<template>
  <div class="row">
    <div class="col">
      <EditSuperAdmin :super_admin="super_admin" @updated="$emit('updated')" />
      <div :data-target="'#superadmin'+super_admin.id" class="card card-link" data-toggle="modal">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h6>{{ super_admin.admin.first_name }} {{ super_admin.admin.last_name }}</h6>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6 col-lg-auto">{{ super_admin.admin.email }}</div>
            <div class="col-12 col-sm-6 col-lg-2 ml-lg-auto">{{ getPhoneNumber(super_admin.admin.phone_number) }}</div>
            <div class="col-12 col-sm-6 col-lg-3">Active {{ getTimeFrom(super_admin.updated_at) }}</div>
            <div class="col-12 col-sm-6 col-lg-3">Added {{ getTimeFrom(super_admin.created_at) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import EditSuperAdmin from './modals/EditSuperAdmin.vue';

    export default {
        name: "SuperAdmin",
        components: {
            EditSuperAdmin
        },
        props: {
            "super_admin": Object
        },
        methods: {
            getPhoneNumber(number) {
                let m = ('' + number).replace(/\D/g, '').match(/^(\d{3})(\d{3})(\d{4})$/);
                return (!m) ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];
            },
            getTimeFrom(date) {
                return moment.utc(date).fromNow();
            }
        }
    }
</script>

