<template>
  <div aria-hidden="true" aria-labelledby="createEmployeeLabel" class="modal fade" id="createEmployee" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createEmployeeLabel">New Employee</h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="postEmployee">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="first_name">First Name</label>
                  <input :class="{'is-valid':!$v.form.first_name.$anyError && $v.form.first_name.$dirty, 'is-invalid':$v.form.first_name.$anyError}" class="form-control" id="first_name" type="text" v-model.trim="$v.form.first_name.$model">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="last_name">Last Name</label>
                  <input :class="{'is-valid':!$v.form.last_name.$anyError && $v.form.last_name.$dirty, 'is-invalid':$v.form.last_name.$anyError}" class="form-control" id="last_name" type="text" v-model.trim="$v.form.last_name.$model">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input :class="{'is-valid':!$v.form.email.$anyError && $v.form.email.$dirty, 'is-invalid':$v.form.email.$anyError}" class="form-control" id="email" type="email" v-model.trim="$v.form.email.$model">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="phone_number">Phone Number</label>
                  <input :class="{'is-valid':!$v.form.phone_number.$anyError && $v.form.phone_number.$dirty, 'is-invalid':$v.form.phone_number.$anyError}" class="form-control" id="phone_number" type="text" v-model.trim="$v.form.phone_number.$model">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="title">Title</label>
                  <input :class="{'is-valid':!$v.form.title.$anyError && $v.form.title.$dirty, 'is-invalid':$v.form.title.$anyError}" class="form-control" id="title" type="text" v-model.trim="$v.form.title.$model">
                </div>
              </div>
            </div>
            <button class="d-none" type="submit"></button>
          </form>
        </div>
        <div class="modal-footer">
          <button class="d-none d-md-inline-block btn btn-outline-primary" data-dismiss="modal" type="button">Close</button>
          <button :disabled="disabled || $v.$invalid" @click="postEmployee" class="btn btn-primary" type="button">Create Employee</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../../api.js';
    import {email, maxLength, numeric, required} from 'vuelidate/lib/validators';

    export default {
        name: "CreateEmployee",
        data() {
            return {
                disabled: false,
                form: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: '',
                    title: '',
                },
            };
        },
        validations: {
            form: {
                first_name: {
                    maxLength: maxLength(255),
                    required
                },
                last_name: {
                    maxLength: maxLength(255),
                    required
                },
                email: {
                    maxLength: maxLength(255),
                    email,
                    required
                },
                phone_number: {
                    maxLength: maxLength(255),
                    numeric,
                    required
                },
                title: {
                    maxLength: maxLength(255),
                    required
                }
            }
        },
        methods: {
            resetForm() {
                this.$v.$reset();
                Object.assign(this.$data, this.$options.data.call(this));
            },
            postEmployee() {
                const self = this;
                if (self.disabled || self.$v.$invalid) return;
                self.disabled = true;

                api.postEmployee(self.form)
                    .then((response) => {
                        self.$emit('updated');
                        $('#createEmployee').modal('hide');
                        self.resetForm();
                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
        }
    }
</script>
