<template>
  <div>
    <!-- connect brivo modal -->
    <ConnectBrivo :brivo="brivo" @updated="$emit('updated')" />
    <div v-if="brivo !== undefined" >
      <RevokeBrivoDigitalCredential :users="brivo.users" />
    </div>
    <div v-if="brivo === undefined">
      <ConnectBrivo :brivo="brivo" @updated="$emit('updated')" />
      <h4>Brivo Account Not Connected</h4>
      <div class="row justify-content-center">
        <div class="col-auto">
          <button class="btn btn-primary" data-target="#connectBrivo" data-toggle="modal" type="button" @click="openBrivoModal()">Connect Brivo Account</button>
        </div>
      </div>
    </div>
    <div v-else>
      <RevokeBrivoDigitalCredential :users="brivo.users" />
      <div class="row mb-4">
        <div class="col-12">
          <h4 class="mb-0">Brivo Account Connected</h4>
          <p>{{ brivo.account.account_name }}</p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <h4>Digital Credentials</h4>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 col-md-auto mt-0 mt-md-2">
                      <strong style="font-size: 20px;">{{ brivo.digitalCredentialAllotment.assigned }}</strong> Credentials assigned
                    </div>
                    <div class="col-12 col-md mt-0 mt-md-2">
                      <strong style="font-size: 20px;">{{ brivo.digitalCredentialAllotment.allotted - brivo.digitalCredentialAllotment.assigned }}</strong> Credentials available
                    </div>
                    <div class="col-12 col-md-auto text-right">
                      <button class="btn btn-outline-danger w-100 mt-3 mt-md-1" data-toggle="modal" data-target="#revokeDigitalCredential">Revoke Lock Access</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h4>Brivo Access</h4>
        </div>
        <BrivoSite :key="site.id" :site="site" v-for="site in brivo.sites" />
      </div>
    </div>
  </div>
</template>

<script>
    import ConnectBrivo from '../components/modals/ConnectBrivo.vue';
    import BrivoSite from '../components/BrivoSite.vue';
    import RevokeBrivoDigitalCredential from '../components/modals/RevokeBrivoDigitalCredential.vue';
    import api from '../api.js';

    export default {
        components: {
            ConnectBrivo,
            BrivoSite,
            RevokeBrivoDigitalCredential
        },
        props: {
            // brivo: Object,
            portfolio: Object
        },
        data() {
            return {
                disabled: false,
                brivoObject: undefined,
            };
        },
        computed: {
            brivo() {
              if (this.brivoObject) {
                return this.brivoObject;
              }
              return undefined;
            },
        },
        beforeMount() {
            const self = this;
            self.getBrivoUsers();
        },
        methods: {
          getBrivoUsers() {
            const self = this;
            api.getBrivoUsers(self.$route.params.id)
                .then((response) => {
                    self.brivoObject = response.brivo;
                    $('.content').css('display', 'block');
                    $('.content').animateCss('fadeIn');
                })
                .catch((error) => {
                    self.handleError(error);
                });
            },
          openBrivoModal() {
            $emit('#connectBrivo').modal('show');
          }
        },
    };
</script>
