<template>
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h4>{{ building.building_name }}</h4>
        <p class="subtitle" v-if="building.portfolio !== null">
          <router-link :to="{name: 'portfolio', params: {'id': this.$route.params.id}}">{{ building.portfolio.business_name }}</router-link>
        </p>
        <p v-else class="d-block" style="margin-bottom: 39px;"></p>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-12 col-md-3">
        <Navigation />
      </div>
      <div class="col-md-7">
        <h4 class="font-weight-normal">Edit Units</h4>
        <div class="row justify-content-center">
          <div class="card text-center col-10 py-3 my-3 upload">
            <div class="card-body">
              <div class="mb-3">
                <i class="fal fa-upload p-4 rounded-circle" style="font-size: 26px;"></i>
              </div>
              <h5 class="card-title">Upload a file to add/update units</h5>
              <p class="card-text">Only .csv file type supported</p>
              <input
                ref="fileInput"
                id="fileInput"
                type="file"
                name="file"
                style="display: none;"
                accept=".csv, text/csv, application/csv, text/comma-separated-values"
                @change="fileUpdated()"
              />
              <button class="btn btn-primary px-5 py-3" @click.prevent="$refs.fileInput.click()">
                Upload
              </button>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="row">
              <div class="col-12 col-md"></div>
              <div class="col-12 col-md"></div>
              <UnitsListItem
                @add-unit="addUnits"
                @download-existing="downloadExisting"
                @download-template="downloadTemplate"
              />
            </div>
          </div>
        </div>
        <Unit :tlj_building_enabled="tlj_building_enabled" :tlj_rooms="tlj_rooms" :internet="internet" :internet_automation="internet_automation" :key="unit.id" :unit="unit" @updated="getBuilding" v-for="unit in building.units" />
      </div>
    </div>
    <footer class="building-footer">
      <div class="row">
        <div class="col-6 col-md-4 col-lg-3 col-xl-2"></div>
        <div class="col-6 col-md-4 offset-md-4 col-lg-3 offset-lg-6 col-xl-2 offset-xl-8">
          <router-link :to="{name: 'buildingamenityspaces', params: {'id': $route.params.id, 'building_id': $route.params.building_id}}" class="btn btn-primary btn-block" tag="button">Next</router-link>
        </div>
      </div>
    </footer>
    <div aria-hidden="true" aria-labelledby="addUnitLabel" class="modal fade" id="addUnit" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addUnitLabel">Add Unit</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="postUnit">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Unit Number</label>
                    <input class="form-control" required type="text" v-model.trim="form.unit_number">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Floor</label>
                    <input class="form-control" required type="text" v-model.trim="form.floor">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Bedrooms</label>
                    <input class="form-control" min="0" required step="1" type="number" v-model.trim="form.bedrooms">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Bathrooms</label>
                    <input class="form-control" min="0" required step="1" type="number" v-model.trim="form.bathrooms">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label>Size</label>
                    <div class="input-group">
                      <input class="form-control" min="0" required step="1" type="number" v-model.trim="form.square_feet">
                      <div class="input-group-append">
                        <span class="input-group-text">sq. ft.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>Max Residents</label>
                    <input class="form-control" min="0" required step="1" type="number" v-model.trim="form.max_residents">
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>Total Rent</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">$</span>
                      </div>
                      <input class="form-control" disabled min="0" step=".01" type="number" v-model.trim="form.total_rent">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Unit Description</label>
                <textarea class="form-control" maxlength="255" v-model.trim="form.description" />
              </div>
              <div v-if="internet">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Device WiFi - SSID</label>
                      <input class="form-control" type="text" v-model.trim="form.device_ssid">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Device WiFi - Password</label>
                      <input class="form-control" type="text" v-model.trim="form.device_password">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Unit WiFi - SSID</label>
                      <input class="form-control" type="text" v-model.trim="form.unit_ssid">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Unit WiFi - Password</label>
                      <input class="form-control" type="text" v-model.trim="form.unit_password">
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="internet_automation">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>AP Serial</label>
                      <input class="form-control" type="text" v-model.trim="form.ap_serial">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Switch Serial</label>
                      <input class="form-control" type="text" v-model.trim="form.switch_serial">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>AP MAC Address</label>
                      <input class="form-control" type="text" v-model.trim="form.ap_mac_address">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Switch MAC Address</label>
                      <input class="form-control" type="text" v-model.trim="form.switch_mac_address">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Switch VLAN</label>
                      <input class="form-control" type="text" v-model.trim="form.switch_vlan">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>AP VLAN</label>
                      <input class="form-control" type="text" v-model.trim="form.ap_vlan">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Switch Identifier</label>
                      <input class="form-control" type="text" v-model.trim="form.switch_identifier">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>AP Identifier</label>
                      <input class="form-control" type="text" v-model.trim="form.ap_identifier">
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="tlj_building_enabled">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>TLJ Room ID</label>
                      <input class="form-control" type="text" v-model.trim="form.tlj_room_name">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>TLJ Area ID</label>
                      <input class="form-control" type="text" v-model.trim="form.tlj_room_area_id">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>TLJ Floor ID</label>
                      <input class="form-control" type="text" v-model.trim="form.tlj_room_floor_id">
                    </div>
                  </div>
                </div>
              </div>
              <button class="d-none" type="submit"></button>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Close</button>
            <button :disabled="disabled" @click="postUnit" class="btn btn-primary" type="button">Save changes</button>
          </div>
        </div>
      </div>
    </div>
    <div aria-hidden="true" aria-labelledby="addUnitsLabel" class="modal fade" id="addUnits" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addUnitsLabel">Add Units</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="postUnit">
              <table class="table table-hover d-block overflow-auto">
                <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" nowrap="nowrap">Unit name</th>
                  <th scope="col">Floor</th>
                  <th scope="col">Beds</th>
                  <th scope="col">Baths</th>
                  <th scope="col">Size</th>
                  <th scope="col" nowrap="nowrap">Max residents</th>
                  <th scope="col" nowrap="nowrap">Total rent</th>
                  <th scope="col" nowrap="nowrap">Address 1</th>
                  <th scope="col" nowrap="nowrap">Address 2</th>
                  <th scope="col" nowrap="nowrap">City</th>
                  <th scope="col" nowrap="nowrap">State</th>
                  <th scope="col" nowrap="nowrap">Postal Code</th>
                  <th scope="col" nowrap="nowrap">Device SSID</th>
                  <th scope="col" nowrap="nowrap">Device Password</th>
                  <th scope="col" nowrap="nowrap">Unit SSID</th>
                  <th scope="col" nowrap="nowrap">Unit password</th>
                  <th scope="col" nowrap="nowrap">AP serial</th>
                  <th scope="col" nowrap="nowrap">Switch serial</th>
                  <th scope="col" nowrap="nowrap">AP MAC address</th>
                  <th scope="col" nowrap="nowrap">Switch MAC address</th>
                  <th scope="col" nowrap="nowrap">Switch VLAN</th>
                  <th scope="col" nowrap="nowrap">AP VLAN</th>
                  <th scope="col" nowrap="nowrap">AP Identifier</th>
                  <th scope="col" nowrap="nowrap">Switch Identifier</th>
                  <th scope="col" nowrap="nowrap">TLJ Room ID</th>
                  <th scope="col" nowrap="nowrap">TLJ Area ID</th>
                  <th scope="col" nowrap="nowrap">TLJ Floor ID</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(unit, index) in formArray" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>Unit {{ unit.unit_number }}</td>
                  <td>{{ unit.floor }}</td>
                  <td>{{ unit.bedrooms }}</td>
                  <td>{{ unit.bathrooms }}</td>
                  <td>{{ unit.square_feet }}</td>
                  <td>{{ unit.max_residents }}</td>
                  <td>$ {{ (+unit.total_rent).toFixed(2) }}</td>
                  <td>{{ unit.address_1 }}</td>
                  <td>{{ unit.address_2 }}</td>
                  <td>{{ unit.city }}</td>
                  <td>{{ unit.state }}</td>
                  <td>{{ unit.postal_code }}</td>
                  <td>{{ unit.device_ssid }}</td>
                  <td>{{ unit.device_password }}</td>
                  <td>{{ unit.unit_ssid }}</td>
                  <td>{{ unit.unit_password }}</td>
                  <td>{{ unit.ap_serial }}</td>
                  <td>{{ unit.switch_serial }}</td>
                  <td>{{ unit.ap_mac_address }}</td>
                  <td>{{ unit.switch_mac_address }}</td>
                  <td>{{ unit.switch_vlan }}</td>
                  <td>{{ unit.ap_vlan }}</td>
                  <td>{{ unit.ap_identifier }}</td>
                  <td>{{ unit.switch_identifier }}</td>
                  <td>{{ unit.tlj_room_name }}</td>
                  <td>{{ unit.tlj_area_id }}</td>
                  <td>{{ unit.tlj_floor_id }}</td>
                </tr>
                </tbody>
              </table>
              <button class="d-none" type="submit"></button>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Close</button>
            <button :disabled="disabled" @click="postUnits" class="btn btn-primary" type="button">Save {{ formArray.length }} units</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.upload {
  border: 2px dashed rgba(53, 150, 217, 0.4) !important;
}
.fa-upload {
  background-color: #F0F5FA;
}

.btn {
  cursor: pointer;
}
</style>
<script>
import api from '../../../api.js';
import Navigation from '../../../components/BuildingNav.vue';
import Unit from '../../../components/Unit.vue';
import { log } from 'util';
import Papa, { parse } from 'papaparse';
import UnitsListItem from "../../../components/partials/UnitsListItem";


export default {
    name: 'Units',
    components: {
        UnitsListItem,
        Navigation,
        Unit,
    },
    data() {
        return {
            tlj_building_enabled: false,
            tlj_rooms: [],
            building: {
                building_name: '',
                new_features: [],
                portfolio: {
                    business_name: '',
                },
                units: []
            },
            disabled: false,
            form: {
                id: '',
                unit_number: '',
                floor: '',
                bedrooms: 0,
                bathrooms: 0,
                square_feet: 0,
                max_residents: 1,
                total_rent: 0.00,
                description: '',
                address_1: null,
                address_2: null,
                city: null,
                state: null,
                postal_code: null,
                device_ssid: null,
                device_password: null,
                unit_ssid: null,
                unit_password: null,
                ap_serial: null,
                switch_serial: null,
                ap_mac_address: null,
                switch_mac_address: null,
                switch_vlan: null,
                ap_vlan: null,
                ap_identifier: null,
                switch_identifier: null,
                tlj_room_name: null,
                tlj_area_id: null,
                tlj_floor_id: null,
            },
            formArray: [],
            internet: false,
            internet_automation: false,
            templateUrl: '',
            csvUrl: '',
            dataTemplate: [{
                'Id': '',
                'Unit number': '',
                'Floor': '',
                'Bedrooms': 0,
                'Bathrooms': 0,
                'Square feet': 0,
                'Max residents': 1,
                'Total rent': 0.00,
                'Description': '',
                'Address_1': null,
                'Address_2': null,
                'City': null,
                'State': null,
                'Postal Code': null,
                'Device SSID': null,
                'Device password': null,
                'Unit SSID': null,
                'Unit password': null,
                'AP serial': null,
                'Switch serial': null,
                'AP MAC Address': null,
                'Switch MAC Address': null,
                'Switch VLAN': null,
                'AP VLAN': null,
                'AP Identifier': null,
                'Switch Identifier': null,
                'TLJ Room ID': null,
                'TLJ Area ID': null,
                'TLJ Floor ID': null,
            }],
        };
    },
    computed: {
        listUnits() {
            if (!this.building.units) return [];
            return this.building.units;
        },
        fileName() {
            if (!this.building) return 'Building Units';
            return this.building.building_name + ' - Building Units.csv';
        }
    },
    async beforeMount() {
        const self = this;
        if (self.$route.params.building_id === 'undefined') {
            self.$router.go(-1);
            return;
        }
        self.getBuilding();
    },
    methods: {
        getBuilding() {
            const self = this;
            api.getBuilding(self.$route.params.building_id)
                .then((response) => {
                    self.building = response.building;

                    if (self.building.new_features.some(e => e.identifier === 'internet')) {
                        self.internet = true;
                    }

                    if (self.building.new_features.some(e => e.identifier === 'internet-automation')) {
                        self.internet_automation = true;
                    }

                    if (self.building.new_features.some((e) => e.identifier === 'tlj-locks')) {
                        self.tlj_building_enabled = true;
                    }

                    const tljBuilding = self.building?.tlj_building;
                    const tljRooms =  tljBuilding?.tlj_rooms;
                    self.tlj_rooms = tljBuilding!== null && tljRooms !== null ? tljRooms : null;

                    if ($('.content').css('display') !== 'block') {
                        $('.content').css('display', 'block');
                        $('.content').animateCss('fadeIn');
                    }
                })
                .catch((error) => {
                    self.handleError(error);
                });
        },
        postUnit() {
            const self = this;
            if (self.disabled) return;
            self.disabled = true;

            api.postUnit(self.form, self.$route.params.building_id)
                .then((response) => {
                    self.disabled = false;

                  if (self.tlj_building_enabled && self.form.tlj_room_name) {
                    api.putTljUnit(
                        self.unit.id,
                        self.form.tlj_room_name,
                        self.form.tlj_area_id,
                        self.form.tlj_floor_id
                    ).catch((error) => {
                      self.disabled = false;
                      self.handleError(error);
                    });
                  }

                    Object.assign(this.$data, this.$options.data.call(this));

                    $('#addUnit').modal('hide');
                    self.getBuilding();
                })
                .catch((error) => {
                    self.disabled = false;
                    self.handleError(error);
                });
        },
        postUnits() {
            const self = this;
            if (self.disabled) return;
            self.disabled = true;

            self.formArray.map((unit) => {
                unit.total_rent = unit.total_rent * 100;
            });

            api.postUnits(self.formArray, self.$route.params.building_id)
                .then((response) => {
                    self.disabled = false;

                    Object.assign(this.$data, this.$options.data.call(this));

                    $('#addUnits').modal('hide');
                    self.getBuilding();
                })
                .catch((error) => {
                    self.disabled = false;
                    self.handleError(error);
                  $('#addUnits').modal('hide');
                });
        },
        async csvToJson(rawFile) {
            return new Promise((resolve, reject) => {
                Papa.parse(rawFile, {
                    header: true,
                    skipEmptyLines: true,
                    transformHeader(headerString) {
                        return headerString.split(/\b\s+/)
                            .map(word => word.toLowerCase())
                            .join('_');
                    },
                    complete(results, file) {
                        resolve(results.data);
                    },
                    error(err, file) {
                        reject(err);
                    },
                });
            });
        },
        async jsonToCsv(rawData) {
            return Papa.unparse(rawData, { delimiter: ',' });
        },
        async fileUpdated() {
            const input = document.getElementById('fileInput');
            const [file] = input.files;
            const array = await this.csvToJson(file);
            this.formArray = [];
            array.forEach(unit => {
              // Filter out empty rows
              if (!Object.values(unit).every(x => x === null || x === '')) {
                unit['tlj_room_name'] = unit['tlj_room_id'];
                delete unit['tlj_room_id'];
                this.formArray.push(unit);
              }
            });

            $('#addUnits').modal('show');
        },
        downloadExisting(eventdata) {
            const self = this;
            api.getUnitsForBuildingExport(this.$route.params.building_id)
                .then((response) => {
                    // Generate CSV File of current units
                    const newFile = response.data;
                    const blob = new Blob([newFile], );
                    const csvUrl = window.URL.createObjectURL(blob);
                    //Create an a tag with the file attached
                    const fileLink = document.createElement('a');
                    fileLink.href = csvUrl;
                    fileLink.setAttribute('download', this.fileName);
                    // File is downloaded on click
                    fileLink.click();
                })
                .catch((error) => {
                    self.handleError(error);
                });
        },
        async downloadTemplate(eventdata) {
            // Generate CSV Template
            const csvString = await this.jsonToCsv(this.dataTemplate);
            const blob = new Blob([csvString]);
            const templateUrl = window.URL.createObjectURL(blob);
            //Create an a tag with the file attached
            const fileLink = document.createElement('a');
            fileLink.href = templateUrl;
            fileLink.setAttribute('download', 'unit-template.csv');
            //File downloaded on click
            fileLink.click();
        },
        addUnits(eventData) {
            $('#addUnit').modal('show');
        }
    },
};
</script>
