<template>
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg navbar-light fixed-top" v-if="this.$store.getters.loggedIn">
      <router-link class="navbar-brand" to="/"><img :src="'/img/logo.png'"></router-link>
      <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler" data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
        <span class="navbar-toggler-icon" />
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" v-if="$store.getters.getPermission('view_portfolios')">
            <router-link class="nav-link" data-target=".navbar-collapse.show" data-toggle="collapse" to="/portfolios">Portfolios</router-link>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" class="nav-link" data-toggle="modal" data-target="#fobsModal">Fob Search</a>
          </li>
          <li class="nav-item" v-if="$store.getters.getPermission('view_queue')">
            <router-link class="nav-link" data-target=".navbar-collapse.show" data-toggle="collapse" to="/queue">Queue</router-link>
          </li>
          <li class="nav-item" v-if="$store.getters.getPermission('view_employees')">
            <router-link class="nav-link" data-target=".navbar-collapse.show" data-toggle="collapse" to="/employees">Employees</router-link>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" id="navbarDropdown" role="button"> {{ $store.getters.user.first_name }} {{ $store.getters.user.last_name }}</a>
            <div aria-labelledby="navbarDropdown" class="dropdown-menu">
              <router-link class="dropdown-item" to="/logout">Logout</router-link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div class="alerts" v-if="this.$store.getters.loggedIn"></div>
    <router-view />
    <div class="sk-folding-cube loading">
      <div class="sk-cube1 sk-cube" />
      <div class="sk-cube2 sk-cube" />
      <div class="sk-cube4 sk-cube" />
      <div class="sk-cube3 sk-cube" />
    </div>
    <FobsModal />
  </div>
</template>

<script>
import FobsModal from '../components/modals/FobsModal.vue';
export default {
  name: 'App',
  components: {
    FobsModal,
  },
};
</script>
