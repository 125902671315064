<template>
  <div aria-hidden="true" aria-labelledby="revokeDigitalCredentialLabel" class="modal fade" id="revokeDigitalCredential" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="revokeDigitalCredentialLabel">Digital Credentials - Revoke Lock Access</h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="form-group input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-search fa-fw"></i></span>
                </div>
                <input class="form-control" placeholder="Search" type="text" v-model.trim="search" />
              </div>
            </div>
          </div>
          <div class="row" v-for="user in filteredList" :key="user.id">
            <div class="col-12 col-lg-3">
              <span class="font-weight-bold">{{ user.user.first_name }} {{ user.user.last_name }}</span>
              <br />
              <small>{{ user.user.email }}</small>
            </div>
            <div class="col-12 col-lg-6">
              <span v-if="user.user_type === 'Tenant'">
                Resident
                <span v-if="user.user.my_unit !== null"> - Unit {{ user.user.my_unit.unit_number }}</span>
                <br />
                <small v-if="user.user.building !== null">{{ user.user.building.building_name}}</small>
              </span>
              <span v-if="user.user_type === 'Admin'">
                Building Admin
                <br />
                <small v-for="(building, index) in user.user.buildings" :key="index">
                  {{ building.building_name }}
                  <span v-if="index < user.user.buildings.length-1">,</span>
                </small>
              </span>
              <span v-if="user.user_type === 'Super Admin'">
                Super Admin
              </span>
            </div>
            <div class="col-12 col-lg-3 text-lg-right">
              <button class="btn btn-outline-danger mt-1 mb-1" v-if="!user.suspended" @click="toggleAccess(user)" :disabled="disabled">Revoke Access</button>
              <button class="btn btn-outline-success mt-1 mb-1" v-if="user.suspended" style="width: 131px;" @click="toggleAccess(user)" :disabled="disabled">Grant Access</button>
              <button class="btn btn-danger" :disabled="disabled" @click="deleteUser(user)">Delete User</button>
            </div>
            <div class="col-12">
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../../api.js';

    export default {
        name: 'RevokeBrivoDigitalCredential',
        props: {
            users: Array
        },
        data() {
            return {
                disabled: false,
                search: ''
            };
        },
        computed: {
            filteredList() {
                if (this.users == null) return [];
                const re = new RegExp(this.search, 'i');
                return this.users.filter(
                    (user) =>
                        user.user.first_name.match(re) ||
                        user.user.last_name.match(re)
                );
            },
        },
        methods: {
            toggleAccess(user) {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                let reason = null;
                if (!user.suspended) {
                    reason = prompt('Are you sure you want to revoke access for ' + user.user.first_name + ' ' + user.user.last_name + '? \nEnter the reason why:');
                    if (reason === null) {
                        self.disabled = false;
                        return;
                    }
                }

                let user_type = '';
                if (user.user_type === 'Tenant') {
                    user_type = 'App\\Models\\Tenant';
                } else if (user.user_type === 'Super Admin' || user.user_type === 'Admin') {
                    user_type = 'App\\Models\\Admin';
                } else {
                    self.handleError('The user is not a tenant or admin.');
                    self.disabled = false;
                    return;
                }

                api.putSuspendUser(reason, user.user.id, user_type)
                    .then((response) => {
                        if (!user.suspended) {
                            self.handleSuccess('Success - ' + user.user.first_name + ' ' + user.user.last_name + ' was suspended.');
                        } else {
                            self.handleSuccess('Success - ' + user.user.first_name + ' ' + user.user.last_name + ' was unsuspended.');
                        }
                        user.suspended = !user.suspended;
                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.handleError(error);
                        self.disabled = false;
                    })
            },
            deleteUser(user) {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                if (!confirm('Are you sure you want to delete the Brivo account for ' + user.user.first_name + ' ' + user.user.last_name + '?')) {
                    self.disabled = false;
                    return;
                }

                let user_type = '';
                if (user.user_type === 'Tenant') {
                    user_type = 'App\\Models\\Tenant';
                } else if (user.user_type === 'Super Admin' || user.user_type === 'Admin') {
                    user_type = 'App\\Models\\Admin';
                } else {
                    self.handleError('The user is not a tenant or admin.');
                    self.disabled = false;
                    return;
                }

                api.deleteBrivoUser(user.user.id, user_type)
                    .then((response) => {
                        let index = self.users.findIndex(x => x.id === user.id);
                        if (index !== -1) {
                            self.users.splice(index, 1);
                        }
                        self.handleSuccess('Success - ' + user.user.first_name + ' ' + user.user.last_name + ' was deleted from Brivo.');
                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.handleError(error);
                        self.disabled = false;
                    });
            }
        }
    }
</script>
