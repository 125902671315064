<template>
  <div :aria-labelledby="id+'Label'" :id="id" aria-hidden="true" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 :id="id+'Label'" class="modal-title">{{ title }}</h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <h2>Are you sure?</h2>
          <span class="d-block mt-2 mb-2">{{ message }}</span>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-primary" data-dismiss="modal" type="button">Cancel</button>
          <button @click="confirm" class="btn btn-danger" type="button">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "ConfirmModal",
        props: {
            id: String,
            title: String,
            message: String
        },
        methods: {
            confirm() {
                this.$emit('confirmed');
                $('#' + this.id).modal('hide');
            }
        }
    }
</script>
