<template>
  <div class="col-12">
    <div class="card">
      <ConfirmModal :id="'revoke'+user.actorable.id" :message="'Revoking access will remove all locks associated with ' + user.actorable.first_name + ' ' + user.actorable.last_name + '.'" :title="'Revoke Access'" @confirmed="revokeAccess" />
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-3">
            <h6 style="font-size: 16px;">{{ user.actorable.first_name }} {{ user.actorable.last_name }}</h6>
            <small class="d-block mt-md-2">{{ user.actorable.email }}</small>
          </div>
          <div class="col-12 col-md-3">
            <span v-if="user.actorable_type === 'App\\Models\\Tenant'">Resident <span v-if="user.actorable.my_unit !== null">- Unit {{ user.actorable.my_unit.unit_number }}</span></span>
            <span v-if="user.actorable_type === 'App\\Models\\Admin' && user.actorable.installer_detail !== null">Installer</span>
            <span v-if="user.actorable_type === 'App\\Models\\Admin' && user.actorable.installer_detail == null && user.actorable.role.type === 'Super Admin'">Super Admin</span>
            <span v-if="user.actorable_type === 'App\\Models\\Admin' && user.actorable.installer_detail == null && user.actorable.role.type === 'Admin'">Building Admin</span>
            <small class="d-block mt-md-2">
              <span v-for="(building, index) in user.actorable.buildings" :key="building.id">
                <span v-if="building !== undefined && building !== null && user.actorable_type === 'App\\Models\\Admin'">
                  {{ building.building_name }}
                </span>
                <span v-if="index + 1 < user.actorable.buildings.length">,</span>
              </span>
                <span v-if="user.actorable.building !== undefined && user.actorable.building !== null && user.actorable_type === 'App\\Models\\Tenant'">
                  {{ user.actorable.building.building_name }}
                </span>
            </small>
          </div>
          <div class="col-12 col-md-3">
            <span v-if="user.locks === undefined">Lock Data Unavailable</span>
            <span v-else>
              {{ user.locks.length }} Door<span v-if="user.locks.length !== 1">s</span>
              <small class="d-block mt-md-2" v-if="!more">
                <span v-if="locksText.length <= 30">
                  {{ locksText.substring(0,30) }}
                </span>
                <span v-else>
                  <span>{{ locksText.substring(0,22) }}</span>
                  <span v-if="locksText.length > 22">...</span>
                  <span @click="seeMore" class="a">See More</span>
                </span>
              </small>
              <small class="d-block mt-md-2" v-if="more">
                <span>{{ locksText }}</span>
              </small>
            </span>
          </div>
          <div class="col-12 col-md-auto ml-auto mt-2">
            <button :data-target="'#revoke'+user.actorable.id" :disabled="user.locks === undefined" class="btn btn-outline-danger w-100" data-toggle="modal">Revoke Access</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../api.js';
    import ConfirmModal from './modals/ConfirmModal.vue';

    export default {
        name: 'AllegionUser',
        components: {
          ConfirmModal,
        },
        props: {
            user: Object,
        },
        data() {
            return {
                more: false,
            };
        },
        computed: {
            locksText() {
                let text = '';
                for (let i = 0; i < this.user.locks.length; i++) {
                    text += this.user.locks[i].name;
                    if (i < this.user.locks.length - 1) {
                        text += ', ';
                    }
                }
                return text;
            },
        },
        methods: {
            seeMore() {
                this.more = !this.more;
            },
            revokeAccess() {
                const self = this;
                if (self.user.actorable_type === 'App\\Models\\Tenant') {
                    self.user.type = 'Tenant';
                } else if (self.user.actorable_type === 'App\\Models\\Admin') {
                    self.user.type = 'Admin';
                }

                api.revokeAllegionAccess(self.user)
                    .then((response) => {
                        self.handleSuccess('Access for ' + self.user.actorable.first_name + ' ' + self.user.actorable.last_name + ' has been revoked.');
                    })
                    .catch((error) => {
                        self.handleError(error);
                    });
            },
        },
    };
</script>
