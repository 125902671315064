<template>
  <div
    :aria-labelledby="'assignBuildingFobsLabel' + building.id"
    :id="'assignBuildingFobs' + building.id"
    aria-hidden="true"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-xl"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <!--          Change this title -->
          <h5
            :id="'assignBuildingFobsLabel' + building.id"
            class="modal-title"
          >
            Building Keys - Assign From Portfolio
          </h5>
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"/></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label :for="'action_type'+building.id">Action</label>
              <select
                :id="'action_type'+building.id"
                class="form-control"
                @change="updateSelectedAction($event)"
              >
                <option
                  v-for="type in actionTypes"
                  :selected="type.id===selectedActionTypeID"
                  :value="type.id"
                  :key="type.id"
                >
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
          <small class="d-block mb-2">Enter a batch range from the list of portfolio keys available.</small>
          <hr >
          <div v-if="selectedActionTypeID==0">Choose an action</div>
          <BatchAssignFob
            v-else-if="selectedActionTypeID==1"
            :building="buildingObject"
            :fob_types="fob_types"
          />
          <BatchDeleteFob
            v-else-if="selectedActionTypeID==2"
            :building="buildingObject"
            :fob_types="fob_types"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../../api.js';
    import BatchAssignFob from './subModals/BatchAssignFob';
    import BatchDeleteFob from './subModals/BatchDeleteFob';
    import {numeric, required} from 'vuelidate/lib/validators';
    export default {
      name: 'BuildingFobModal',
      components: {BatchDeleteFob, BatchAssignFob},
      props: {
            building: Object,
            fob_types: Array,
        },
        data() {
            return {
                actionTypes: [{id: 1, name: 'Add/Edit'}, {id: 2, name: 'Batch delete'}],
                batch_keys: 0,
                disabled: false,
                buildingObject: this.building,
                activeFobs: [],
                activePortfolioFobs: [],
                availableFobs: [],
                availablePortfolioFobs: [],
                selectedActionTypeID: 0,
                fobsPaginationObject: {
                  activeFobsStateObject: {
                    activeFobsURI: null,
                    activeFobsLoading: false,
                  },
                  activePortfolioFobsStateObject: {
                    activePortfolioFobsURI: null,
                    activePortfolioFobsLoading: false,
                  },
                  availableFobsStateObject: {
                    availableFobsURI: null,
                    availableFobsLoading: false,
                  },
                  availablePortfolioFobsStateObject: {
                    availablePortfolioFobsURI: null,
                    availablePortfolioFobsLoading: false,
                  },
                },
                form: {
                    start_number: '',
                    end_number: '',
                    facility_code: '',
                    key_type: '',
                },
                search: null,
            };
        },
        validations: {
            form: {
                start_number: {
                    numeric,
                    required,
                },
                end_number: {
                    numeric,
                    required,
                },
                facility_code: {
                    numeric,
                    required,
                },
                key_type: {
                    required,
                },
            },
        },
        computed:
          {
          searchEmptyString() {
              if (this.search === '') {
                  return 'No keys assigned to this building.';
              }
              return 'No keys found.';
          },
          filteredFobs(fobType) {
              if (fobType === undefined) {
                return [];
              }
              const re = new RegExp(this.search, 'i');
              return fobType.filter(
                  (fob) =>
                      fob.card_number.toString().match(re)
              );
          },
        },
      watch: {
        'search': function(newValue, oldValue) {
          if (this.buildingObject && this.buildingObject.id) {
            this.getFobs(this.buildingObject);
          }
        },
        'building': function(newValue, oldValue) {
            this.buildingObject = newValue;
            this.getFobs(newValue);
            this.scroll(this.fobsPaginationObject, newValue);
        },
        'form.start_number': function(newValue, oldValue) {
          this.getBatchDiff();
        },
        'form.end_number': function(newValue, oldValue) {
          this.getBatchDiff();
        },
      },
        mounted() {
          const self = this;
          self.search = '';
            if (self.fob_types !== null && self.fob_types.length > 0) {
                let defaultFob = self.fob_types.find((fob) => fob.name === 'Fob');
                if (defaultFob) {
                    self.form.key_type = defaultFob.id;
                } else {
                    self.form.key_type = self.fob_types[0].id;
                }
            }
            this.scroll(this.fobsPaginationObject, this.buildingObject);
        },
        methods: {
            updateSelectedAction(event) {
              this.selectedActionTypeID = event.target.value;
            },
            getDate(date) {
                return moment.utc(date).format('MMMM D, YYYY');
            },
            getBatchDiff() {
                let start = parseInt(this.form.start_number);
                let end = parseInt(this.form.end_number);
                if (isNaN(start) || isNaN(end) || start > end) {
                    this.batch_keys = 0;
                } else {
                    this.batch_keys = (end - start) + 1;
                }
            },
            getPaginatedActiveFobs(fobsPaginationObject, building) {
              const self = this;
              if (!fobsPaginationObject.activeFobsStateObject.activeFobsLoading && fobsPaginationObject.activeFobsStateObject.activeFobsURI) {
                fobsPaginationObject.activeFobsStateObject.activeFobsLoading = true;
                api.getPaginatedActiveFobs(this.search, building, fobsPaginationObject.activeFobsStateObject.activeFobsURI.split('?')[1])
                .then((response) => {
                    if ($('.content').css('display') === 'none') {
                        $('.content').css('display', 'block');
                        $('.content').animateCss('fadeIn');
                    }
                    fobsPaginationObject.activeFobsStateObject.activeFobsURI = response.next_page_url;
                    self.activeFobs = self.activeFobs.concat(response.data);
                    fobsPaginationObject.activeFobsStateObject.activeFobsLoading = false;
                })
                .catch((error) => {
                  fobsPaginationObject.activeFobsStateObject.activeFobsLoading = false;
                  fobsPaginationObject.activeFobsStateObject.activeFobsURI = null;
                });
              }
            },
            getPaginatedActivePortfolioFobs(fobsPaginationObject, building) {
              const self = this;
              if (!fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsLoading && fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI) {
                fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsLoading = true;
                api.getPaginatedActivePortfolioFobs(this.search, building, fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI.split('?')[1])
                .then((response) => {
                    if ($('.content').css('display') === 'none') {
                        $('.content').css('display', 'block');
                        $('.content').animateCss('fadeIn');
                    }
                    fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI = response.next_page_url;
                    self.activePortfolioFobs = self.activePortfolioFobs.concat(response.data);
                    fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsLoading = false;
                })
                .catch((error) => {
                  fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsLoading = false;
                  fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI = null;
                });
              }
            },
            getPaginatedAvailableFobs(fobsPaginationObject, building) {
              const self = this;
              if (!fobsPaginationObject.availableFobsStateObject.availableFobsLoading && fobsPaginationObject.availableFobsStateObject.availableFobsURI) {
                fobsPaginationObject.availableFobsStateObject.availableFobsLoading = true;
                api.getPaginatedAvailableFobs(this.search, building, fobsPaginationObject.availableFobsStateObject.availableFobsURI.split('?')[1])
                .then((response) => {
                    if ($('.content').css('display') === 'none') {
                        $('.content').css('display', 'block');
                        $('.content').animateCss('fadeIn');
                    }
                    fobsPaginationObject.availableFobsStateObject.availableFobsURI = response.next_page_url;
                    self.availableFobs = self.availableFobs.concat(response.data);
                    fobsPaginationObject.availableFobsStateObject.availableFobsLoading = false;
                })
                .catch((error) => {
                  fobsPaginationObject.availableFobsStateObject.availableFobsLoading = false;
                  fobsPaginationObject.availableFobsStateObject.availableFobsURI = null;
                });
              }
            },
            getPaginatedAvailablePortfolioFobs(fobsPaginationObject, building) {
              const self = this;
              if (!fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsLoading && fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI) {
                fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsLoading = true;
                api.getPaginatedAvailablePortfolioFobs(this.search, building, fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI.split('?')[1])
                .then((response) => {
                    if ($('.content').css('display') === 'none') {
                        $('.content').css('display', 'block');
                        $('.content').animateCss('fadeIn');
                    }
                    fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI = response.next_page_url;
                    self.availablePortfolioFobs = self.availablePortfolioFobs.concat(response.data);
                    fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsLoading = false;
                })
                .catch((error) => {
                  fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsLoading = false;
                  fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI = null;
                });
              }
            },
            scroll(fobsPaginationObject, building) {
              const self = this;
              document.getElementById('assignBuildingFobs' + building.id)
                      .addEventListener('scroll', function(event) {
                let element = document.getElementById('assignBuildingFobs' + building.id);
                let bottomOfWindow = element.scrollTop >= element.offsetHeight;
                if (bottomOfWindow && building && fobsPaginationObject) {
                    self.getPaginatedActiveFobs(fobsPaginationObject, building);
                    self.getPaginatedActivePortfolioFobs(fobsPaginationObject, building);
                    self.getPaginatedAvailableFobs(fobsPaginationObject, building);
                    self.getPaginatedAvailablePortfolioFobs(fobsPaginationObject, building);
                }
              });
            },
            splicePaginationUrl(url) {
                  return str.split('?')[1];
            },
            getFobs(building) {
              const self = this;
              if (building) {
                api.getActiveFobs(this.search, building)
                  .then((response) => {
                      if ($('.content').css('display') === 'none') {
                          $('.content').css('display', 'block');
                          $('.content').animateCss('fadeIn');
                      }
                      this.fobsPaginationObject.activeFobsStateObject.activeFobsURI = response.next_page_url;
                      self.activeFobs = response.data;
                  })
                  .catch((error) => {
                  });
                api.getActivePortfolioFobs(this.search, building.portfolio_id, building.id)
                  .then((response) => {
                      if ($('.content').css('display') === 'none') {
                          $('.content').css('display', 'block');
                          $('.content').animateCss('fadeIn');
                      }
                      this.fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI = response.next_page_url;
                      self.activePortfolioFobs = response.data;
                  })
                  .catch((error) => {
                  });
                api.getAvailableFobs(this.search, building)
                  .then((response) => {
                      if ($('.content').css('display') === 'none') {
                          $('.content').css('display', 'block');
                          $('.content').animateCss('fadeIn');
                      }
                      this.fobsPaginationObject.availablePortfolioFobsStateObject.availableFobsURI = response.next_page_url;
                      self.availableFobs = response.data;
                  })
                  .catch((error) => {
                  });
                api.getAvailablePortfolioFobs(this.search, building.portfolio_id, building.id)
                  .then((response) => {
                      if ($('.content').css('display') === 'none') {
                          $('.content').css('display', 'block');
                          $('.content').animateCss('fadeIn');
                      }
                      this.fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI = response.next_page_url;
                      self.availablePortfolioFobs = response.data;
                  })
                  .catch((error) => {
                  });
                }
            },
            showPortfolioConfirm(fobId) {
                const portfolio = $('#portfolio-' + fobId);
                const portfolioConfirm = $('#portfolioconfirm-' + fobId);
                const unassign = $('#unassign-' + fobId);
                const unassignConfirm = $('#unassignconfirm-' + fobId);
                if (portfolio.text() === 'Move to Portfolio') {
                    portfolio.text('Cancel');
                    portfolioConfirm.removeClass('d-none');
                    unassign.addClass('d-none');
                    unassignConfirm.addClass('d-none');
                } else {
                    portfolio.text('Move to Portfolio');
                    portfolioConfirm.addClass('d-none');
                    unassign.removeClass('d-none');
                    unassignConfirm.removeClass('d-none');
                }
            },
            addToPortfolio(fobId) {
                const self = this;
                if (self.disabled) {
                    return;
                }
                self.disabled = true;
                api.deleteFobBuilding(fobId)
                    .then((response) => {
                        self.handleSuccess('Success - fob was removed from all buildings.');
                        let assignedIndex = self.activeFobs.findIndex((x) => x.id === fobId);
                        if (assignedIndex !== -1) {
                            self.activeFobs.splice(assignedIndex, 1);
                        }
                        let assignedPortfolioIndex = self.activePortfolioFobs.findIndex((x) => x.id === fobId);
                        if (assignedPortfolioIndex !== -1) {
                            self.activePortfolioFobs.splice(assignedPortfolioIndex, 1);
                        }
                        let availableIndex = self.availableFobs.findIndex((x) => x.id === fobId);
                        if (availableIndex !== -1) {
                            self.availableFobs.splice(availableIndex, 1);
                        }
                        let availablePortfolioIndex = self.availablePortfolioFobs.findIndex((x) => x.id === fobId);
                        if (availablePortfolioIndex !== -1) {
                            self.availablePortfolioFobs.splice(availablePortfolioIndex, 1);
                        }

                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.handleError(error);
                        self.disabled = false;
                    });
            },
            showUnassignConfirm(fobId) {
                const unassign = $('#unassign-' + fobId);
                const unassignConfirm = $('#unassignconfirm-' + fobId);
                const portfolio = $('#portfolio-' + fobId);
                const portfolioConfirm = $('#portfolioconfirm-' + fobId);
                if (unassign.text() === 'Unassign') {
                    unassign.text('Cancel');
                    unassignConfirm.removeClass('d-none');
                    portfolio.addClass('d-none');
                    portfolioConfirm.addClass('d-none');
                } else {
                    unassign.text('Unassign');
                    unassignConfirm.addClass('d-none');
                    portfolio.removeClass('d-none');
                    portfolioConfirm.removeClass('d-none');
                }
            },
            unassignFob(fobId) {
                const self = this;
                if (self.disabled) {
                    return;
                }
                self.disabled = true;
                api.deleteUnassignFob(fobId)
                    .then((response) => {
                        self.handleSuccess('Success - fob was unassigned.');
                        self.getFobs(self.buildingObject);
                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.handleError(error);
                        self.disabled = false;
                    });
            },
            assignPortfolioKeys() {
                const self = this;
                if (self.disabled || self.form.start_number > self.form.end_number) {
                    return;
                }
                self.disabled = true;
                api.putAssignFobsToBuilding(self.form.start_number, self.form.end_number, self.form.facility_code, self.form.key_type, self.building.id, self.$route.params.id)
                    .then((response) => {
                        self.handleSuccess('Success - batch assignment complete.');
                        self.getFobs(self.buildingObject);
                        self.disabled = false;
                        self.form.start_number = '';
                        self.form.end_number = '';
                        self.form.facility_code = '';
                        self.form.key_type = '';
                        this.$v.$reset();
                    })
                    .catch((error) => {
                        self.handleError(error);
                        self.disabled = false;
                    });
            },
        },
    };
</script>
