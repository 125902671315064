import Vue from 'vue';
import Vuex from 'vuex';

import plan from './modules/plan';
import modal from './modules/modal';
import deleteKeyFobs from './modules/deleteKeyFobs';
import unassignKeyFobs from './modules/unassignKeyFobs';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        plan,
        modal,
        deleteKeyFobs,
        unassignKeyFobs,
    },
    state: {
        user: JSON.parse(localStorage.getItem('user')),
    },
    mutations: {
        loginUser(state) {
            state.user = JSON.parse(localStorage.getItem('user'));
        },
        logoutUser(state) {
            state.user = null;
            localStorage.removeItem('user');
        },
    },
    getters: {
        loggedIn: (state) => {
            return state.user !== null;
        },
        user: (state) => {
            return state.user;
        },
        getPermission: (state) => (permission) => {
            if (state.user !== null) {
                return state.user.permissions.some((e) => e.identifier === permission);
            }
            return null;
        },
    },
});
