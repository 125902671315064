<template>
  <div aria-hidden="true" aria-labelledby="addTierLabel" class="modal fade" id="addTier" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addTierLabel">Add Tier</h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="postTier">
            <div class="form-group">
              <label for="name">Tier Name</label>
              <input type="text" class="form-control" :class="{'is-invalid':$v.form.name.$anyError}" id="name" v-model.trim="$v.form.name.$model">
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="up_speed">Up speed</label>
                  <div class="input-group">
                    <input type="number" class="form-control" :class="{'is-invalid':$v.form.up_speed.$anyError}" id="up_speed" min="0" step="1" v-model.trim="$v.form.up_speed.$model">
                    <div class="input-group-append">
                      <span class="input-group-text">Mbps</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="down_speed">Down speed</label>
                  <div class="input-group">
                    <input type="number" class="form-control" :class="{'is-invalid':$v.form.down_speed.$anyError}" id="down_speed" min="0" step="1" v-model.trim="$v.form.down_speed.$model">
                    <div class="input-group-append">
                      <span class="input-group-text">Mbps</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="cost">Price per month</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input type="number" class="form-control" :class="{'is-invalid':$v.form.cost.$anyError}" id="cost" min="0" step=".01" v-model.trim="$v.form.cost.$model">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="description">Tier Description</label>
              <small>Choose a description that reflects tier speed.</small>
              <input type="text" class="form-control" :class="{'is-invalid':$v.form.description.$anyError}" id="description" v-model.trim="$v.form.description.$model">
            </div>
            <div class="form-group">
              <label>Tier Image</label>
              <small>Choose the image that reflects the tier speed.</small>
              <br />
              <span :key="image.id" v-for="image in images">
                <label>
                  <input type="radio" class="image-radio" :class="{'is-invalid':$v.form.image_url.$anyError}" name="image_url" v-model.trim="$v.form.image_url.$model" :value="image.image_url" />
                  <img :src="image.image_url" class="tier-image-select" :alt="image.identifier" />
                </label>
              </span>
            </div>
            <div class="form-group">
              <input type="checkbox" class="form-control d-inline-block" id="default" v-model.trim="form.default">
              <label for="default">Default Tier</label>
              <small>This tier will pre-selected when residents sign up via Homebase.</small>
            </div>
            <div class="form-group">
              <input type="checkbox" class="form-control d-inline-block" id="downgrade" v-model.trim="form.downgrade">
              <label for="downgrade">Downgrade Tier</label>
              <small>This tier will selected when a residents wifi has been suspended and automatically downgraded.</small>
            </div>
            <button class="d-none" type="submit"></button>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-secondary" data-dismiss="modal" type="button">Close</button>
          <button :disabled="disabled || $v.$invalid" @click="postTier" class="btn btn-primary" type="button">Create Tier</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../../api.js';
    import {decimal, maxLength, integer, required} from 'vuelidate/lib/validators';

    export default {
        name: "AddWiFiTierModal",
        props: {
            images: Array
        },
        data() {
            return {
                disabled: false,
                form: {
                    name: '',
                    up_speed: 0,
                    down_speed: 0,
                    cost: 0.00,
                    description: '',
                    image_url: '',
                    default: false,
                    downgrade: false
                },
            };
        },
        validations: {
            form: {
                name: {
                    maxLength: maxLength(255),
                    required
                },
                up_speed: {
                    integer,
                    required
                },
                down_speed: {
                    integer,
                    required
                },
                cost: {
                    decimal,
                    required
                },
                description: {
                    required
                },
                image_url: {
                    required
                }
            }
        },
        methods: {
            resetForm() {
                this.$v.$reset();
                Object.assign(this.$data, this.$options.data.call(this));
            },
            postTier() {
                const self = this;
                if (self.disabled) return;
                if (self.form.image_url === "") {
                    alert("Please select a tier image.");
                    return;
                }
                self.disabled = true;

                api.postBuildingInternetTier(self.form, self.$route.params.building_id)
                    .then((response) => {
                        self.disabled = false;
                        $('#addTier').modal('hide');
                        self.$emit('updated');
                        self.resetForm();
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
        }
    }
</script>
