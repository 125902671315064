<template>
  <div class="col-12" v-if="site.building !== null">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-6">
            <h6 style="font-size: 16px;">{{ site.name }}</h6>
            <small class="d-block mt-md-2">{{ site.building.address_1 }}, {{ site.building.city_name }}, {{ site.building.state }}</small>
          </div>
          <div class="col-12 col-md-6">
            {{ site.building.brivo_devices.length }} Door<span v-if="site.building.brivo_devices.length !== 1">s</span>
            <small class="d-block mt-md-2" v-if="!more">
              <span v-if="locksText.length <= 30">{{ locksText.substring(0,30) }}</span>
              <span v-else>
                <span>{{ locksText.substring(0,22) }}</span>
                <span v-if="locksText.length > 22">...</span>
                <span @click="seeMore" class="a">See More</span>
              </span>
            </small>
            <small class="d-block mt-md-2" v-if="more">
              <span>{{ locksText }}</span>
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'BrivoSite',
        props: {
            site: Object
        },
        data() {
            return {
                more: false
            };
        },
        computed: {
            locksText() {
                let text = "";
                for (let i = 0; i < this.site.building.brivo_devices.length; i++) {
                    text += this.site.building.brivo_devices[i].name;
                    if (i < this.site.building.brivo_devices.length - 1) {
                        text += ", "
                    }
                }
                return text;
            }
        },
        methods: {
            seeMore() {
                this.more = !this.more;
            }
        }
    };
</script>
