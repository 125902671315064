<template>
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h4>{{ building.building_name }}</h4>
        <p class="subtitle" v-if="building.portfolio !== null">
          <router-link :to="{name: 'portfolio', params: {'id': this.$route.params.id}}">{{ building.portfolio.business_name }}</router-link>
        </p>
        <p v-else class="d-block" style="margin-bottom: 39px;"></p>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-12 col-md-3">
        <Navigation />
      </div>
      <div class="col-md-7">
        <h4 class="font-weight-normal">Add Staff</h4>
        <small class="font-weight-bold building-small">Add new and existing building admin or create a new installer.</small>
        <button class="btn btn-primary mt-3" data-target="#newAdmin" data-toggle="modal">Add Building Admin</button>
        <button class="btn btn-primary mt-3" data-target="#newInstallerAdmin" data-toggle="modal">Add Installer</button>
        <h4 class="font-weight-normal mt-4">Manage Staff</h4>
        <small class="font-weight-bold building-small">Staff Members</small>
        <Admin :admin="admin" :key="admin.id" @updated="refreshData" v-for="admin in building.admins" />
      </div>
    </div>
    <footer class="building-footer">
      <div class="row">
        <div class="col-6 col-md-4 col-lg-3 col-xl-2" />
        <div class="col-6 col-md-4 offset-md-4 col-lg-3 offset-lg-6 col-xl-2 offset-xl-8">
          <router-link :to="'/portfolios/'+this.$route.params.id+'/building/'+this.$route.params.building_id+'/residents'" class="btn btn-primary btn-block" tag="button">Next</router-link>
        </div>
      </div>
    </footer>

    <!--Create New Building Admin Modal-->
    <div aria-hidden="true" aria-labelledby="newAdminLabel" class="modal fade" id="newAdmin" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="newAdminLabel">Add Building Admins</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 modal-messages" />
            </div>
            <h5>Create a new building admin</h5>
            <form @submit.prevent="postAdmin">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>First Name</label>
                    <input class="form-control" name="first_name" required type="text" v-model.trim="new_admin.first_name">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Last Name</label>
                    <input class="form-control" name="last_name" required type="text" v-model.trim="new_admin.last_name">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Email Address</label>
                    <input class="form-control" name="email" required type="email" v-model.trim="new_admin.email">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input class="form-control" name="phone_number" required type="text" v-model.trim="new_admin.phone_number">
                  </div>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-md-6">
                  <button :disabled="disabled" @click="postAdmin" class="btn btn-light btn-block" type="submit">Add to Building Admins</button>
                </div>
              </div>
            </form>
            <hr>
            <h5>Choose from existing admins</h5>
            <div class="scroll-box">
              <AddAdmin :admin="admin" :key="admin.id" v-for="admin in available_admins" />
            </div>
          </div>
          <div class="modal-footer">
            <button @click="refreshData" class="btn btn-secondary" type="button">Close</button>
            <button :disabled="disabled" @click="refreshData" class="btn btn-primary" type="button">Save Admins</button>
          </div>
        </div>
      </div>
    </div>

    <!--Create New Installer Admin Modal-->
    <div aria-hidden="true" aria-labelledby="newInstallerAdminLabel" class="modal fade" id="newInstallerAdmin" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="newInstallerAdminLabel">Add Installer</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 modal-messages" />
            </div>
            <h5>Create a new installer</h5>
            <form @submit.prevent="postInstallerAdmin">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>First Name</label>
                    <input class="form-control" name="first_name" required type="text" v-model.trim="new_installer_admin.first_name">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Last Name</label>
                    <input class="form-control" name="last_name" required type="text" v-model.trim="new_installer_admin.last_name">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Email Address</label>
                    <input class="form-control" name="email" required type="email" v-model.trim="new_installer_admin.email">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input class="form-control" name="phone_number" required type="text" v-model.trim="new_installer_admin.phone_number">
                  </div>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-6">
                  <button :disabled="false" @click="postInstallerAdmin" class="btn btn-primary offset-6" type="submit">Add to Staff</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
    import api from '../../../api.js';
    import AddAdmin from '../../../components/add/Admin.vue';
    import Admin from '../../../components/Admin.vue';
    import Navigation from '../../../components/BuildingNav.vue';

    export default {
        name: 'Admins',
        components: {
            AddAdmin,
            Admin,
            Navigation,
        },
        data() {
            return {
                available_admins: [],
                building: {
                    admins: [],
                    building_name: '',
                    portfolio: {
                        admins: [],
                        business_name: '',
                    },
                },
                disabled: false,
                new_admin: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: '',
                },
                new_installer_admin: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: '',
                },
            };
        },
        beforeMount() {
            const self = this;
            $('.loading').css('display', 'block');
            if (self.$route.params.building_id === 'undefined') {
                self.$router.go(-1);
                return;
            }

            self.getBuilding(true);
            self.getAvailableAdmins();
        },
        methods: {
            postAdmin() {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                api.postAdmin(self.new_admin, self.$route.params.building_id)
                    .then((response) => {
                        self.disabled = false;
                        let admin = response.admin;
                        admin.is_checked = true;
                        if (self.available_admins !== undefined) {
                          self.available_admins.unshift(admin);
                        }
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
            postInstallerAdmin() {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                api.postInstallerAdmin(
                    self.new_installer_admin,
                    self.$route.params.building_id,
                    self.$route.params.id
                )
                    .then((response) => {
                        self.disabled = false;

                        self.getBuilding(false);
                        self.getAvailableAdmins();
                        $('#newInstallerAdmin').modal('hide');
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
            getAvailableAdmins() {
                const self = this;
                api.getAvailableAdmins(self.$route.params.building_id)
                    .then((response) => {
                        self.available_admins = response.admins;
                    })
                    .catch((error) => {
                        self.handleError(error);
                    });
            },
            getBuilding(reload) {
                const self = this;
                api.getBuilding(self.$route.params.building_id)
                    .then((response) => {
                        self.building = response.building;

                        if (reload) {
                            $('.loading').css('display', 'none');
                            $('.content').css('display', 'block');
                            $('.content').animateCss('fadeIn');
                        }
                    })
                    .catch((error) => {
                        self.handleError(error);
                    });
            },
            refreshData() {
                this.getBuilding(false);
                this.getAvailableAdmins();
                $('#newAdmin').modal('hide');
            },
        },
    };
</script>
