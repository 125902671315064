import api from '../../api';
import {
    statuses,
} from '../../configs/stateKeys';

export default {
    namespaced: true,
    state: {
        building: {
            id: '',
            building_name: '',
            portfolio: {
                business_name: '',
            },
        },
        features: [],
        disabled: false,
        hasChanges: false,
    },
    getters: {
        building: (state) => state.building,
        features: (state) => state.features,
        disabled: (state) => state.disabled,
    },
    actions: {
        fetch: function({commit}, {buildingId}) {
            return api.getBuilding(buildingId)
                .then((response) => {
                    const {
                        building,
                        base_features: all_features,
                    } = response;
                    const {
                        UNAVAILABLE,
                        ENABLED,
                        DISABLED,
                    } = statuses;

                    let selected_features = building.new_features.map((new_feature) => (
                        new_feature.base_feature.identifier
                    ));

                    all_features.forEach((feature) => {
                        if (
                            feature.identifier === 'chat' ||
                            feature.identifier === 'maintenance' ||
                            feature.identifier === 'events' ||
                            feature.identifier === 'tasks'
                        ) {
                            feature.status = UNAVAILABLE;
                            selected_features.push(feature);
                        } else {
                            feature.status = (selected_features.includes(feature.identifier) ? ENABLED : DISABLED);
                        }
                    });

                    // re-order feature according to HCB-1920
                    const internet_feature_index = all_features.findIndex(feature => feature.identifier === 'internet');
                    if (internet_feature_index !== -1) {
                        const wifi_auto_shutoff_feature_index = all_features.findIndex(feature => feature.identifier === 'wifi-auto-shutoff');
                        if (wifi_auto_shutoff_feature_index !== -1) {
                            const wifi_auto_shutoff_feature = all_features[wifi_auto_shutoff_feature_index];
                            all_features.splice(wifi_auto_shutoff_feature_index, 1);
                            all_features.splice(internet_feature_index + 1, 0, wifi_auto_shutoff_feature);
                        }
                    }
                    commit('SET_BUILDING', building);
                    commit('SET_FEATURES', all_features);
                })
                .catch((error) => {
                    throw error;
                });
        },
        save: function({commit}) {
            if (this.state.plan.hasChanges) {
                commit('SET_DISABLED', true);
                return api.postBuildingPlan(this.state.plan.building.id)
                    .catch((error)=>{
                        commit('SET_DISABLED', false);
                        throw error;
                    });
            }
        },
        toggleFeature: function({commit}, {
            featureId,
            statusToSet,
        }) {
            const {
                DISABLING,
                DISABLED,
                ENABLING,
                ENABLED,
            } = statuses;

            commit('SET_DISABLED', true);

            const self = this;
            const oldStatus = self.state.plan.features.find((feature) => feature.id === featureId).status;

            let statusValue = 0;
            let newStatus = DISABLING;
            if (statusToSet === ENABLED) {
                statusValue = 1;
                newStatus = ENABLING;
            }

            commit('UPDATE_FEATURE', {
                id: featureId,
                status: newStatus,
            });

            return api.patchBuildingFeature(self.state.plan.building.id, featureId, statusValue)
                .then((response) => {
                    commit('UPDATE_FEATURE', {
                        id: featureId,
                        status: (!!response.feature.deleted_at ? DISABLED : ENABLED),
                    });
                    commit('SET_HAS_CHANGES', true);
                    commit('SET_DISABLED', false);
                })
                .catch((error) => {
                    commit('UPDATE_FEATURE', {
                        id: featureId,
                        status: oldStatus,
                    });
                    commit('SET_DISABLED', false);
                    throw error;
                });
        },
    },
    mutations: {
        SET_BUILDING(state, building) {
            state.building = building;
        },
        SET_DISABLED(state, disabled) {
            state.disabled = disabled;
        },
        SET_HAS_CHANGES(state, hasChanges) {
            state.hasChanges = hasChanges;
        },
        SET_FEATURES(state, features) {
            state.features = features;
        },
        UPDATE_FEATURE(state, updateFeaturePayload) {
            const {
                id,
                status,
            } = updateFeaturePayload;
            const feature = state.features.find((feature) => feature.id === id);
            feature.status = status;
        },
    },
};
