<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <router-link to="/"><img :src="'/img/logo.png'" alt="" class="logo"></router-link>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 alerts"></div>
    </div>
    <div class="row justify-content-center reset-card" v-if="valid">
      <div class="col col-md-auto">
        <div class="card">
          <div class="card-body p-5">
            <form @submit.prevent="reset">
              <div class="form-group">
                <label for="password">Password</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="far fa-lock-alt"></i></span>
                  </div>
                  <input class="form-control" id="password" type="password" v-model.trim="$v.form.password.$model" />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i :class="$v.form.password.$invalid ? 'fa-times text-danger' : 'fa-check text-success'" class="fas"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="password_confirmation">Confirm Password</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="far fa-lock-alt"></i></span>
                  </div>
                  <input class="form-control" id="password_confirmation" type="password" v-model.trim="$v.form.password_confirmation.$model" />
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i :class="$v.form.password_confirmation.$invalid ? 'fa-times text-danger' : 'fa-check text-success'" class="fas"></i>
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row mb-5" style="font-size: 12px;">
                <div class="col-12 col-md-6">
                  <i :class="$v.form.password.validateEightCharacters ? 'text-success' : 'text-danger'" class="fas fa-circle"></i> 8+ Characters
                </div>
                <div class="col-12 col-md-6">
                  <i :class="$v.form.password.validateUpperLower ? 'text-success' : 'text-danger'" class="fas fa-circle"></i> Upper and Lowercase Letters
                </div>
                <div class="col-12 col-md-6">
                  <i :class="$v.form.password.validateNumber ? 'text-success' : 'text-danger'" class="fas fa-circle"></i> Number(s)
                </div>
                <div class="col-12 col-md-6">
                  <i :class="$v.form.password.validateSymbol ? 'text-success' : 'text-danger'" class="fas fa-circle"></i> Symbol(s)
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-auto ml-auto">
                  <button :disabled="disabled || $v.$invalid" class="btn btn-primary w-100" style="min-width: 200px;" type="submit">Reset password</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../../api.js';
    import {required, sameAs} from 'vuelidate/lib/validators';

    export default {
        data() {
            return {
                disabled: false,
                form: {
                    reset_unique: '',
                    password: '',
                    password_confirmation: '',
                },
                valid: false,
            };
        },
        validations: {
            form: {
                password: {
                    validateEightCharacters() {
                        return /^.{8,}/.test(this.form.password);
                    },
                    validateUpperLower() {
                        return /^(?=.*?[A-Z])(?=.*?[a-z])/.test(this.form.password);
                    },
                    validateNumber() {
                        return /^(?=.*?[0-9])/.test(this.form.password);
                    },
                    validateSymbol() {
                        return /^(?=.*?[#?!@$%^&*-])/.test(this.form.password);
                    },
                    required
                },
                password_confirmation: {
                    matchText: sameAs('password'),
                    required
                }
            }
        },
        beforeMount() {
            const self = this;
            this.$store.commit('logoutUser');

            api.getResetLink(self.$route.params.reset_unique)
                .then((response) => {
                    self.valid = true;
                    self.form.reset_unique = this.$route.params.reset_unique;
                })
                .catch((error) => {
                    self.valid = false;
                    self.handleError(error);
                    setTimeout(function () {
                        self.$router.push({name: 'login'});
                    }, 5000);
                });
        },
        methods: {
            clearForm() {
                this.$v.$reset();
                Object.assign(this.$data, this.$options.data.call(this));
            },
            reset() {
                const self = this;
                if (self.disabled || self.$v.$invalid) return;
                self.disabled = true;

                api.putResetPassword(self.form)
                    .then((response) => {
                        self.clearForm();
                        self.disabled = false;
                        self.valid = false;
                        self.handleSuccess("Your password has been reset! You can now log in to Homebase Chell.");
                        setTimeout(function () {
                            self.$router.push({name: 'login'});
                        }, 5000);
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.clearForm();
                        self.handleError(error);
                    });
            },
        },
    };
</script>
