<template>
  <div :id="'tier'+tier.id" aria-hidden="true" aria-labelledby="tierLabel" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="tierLabel">Manage Tier</h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="putTier">
            <div class="form-group">
              <label for="name">Tier Name</label>
              <input type="text" class="form-control" :class="{'is-invalid':$v.form.name.$anyError}" id="name" v-model.trim="$v.form.name.$model">
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="up_speed">Up speed</label>
                  <div class="input-group">
                    <input type="number" class="form-control" :class="{'is-invalid':$v.form.up_speed.$anyError}" id="up_speed" min="0" step="1" v-model.trim="$v.form.up_speed.$model">
                    <div class="input-group-append">
                      <span class="input-group-text">Mbps</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="down_speed">Down speed</label>
                  <div class="input-group">
                    <input type="number" class="form-control" :class="{'is-invalid':$v.form.down_speed.$anyError}" id="down_speed" min="0" step="1" v-model.trim="$v.form.down_speed.$model">
                    <div class="input-group-append">
                      <span class="input-group-text">Mbps</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="cost">Price per month</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">$</span>
                    </div>
                    <input type="number" class="form-control" :class="{'is-invalid':$v.form.cost.$anyError}" id="cost" min="0" step=".01" v-model.trim="$v.form.cost.$model">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Tier Description</label>
              <small>Choose a description that reflects tier speed.</small>
              <input type="text" class="form-control" :class="{'is-invalid':$v.form.description.$anyError}" id="description" v-model.trim="$v.form.description.$model">
            </div>
            <div class="form-group">
              <label>Tier Image</label>
              <small>Choose the image that reflects the tier speed.</small>
              <br />
              <span :key="image.id" v-for="image in images">
                <label>
                  <input type="radio" class="image-radio" :class="{'is-invalid':$v.form.image_url.$anyError}" name="image_url" v-model.trim="$v.form.image_url.$model" :value="image.image_url" />
                  <img :src="image.image_url" class="tier-image-select" :alt="image.identifier" />
                </label>
              </span>
            </div>
            <div class="form-group">
              <input type="checkbox" class="form-control d-inline-block" id="default" v-model.trim="form.default">
              <label for="default">Default Tier</label>
              <small>This tier will pre-selected when residents sign up via Homebase.</small>
            </div>
            <div class="form-group">
              <input type="checkbox" class="form-control d-inline-block" id="downgrade" v-model.trim="form.downgrade">
              <label for="downgrade">Downgrade Tier</label>
              <small>This tier will selected when a residents wifi has been suspended and automatically downgraded.</small>
            </div>
            <button class="d-none" type="submit"></button>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-danger mr-auto" data-dismiss="modal" type="button" :disabled="deleteTierDisabled" @click="deleteTier"> Delete Tier</button>
          <button :disabled="disabled" @click="putTier" class="btn btn-primary" type="button">Save Tier</button>
          <div class="delete-tier-disabled-msg-container">
            <span v-if="deleteTierDisabled" class="delete-tier-disabled-msg">Default tier cannot be deleted. Change default tier before deleting.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.delete-tier-disabled-msg-container {
  width: 100%;
}
.delete-tier-disabled-msg {
  color: #AEAEAE;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
</style>

<script>
    import api from '../../api.js';
    import {decimal, maxLength, integer, required} from 'vuelidate/lib/validators';

    export default {
        name: "EditWiFiTierModal",
        props: {
            tier: Object,
            images: Array,
            default_id: String,
            downgrade_id: String,
        },
        data() {
            return {
                disabled: false,
                deleteTierDisabled: false,
                form: {
                    name: '',
                    up_speed: 0,
                    down_speed: 0,
                    cost: 0.00,
                    description: '',
                    image_url: '',
                    default: false,
                    downgrade: false
                },
            };
        },
        validations: {
            form: {
                name: {
                    maxLength: maxLength(255),
                    required
                },
                up_speed: {
                    integer,
                    required
                },
                down_speed: {
                    integer,
                    required
                },
                cost: {
                    decimal,
                    required
                },
                description: {
                    required
                },
                image_url: {
                    required
                }
            }
        },
        beforeMount() {
            const self = this;
            self.form.tier_id = self.tier.id;
            self.form.name = self.tier.name;
            self.form.up_speed = self.tier.up_speed;
            self.form.down_speed = self.tier.down_speed;
            self.form.cost = self.tier.cost / 100;
            self.form.description = self.tier.description;
            self.form.image_url = self.tier.image_url;
            if (self.default_id === self.tier.id) {
                self.form.default = true;
            }
            if (self.downgrade_id === self.tier.id) {
              self.form.downgrade = true;
            }
            if(self.form.default) {
              this.deleteTierDisabled = true;
            }
        },
        methods: {
            resetForm() {
                this.$v.$reset();
                Object.assign(this.$data, this.$options.data.call(this));
            },
            deleteTier() {
              const self = this;
              if (self.disabled) return;
              self.disabled = true;

              api.deleteBuildingInternetTier(self.$route.params.building_id, self.tier.id)
                  .then((response) => {
                    self.disabled = false;
                    $('#tier' + self.tier.id).modal('hide');
                    self.$emit('updated', self.tier);
                    self.handleSuccess('Successfully Removed Wifi Tier');
                  })
                  .catch((error) => {
                    self.disabled = false;
                    self.handleError(error);
                  });
            },
            putTier() {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                api.putBuildingInternetTier(self.form, self.$route.params.building_id)
                    .then((response) => {
                        self.disabled = false;
                        $('#tier' + self.tier.id).modal('hide');
                        self.$emit('updated', self.tier);
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
        }
    }
</script>
