<template>
  <div class="row">
    <div class="col">
      <div
        :data-target="'#space'+space.id"
        class="card card-link"
        data-toggle="modal">
        <div class="card-body">
          <div class="row">
            <div class="col-4 font-weight-bold">{{ space.space_name }}</div>
            <div class="col-4">Floor {{ space.floor }}</div>
            <div
              class="col-4"
              style="text-transform: capitalize">{{ space.access_type }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      :id="'space'+space.id"
      aria-hidden="false"
      aria-labelledby="spaceLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1">
      <div
        class="modal-dialog"
        role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              id="spaceLabel"
              class="modal-title">{{ space.space_name }}</h5>
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button">
              <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"/></span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="putSpace">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Name</label>
                    <input
                      v-model.trim="form.space_name"
                      class="form-control"
                      required
                      type="text">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Floor</label>
                    <input
                      v-model.trim="form.floor"
                      class="form-control"
                      required
                      type="text">
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Room permission</label>
                    <p>Unrestricted - By default, both residents and staff can access this space. Used for public spaces.</p>
                    <p>Restricted - By default, only staff can access this space. Used for private spaces.</p>
                    <input
                      v-model.trim="form.restricted"
                      class="form-control"
                      required
                      type="hidden">
                  </div>
                </div>
                <div class="col-12 mb-1">
                  <div
                    class="btn-group"
                    style="width: 100%">
                    <button
                      type="button"
                      class="btn dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style="width: 100%">
                      {{ form.restricted === true ? 'Restricted':'Unrestricted' }}
                    </button>
                    <div
                      class="dropdown-menu"
                      style="width: 100%">
                      <a
                        class="dropdown-item"
                        @click="updatePermission(true)">Restricted</a>
                      <a
                        class="dropdown-item"
                        @click="updatePermission(false)">Unrestricted</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group" style="display: flex; align-items: flex-start;">
                  <input
                      :id="'device_type_restrictions' + space.id"
                      v-model.trim="form.device_type_restrictions"
                      class="form-control"
                      style="vertical-align: middle;"
                      type="checkbox"
                  >
                  <label :for="'device_type_restrictions' + space.id" style="margin-left: 8px; font-weight: normal;">
                    Lock only device control for residents <b>Highly recommended</b>
                    Unchecking this will grant full device permissions to residents added to this space. Only uncheck this for shared private spaces such as student housing suites.
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label>Description</label>
                <textarea
                  v-model.trim="form.description"
                  class="form-control"
                  maxlength="255" />
              </div>
              <!-- <div v-if="internet">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Device WiFi - SSID</label>
                      <input class="form-control" type="text" v-model.trim="form.device_ssid">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Device WiFi - Password</label>
                      <input class="form-control" type="text" v-model.trim="form.device_password">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Space WiFi - SSID</label>
                      <input class="form-control" type="text" v-model.trim="form.space_ssid">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Space WiFi - Password</label>
                      <input class="form-control" type="text" v-model.trim="form.space_password">
                    </div>
                  </div>
                </div>
              </div> -->

              <div v-if="tlj_building_enabled">
                <div class="row" >
                  <div class="col-12">
                    <div class="form-group">
                      <label>TLJ Room ID</label>
                      <input class="form-control" type="text" v-model.trim="form.tlj_room_name">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>TLJ Area ID</label>
                      <input class="form-control" type="text" v-model.trim="form.tlj_area_id">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>TLJ Floor ID</label>
                      <input class="form-control" type="text" v-model.trim="form.tlj_floor_id">
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="d-none"
                type="submit" />
            </form>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              data-dismiss="modal"
              type="button">Close</button>
            <button
              class="btn btn-danger"
              data-dismiss="modal"
              type="button"
              @click="deleteSpace">Delete</button>
            <button
              :disabled="disabled"
              class="btn btn-primary"
              type="button"
              @click="putSpace">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right;
  margin-top: 10px;
}
.dropdown-toggle{
  border: 1px solid #979797;
}
.dropdown-item {
  border-bottom: 1px solid #979797;
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
}
</style>
<script>
import api from '../api.js';

export default {
  name: 'Space',
  props: {
    space: Object,
    internet: Boolean,
    tlj_building_enabled: Boolean,
    tlj_rooms: Array,
  },
  data() {
    return {
      disabled: false,
      form: {
        space_name: '',
        floor: '',
        description: 'No description',
        restricted: true,
        device_type_restrictions: true,
        tlj_room_name: null,
        tlj_area_id: null,
        tlj_floor_id: null,
      },
    };
  },
  computed: {
    active() {
      return moment.utc(this.space.updated_at).fromNow();
    },
    added() {
      return moment.utc(this.space.created_at).fromNow();
    },
    restrictedValue() {
      const self = this;
      if (self.form.restricted === true) {
        return 'Restricted';
      }
      return 'Unrestricted';
    },
    getRouteParams() {
      const portfolioId = this.$route.params.id;
      const buildingId = this.$route.params.building_id;

       if (!portfolioId || !buildingId) {
         return null;
       }
      return {
        id: portfolioId,
        building_id: buildingId,
      };
    },
  },
  mounted() {
    const self = this;
    $('#space' + self.space.id).on('hide.bs.modal', function() {
      self.form = self.space;
      self.form.restricted = self.space.access_type === 'restricted';
      self.$emit('updated');
    });
  },
  beforeMount() {
    const self = this;
    self.form = self.space;
    self.form.restricted = self.space.access_type === 'restricted';
    const tljRoom = self.tlj_rooms.filter((room) => room.hub_id === self.space.hub_id);
    self.form.tlj_room_name = tljRoom.length > 0 ? tljRoom[0].tlj_room_name: null;
    self.form.tlj_area_id = tljRoom.length > 0 ? tljRoom[0].tlj_area_id: null;
    self.form.tlj_floor_id = tljRoom.length > 0 ? tljRoom[0].tlj_floor_id: null;
  },
  methods: {
    putSpace() {
      const self = this;
      if (self.disabled) return;
      self.disabled = true;
      api.putSpace(self.form)
          .then((response) => {
            self.disabled = false;
            const { space } = response;
            if (space && self.tlj_building_enabled && self.form.tlj_room_name) {
              api.putTljSpace(
                  space.id,
                  self.form.tlj_room_name,
                  self.form.tlj_area_id,
                  self.form.tlj_floor_id
              ).catch((error) => {
                self.disabled = false;
                self.handleError(error);
              });
            }
            self.form = space;
            self.form.restricted = space.access_type === 'restricted';
            $('#space' + space.id).modal('hide');
            self.$emit('updated');
          })
          .catch((error) => {
            self.disabled = false;
            self.handleError(error);
          });
    },
    deleteSpace: function() {
      const self = this;
      if (self.disabled) return;
      self.disabled = true;
      const message = 'Would you like to delete this space ?';
      if (confirm(message)) {
        api.deleteSpace(self.form)
            .then(() => {
              $('#space' + self.space.id).modal('hide');
              self.handleSuccess(self.space.space_name + ' has been successfully removed');
              self.$emit('updated');

              const routeParams = self.getRouteParams;

              if (routeParams) {
                this.$router.push({
                  name: 'buildingamenityspaces',
                  params: routeParams,
                });
              }
            })
            .catch((error) => {
              self.disabled = false;
              self.handleError(error);
            });
      }
    },
    updatePermission(value) {
      const self = this;
      this.$nextTick(function() {
        self.form.restricted = value;
        this.$forceUpdate();
      });
    },
  },
};
</script>
