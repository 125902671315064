<template>

</template>

<script>
    export default {
        name: 'Index',
        mounted() {
            this.handleError('You do not have permission to access the requested resource.');
            $('.loading').css('display', 'none');
        },
    };
</script>
