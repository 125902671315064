export default {
  namespaced: true,
  state: {
    hidden: [],
    content: {},
    open: [],
  },
  getters: {
    allHidden: (state) => state.hidden,
    hiddenById: (state) => (id) => {
      return state.hidden.includes(id);
    },
    allOpen: (state) => state.open,
    openById: (state) => (id) => {
      return state.open.includes(id);
    },
    content: (state) => state.content,
    contentById: (state) => (id) => {
      return state.content[id];
    },
  },
  mutations: {
    OPEN: (state, modalData) => {
      const { modalId, contentData } = modalData;

      state.content[modalId] = contentData;
      state.hidden = state.hidden.filter((hiddenModalId) => modalId !== hiddenModalId);
      state.open.push(modalId);
    },
    CLOSE: (state, modalId) => {
      state.content[modalId] = undefined;
      state.open = state.open.filter((openModalId) => openModalId !== modalId);
      state.hidden.push(modalId);
    },
  },
  actions: {
    open: ({ commit }, modalData) => commit('OPEN', modalData),
    close: ({ commit }, { modalId }) => commit('CLOSE', modalId),
  },
};
