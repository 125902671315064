export default {
    namespaced: true,
    state: {
        deleteFobForm: {},
    },
    getters: {
        deleteFobForm: (state) => state.deleteFobForm,
    },
    mutations: {
        SET_FORM_DATA: (state, formData) => {
            state.deleteFobForm = {...formData};
        },
        CLEAR_FORM_DATA: (state) => {
            state.deleteFobForm = {};
        },
    },
    actions: {
        setFormData: ({commit}, formData) => {
            commit('SET_FORM_DATA', formData);
        },
        clearFormData: ({commit}) => commit('CLEAR_FORM_DATA'),
    },
};
