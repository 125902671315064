<template>
  <!-- MODAL START -->
  <div class="modal" id="fobsModal" tabindex="-1" role="dialog" aria-labelledby="fobsModalTitle"
       aria-hidden="true">

    <!-- MODAL DIALOG START -->
    <div class="modal-dialog modal-xl" role="document">


      <!-- MODAL CONTENT START -->
      <div class="modal-content">


        <!-- HEADER START -->
        <div class="modal-header">
          <h5 class="modal-title" id="fobsModalTitle">Fob search</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- HEADER END -->


        <!-- BODY START -->
        <div class="modal-body">
          <div>
            <!-- SEARCH FORM START -->

            <form @submit.prevent="submitFobSearchForm">
              <div class="row">
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label for="start_number">Start Number</label>
                    <input :class="{'is-invalid':$v.search_form.start_number.$anyError}" class="form-control" id="start_number" type="text" v-model.trim="$v.search_form.start_number.$model"/>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label for="end_number">End Number (optional)</label>
                    <input :class="{'is-invalid':$v.search_form.end_number.$anyError}" class="form-control" id="end_number" type="text" v-model.trim="$v.search_form.end_number.$model"/>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label for="facility_code">Facility Code</label>
                    <input :class="{'is-invalid':$v.search_form.facility_code.$anyError}" class="form-control" id="facility_code" type="text" v-model.trim="$v.search_form.facility_code.$model"/>
                  </div>
                </div>
                <div class="col-12 col-md-3">
                  <div class="form-group">
                    <label for="bits">Bits</label>
                    <select :class="{'is-invalid':$v.search_form.bits.$anyError}" class="form-control" id="bits" v-model.trim="$v.search_form.bits.$model">
                      <option value="26">26A</option>
                      <option value="35">35C</option>
                      <option value="37">37X</option>
                      <option value="37">37H</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-12 text-right">
                  <div class="form-group">
                    <button type="button" class="btn btn-outline-danger btn-clear" v-if="hasSearched" @click="resetSearchForm">Clear</button>
                    <button
                      type="button"
                      class="btn btn-primary btn-search"
                      @click="submitFobSearchForm"
                      :disabled="searchButtonDisabled"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!-- SEARCH FORM END -->

            <template v-if="hasSearched">
              <template v-if="fobs.length > 0">

                <!-- FOBS SEARCH FIELD START -->
                <div class="row">
                  <div class="col-12">
                    <div class="form-group input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-search fa-fw"></i></span>
                      </div>
                      <input type="text" class="form-control" placeholder="Search..." v-model.trim="search_text"/>
                    </div>
                  </div>
                </div>
                <!-- FOBS SEARCH FIELD END -->

                <div class="row fob-table-header font-weight-bold">
                  <div class="col-6 col-lg-1">Key</div>
                  <div class="col-6 col-lg-1">FC</div>
                  <div class="col-6 col-lg-1">Bit count</div>
                  <div class="col-6 col-lg-2">Assignment</div>
                  <div class="col-6 col-lg-1">User Type</div>
                  <div class="col-6 col-lg-2">Communities</div>
                  <div class="col-6 col-lg-2">Date Added</div>
                  <div class="col-6 col-lg-2"></div>
                </div>

                <template v-for="fob in displayedFobs">
                  <FobListItem :fob="fob" @trigger-reload="reloadFobsList"/>
                </template>
              </template>


              <!-- NO RESULTS START -->
              <div class="row" v-else>
                <div class="col-12 col-md-12 text-center">
                  <span class="no-results-label">No key fobs have been batched that match these search results</span>
                </div>
              </div>
              <!-- NO RESULTS END -->
            </template>


            <!-- NO SEARCH START -->
            <template v-else>
              <div class="row">
                <div class="col-12 col-md-12 text-center">
                  <span class="no-results-label">Search to see results</span>
                </div>
              </div>
            </template>
            <!-- NO SEARCH END -->

          </div>
        </div>
        <!-- BODY END -->


      </div>
      <!-- MODAL CONTENT START -->

    </div>
    <!-- MODAL DIALOG END -->

  </div>
  <!-- MODAL END -->
</template>


<style scoped lang="scss">
.no-results-label {
  font-size: 15px;
  font-weight: 500;
  line-height: 17.9px;
  text-align: center;
  color: #898989;
}

.btn-clear {
  margin-right: 12px;
}

.btn-search {
  width: 194px;
}
</style>

<script>

import {integer, required} from 'vuelidate/lib/validators';
import api from '../../api';
import FobListItem from '../../components/partials/FobLIstItem.vue';

export default {
  name: 'FobsModal',
  components: { FobListItem },
  methods: {
    resetSearchForm() {
      this.search_text = ''
      this.search_form.start_number = ''
      this.search_form.end_number = ''
      this.search_form.facility_code = ''
      this.search_form.bits = ''
      this.hasSearched = false
      this.fobs = []
      this.search_text = ''
    },
    submitFobSearchForm() {
      api.getChellFobs(
          this.search_form.start_number,
          this.search_form.end_number,
          this.search_form.facility_code,
          this.search_form.bits
      )
        .then((response) => {
          this.fobs = response;
          this.displayedFobs = this.fobs;
        })
        .catch((error) => {
          console.log('Error: ', error)
        })
        .finally(() => {
          this.hasSearched = true;
        });
    },
    reloadFobsList() {
      this.submitFobSearchForm()
    }
  },

  data() {
    return {
      search_form: {
        start_number: '',
        end_number: '',
        facility_code: '',
        bits: '',
      },
      search_form_errors: [],
      hasSearched: false,
      fobs: [],
      search_text: ''

    }
  },
  computed: {
    displayedFobs() {
      const searchText = this.search_text
      return this.fobs.filter((fob) => {
        return (fob.card_number.toString().toLowerCase().indexOf(searchText) > -1) ||
            (fob.facility_code.toString().toLowerCase().indexOf(searchText) > -1) ||
            (fob.bits.toString().toLowerCase().indexOf(searchText) > -1) ;
      })
    },
    searchButtonDisabled() {
      return this.$v.$invalid ||
        ((this.search_form.start_number !== '' && this.search_form.end_number !== '') &&
          this.search_form.start_number > this.search_form.end_number)
    }
  },
  validations: {
    search_form: {
      start_number: {
        integer,
        required,
      },
      end_number: {
        integer,
      },
      facility_code: {
        integer,
        required,
      },
      bits: {
        required,
      },
    },
  }


};
</script>
