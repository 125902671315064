<template>
  <div />
</template>
<script>
    export default {
        beforeMount() {
            this.$store.commit('logoutUser');
            this.$router.push({name: 'login'});
        },
    };
</script>
