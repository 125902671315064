<template>
  <div aria-hidden="true" aria-labelledby="createSuperAdminLabel" class="modal fade" id="createSuperAdmin" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createSuperAdminLabel">Add Homebase Support Account</h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <h5>Create a new homebase support account</h5>
          <form @submit.prevent="postSuperAdmin">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="first_name">First Name</label>
                  <input :class="{'is-valid':!$v.form.first_name.$anyError && $v.form.first_name.$dirty, 'is-invalid':$v.form.first_name.$anyError}" class="form-control" id="first_name" type="text" v-model.trim="$v.form.first_name.$model">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="last_name">Last Name</label>
                  <input :class="{'is-valid':!$v.form.last_name.$anyError && $v.form.last_name.$dirty, 'is-invalid':$v.form.last_name.$anyError}" class="form-control" id="last_name" type="text" v-model.trim="$v.form.last_name.$model">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="email">Email Address</label>
                  <input :class="{'is-valid':!$v.form.email.$anyError && $v.form.email.$dirty, 'is-invalid':$v.form.email.$anyError}" class="form-control" id="email" type="email" v-model.trim="$v.form.email.$model">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="phone_number">Phone Number</label>
                  <input :class="{'is-valid':!$v.form.phone_number.$anyError && $v.form.phone_number.$dirty, 'is-invalid':$v.form.phone_number.$anyError}" class="form-control" id="phone_number" type="text" v-model.trim="$v.form.phone_number.$model">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label :for="job_title">Job Title</label>
                  <input :class="{'is-valid':!$v.form.job_title.$anyError && $v.form.job_title.$dirty, 'is-invalid':$v.form.job_title.$anyError}" :id="job_title" class="form-control" type="text" v-model.trim="$v.form.job_title.$model">
                </div>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-12 col-md-6">
                <button :disabled="disabled || $v.$invalid" class="btn btn-outline-primary btn-block" type="submit">Add to Homebase Support Accounts</button>
              </div>
            </div>
          </form>
          <hr>
          <h5>Choose from existing admins</h5>
          <div class="scroll-box">
            <div :key="admin.id" class="row" v-for="admin in existing_admins">
              <div class="col-12">
                <div :id="'card'+admin.id" @click="toggleChecked(admin)" class="card card-link card-select">
                  <div class="card-body">
                    <div class="row d-flex">
                      <div class="col">
                        {{ admin.first_name }} {{ admin.last_name }}
                      </div>
                      <div class="col-auto">
                        {{ admin.email }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-block">
          <div class="row">
            <div class="col text-right">
              <button :disabled="disabled" class="btn btn-primary" type="button" v-on:click="updateSuperAdmins">Save Homebase Support Accounts</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../../api.js';
    import {email, maxLength, numeric, required} from 'vuelidate/lib/validators';

    export default {
        name: 'CreateSuperAdmin',
        props: {
            existing_admins: Array
        },
        data() {
            return {
                disabled: false,
                form: {
                    first_name: 'Homebase',
                    last_name: 'Support',
                    email: '',
                    phone_number: '',
                    job_title: 'Property Manager',
                },
            };
        },
        validations: {
            form: {
                first_name: {
                    maxLength: maxLength(255),
                    required,
                },
                last_name: {
                    maxLength: maxLength(255),
                    required,
                },
                email: {
                    maxLength: maxLength(255),
                    email,
                    required,
                },
                phone_number: {
                    maxLength: maxLength(255),
                    numeric,
                    required,
                },
                job_title: {
                    maxLength: maxLength(255),
                },
            },
        },
        methods: {
            resetForm() {
                this.$v.$reset();
                Object.assign(this.$data, this.$options.data.call(this));
            },
            postSuperAdmin() {
                const self = this;
                if (self.disabled || self.$v.$invalid) return;
                self.disabled = true;

                self.form.portfolio_id = self.$route.params.id;
                api.postSuperAdmin(self.form)
                    .then((response) => {
                        self.disabled = false;

                        let admin = response.super_admin.admin;
                        admin.is_checked = true;
                        self.existing_admins.unshift(admin);
                        self.wait('#card' + admin.id, 100);

                        self.resetForm();
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
            wait(selector, time) {
                const self = this;
                if ($(selector).length > 0) {
                    $(selector).addClass('checked');
                    return;
                }
                setTimeout(function () {
                    self.wait(selector, time);
                }, time);
            },
            toggleChecked(admin) {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                admin.is_checked = !admin.is_checked;

                if (admin.is_checked) {
                    api.putSuperAdmin(admin)
                        .then((response) => {
                            self.disabled = false;
                            $('#card' + admin.id).addClass('checked');
                        })
                        .catch((error) => {
                            self.disabled = false;
                            self.handleError(error);
                        });
                } else {
                    api.deleteSuperAdmin(admin)
                        .then((response) => {
                            self.disabled = false;
                            $('#card' + admin.id).removeClass('checked');
                        })
                        .catch((error) => {
                            self.disabled = false;
                            self.handleError(error);
                        });
                }
            },
            updateSuperAdmins() {
                $('#createSuperAdmin').modal('hide');
                this.$emit('updated');
            },
        },
    };
</script>
