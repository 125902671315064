import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from './views/auth/Login.vue';
import Reset from './views/auth/Reset.vue';
import Logout from './views/auth/Logout.vue';
import Index from './views/Index.vue';
import Portfolios from './views/portfolios/Portfolios.vue';
import Portfolio from './views/portfolios/Overview.vue';
import CreatePortfolio from './views/portfolios/CreatePortfolio.vue';
import CreateBuilding from './views/portfolios/building/Create.vue';
import BuildingInformation from './views/portfolios/building/Information.vue';
import BuildingPlan from './views/portfolios/building/Plan.vue';
import BuildingConfiguration from './views/portfolios/building/Configuration.vue';
import BuildingWiFi from './views/portfolios/building/WiFi.vue';
import BrivoSite from './views/portfolios/building/BrivoSite.vue';
import BrivoGroups from './views/portfolios/building/BrivoGroups.vue';
import BuildingAdmins from './views/portfolios/building/Admins.vue';
import BuildingUnits from './views/portfolios/building/Units.vue';
import BuildingAmenitySpaces from './views/portfolios/building/AmenitySpaces.vue';
import BuildingResidents from './views/portfolios/building/Residents.vue';
import BuildingLaunch from './views/portfolios/building/Launch.vue';
import Queue from './views/Queue.vue';
import Employees from './views/Employees.vue';

import store from './store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: {name: 'login'},
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/reset/:reset_unique',
        name: 'reset',
        component: Reset,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/#',
        name: 'index',
        component: Index,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios',
        name: 'portfolios',
        component: Portfolios,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/create',
        name: 'createportfolio',
        component: CreatePortfolio,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id',
        name: 'portfolio',
        component: Portfolio,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id/edit',
        name: 'editportfolio',
        component: CreatePortfolio,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id/building/create',
        name: 'createbuilding',
        component: CreateBuilding,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id/building/:building_id/information',
        name: 'editbuilding',
        component: BuildingInformation,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id/building/:building_id/plan',
        name: 'buildingplan',
        component: BuildingPlan,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id/building/:building_id/configuration',
        name: 'buildingconfiguration',
        component: BuildingConfiguration,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id/building/:building_id/wifi',
        name: 'buildingwifi',
        component: BuildingWiFi,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id/building/:building_id/brivo',
        redirect: {name: 'brivosite'},
    },
    {
        path: '/portfolios/:id/building/:building_id/brivo/site',
        name: 'brivosite',
        component: BrivoSite,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id/building/:building_id/brivo/groups',
        name: 'brivogroups',
        component: BrivoGroups,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id/building/:building_id/admins',
        name: 'buildingadmins',
        component: BuildingAdmins,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id/building/:building_id/units',
        name: 'buildingunits',
        component: BuildingUnits,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id/building/:building_id/amenity-spaces',
        name: 'buildingamenityspaces',
        component: BuildingAmenitySpaces,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id/building/:building_id/residents',
        name: 'buildingresidents',
        component: BuildingResidents,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolios/:id/building/:building_id/launch',
        name: 'buildinglaunch',
        component: BuildingLaunch,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/queue',
        name: 'queue',
        component: Queue,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/employees',
        name: 'employees',
        component: Employees,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '*',
        redirect: {name: 'login'},
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((route) => route.meta.requiresAuth) && store.state.user === null) {
        next({name: 'login'});
        return;
    }
    if (to.path === '/login' && store.state.user != null) {
        next({name: 'portfolios'});
        return;
    }
    next();
});

export default router;
