<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-3">
            {{ building.building_name }}
          </div>
          <div class="col-12 col-md-2"/>
          <div class="col-12 col-md-2"/>
          <div class="col-12 col-md-2"/>
          <div class="col-12 col-md-3 text-right">
            <DropdownMenu
              label="Actions"
              :disabled="actionsDisabled"
              :menuItems="menuItems"
              @trigger-action-delete="batchDeleteForBuildingKeys"
              @trigger-action-add-edit="addEditForBuildingKeys"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownMenu from '../elements/DropdownMenu.vue';

export default {
  name: 'BuildingKeysListItem',

  components: {
    DropdownMenu,
  },

  props: {
    building: Object,
  },

  data() {
    return {
      actionsDisabled: false,
      modalHiddenAddEdit: true,
      menuItems: [
        {
          key: 'add-edit',
          label: 'Add/Edit',
          icon: 'plus-circle',
          event: {
            name: 'trigger-action-add-edit',
            data: {
              name: 'building-keys-add-edit',
            },
          },
        },
        {
          key: 'batch-delete',
          label: 'Batch delete',
          icon: 'trash-alt',
          event: {
            name: 'trigger-action-delete',
            data: {
              name: 'building-keys-delete',
            },
          },
        },
      ],
    };
  },

  methods: {
    batchDeleteForBuildingKeys(eventData) {
      this.$emit('update-building-keys-delete', {building: this.building});
    },
    addEditForBuildingKeys(eventData) {
      this.$emit('update-building-keys-add-edit', {building: this.building});
    },
  },
};
</script>
