<template>
<div v-if="building != null" @scroll="onScroll">
  <form @submit.prevent="assignPortfolioKeys">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label :for="'start_number'+building.id">Start Number</label>
          <input :class="{'is-invalid':$v.form.start_number.$anyError}" class="form-control" :id="'start_number'+building.id" type="text" v-model.trim="$v.form.start_number.$model">
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label :for="'end_number'+building.id">End Number</label>
          <input :class="{'is-invalid':$v.form.end_number.$anyError}" class="form-control" :id="'end_number'+building.id" type="text" v-model.trim="$v.form.end_number.$model">
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label :for="'facility_code'+building.id">Facility Code</label>
          <input :class="{'is-invalid':$v.form.facility_code.$anyError}" class="form-control" :id="'facility_code'+building.id" type="text" v-model.trim="$v.form.facility_code.$model">
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="form-group">
          <label :for="'key_type'+building.id">Key Type</label>
          <select :class="{'is-invalid':$v.form.key_type.$anyError}" class="form-control" :id="'key_type'+building.id" v-model.trim="$v.form.key_type.$model">
            <option v-for="type in fob_types" :value="type.id" :key="type.id">{{ type.name }}</option>
          </select>
        </div>
      </div>
      <div class="col"></div>
      <div class="col-auto">
        <button
          type="submit"
          class="btn btn-primary"
          @click="assignPortfolioKeys"
          :disabled="disabled || form.start_number > form.end_number || form.start_number === '' || form.end_number === ''"
        >
          Assign <strong>{{ batch_keys }}</strong> Key<span v-if="batch_keys !== 1">s</span>
        </button>
      </div>
    </div>
  </form>

  <hr />
  <!-- FOBS SEARCH FIELD -->
  <div class="row">
    <div class="col-12">
      <div class="form-group input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fas fa-search fa-fw"></i></span>
        </div>
        <input type="text" class="form-control" placeholder="Search card numbers..." v-model.trim="search">
      </div>
    </div>
  </div>
  <!-- LOADING FOBS -->
  <div class="row justify-content-center text-center" v-if="loadingFobsContent">
    <div class="col-md-6">
      <i class="far fa-key fa-fw fa-2x d-block m-auto iconBadge"></i>
      <h6 v-if="search === ''" class="mt-2">Loading keys</h6>
      <h6 v-else class="mt-2">Searching keys</h6>
    </div>
  </div>
  <!-- NO FILTERED FOBS CONTENT -->
  <div class="row justify-content-center text-center" v-else-if="hasNoFilteredFobs()">
    <div class="col-md-6">
      <i class="far fa-key fa-fw fa-2x d-block m-auto iconBadge"></i>
      <h6 class="mt-2">{{ searchEmptyString }}</h6>
    </div>
  </div>
  <!-- FILTERED FOBS CONTENT -->
  <div v-else>
    <div class="row fob-table-header font-weight-bold">
      <div class="col-6 col-lg-2">Key <span class="float-right font-italic">FC</span></div>
      <div class="col-6 col-lg-3">Assignment</div>
      <div class="col-6 col-lg-2">Type</div>
      <div class="col-6 col-lg-2">Date Added</div>
      <div class="col-12 col-lg-3"></div>
    </div>
    <div class="row fob-table-border" v-for="fob in filteredAssigned" :key="fob.id">
      <div class="col-6 col-lg-2">{{ fob.card_number }} <span class="float-right font-italic">{{ fob.facility_code }}</span></div>
      <div class="col-6 col-lg-3">
        <span v-if="fob.user && fob.user.first_name">
          {{ fob.user.first_name }} {{ fob.user.last_name }}
          <span v-if="fob.user_type === 'App\\Models\\Tenant'" class="float-right font-italic">Tenant</span>
          <span v-if="fob.user_type === 'App\\Models\\Admin'" class="float-right font-italic">Admin</span>
          <span v-if="fob.user_type === 'App\\Models\\SuperAdmin'" class="float-right font-italic">Super Admin</span>
        </span>
        <span v-else class="text-muted font-italic">Unknown User</span>
      </div>
      <div class="col-6 col-lg-2"><span v-if="fob.fob_type !== null">{{ fob.fob_type.name }}</span><span v-else class="text-muted font-italic">N/A</span></div>
      <div class="col-6 col-lg-2">{{ getDate(fob.created_at) }}</div>
      <div class="col-12 col-lg-3 font-weight-bold text-right">
        <span class="text-primary mr-3 d-none" :id="'unassignconfirm-'+fob.id" @click="unassignFob(fob.id)" style="cursor:pointer;">Confirm</span>
        <span class="text-danger" :id="'unassign-'+fob.id" @click="showUnassignConfirm(fob.id)" style="cursor:pointer;">Unassign</span>
        <span v-if="fob.user_type === 'App\\Models\\SuperAdmin'">
          <span class="text-primary d-none" :id="'portfolioconfirm-'+fob.id" @click="addToPortfolio(fob.id)" style="cursor:pointer;">Confirm</span>
          <span class="text-danger ml-3" :id="'portfolio-'+fob.id" @click="showPortfolioConfirm(fob.id)" style="cursor:pointer;">Move to Portfolio</span>
        </span>
      </div>
    </div>
    <div class="row fob-table-border" v-for="fob in filteredAssignedPortfolio" :key="fob.id">
      <div class="col-6 col-lg-2">{{ fob.card_number }} <span class="float-right font-italic">{{ fob.facility_code }}</span></div>
      <div class="col-6 col-lg-3">
        <span v-if="fob.user && fob.user.first_name">
          {{ fob.user.first_name }} {{ fob.user.last_name }}
          <span v-if="fob.user_type === 'App\\Models\\Tenant'" class="float-right font-italic">Tenant</span>
          <span v-if="fob.user_type === 'App\\Models\\Admin'" class="float-right font-italic">Admin</span>
          <span v-if="fob.user_type === 'App\\Models\\SuperAdmin'" class="float-right font-italic">Super Admin</span>
        </span>
        <span v-else class="text-muted font-italic">Unknown User</span>
      </div>
      <div class="col-6 col-lg-2"><span v-if="fob.fob_type !== null">{{ fob.fob_type.name }}</span><span v-else class="text-muted font-italic">N/A</span></div>
      <div class="col-6 col-lg-2">{{ getDate(fob.created_at) }}</div>
      <div class="col-12 col-lg-3 font-weight-bold text-right">
        <span class="text-primary mr-3 d-none" :id="'unassignconfirm-'+fob.id" @click="unassignFob(fob.id)" style="cursor:pointer;">Confirm</span>
        <span class="text-danger" :id="'unassign-'+fob.id" @click="showUnassignConfirm(fob.id)" style="cursor:pointer;">Unassign</span>
        <span v-if="fob.user_type === 'App\\Models\\SuperAdmin'">
          <span class="text-primary d-none" :id="'portfolioconfirm-'+fob.id" @click="addToPortfolio(fob.id)" style="cursor:pointer;">Confirm</span>
          <span class="text-danger ml-3" :id="'portfolio-'+fob.id" @click="showPortfolioConfirm(fob.id)" style="cursor:pointer;">Move to Portfolio</span>
        </span>
      </div>
    </div>
    <div class="row fob-table-border" v-for="fob in filteredAvailable" :key="fob.id">
      <div class="col-6 col-lg-2">{{ fob.card_number }} <span class="float-right font-italic">{{ fob.facility_code }}</span></div>
      <div class="col-6 col-lg-3 text-muted font-italic">Unassigned</div>
      <div class="col-6 col-lg-2"><span v-if="fob.fob_type !== null">{{ fob.fob_type.name }}</span><span v-else class="text-muted">N/A</span></div>
      <div class="col-6 col-lg-2">{{ getDate(fob.created_at) }}</div>
      <div class="col-12 col-lg-3 font-weight-bold text-right">
        <span class="text-primary mr-3 d-none" :id="'portfolioconfirm-'+fob.id" @click="addToPortfolio(fob.id)" style="cursor:pointer;">Confirm</span>
        <span class="text-danger" :id="'portfolio-'+fob.id" @click="showPortfolioConfirm(fob.id)" style="cursor:pointer;">Move to Portfolio</span>
      </div>
    </div>
    <div class="row fob-table-border" v-for="fob in filteredAvailablePortfolio" :key="fob.id">
      <div class="col-6 col-lg-2">{{ fob.card_number }} <span class="float-right font-italic">{{ fob.facility_code }}</span></div>
      <div class="col-6 col-lg-3 text-muted font-italic">Unassigned</div>
      <div class="col-6 col-lg-2"><span v-if="fob.fob_type !== null">{{ fob.fob_type.name }}</span><span v-else class="text-muted">N/A</span></div>
      <div class="col-6 col-lg-2">{{ getDate(fob.created_at) }}</div>
      <div class="col-12 col-lg-3 font-weight-bold text-right">
        <span class="text-primary mr-3 d-none" :id="'portfolioconfirm-'+fob.id" @click="addToPortfolio(fob.id)" style="cursor:pointer;">Confirm</span>
        <span class="text-danger" :id="'portfolio-'+fob.id" @click="showPortfolioConfirm(fob.id)" style="cursor:pointer;">Move to Portfolio</span>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
  .iconBadge {
    background-color: #eeeeee;
    color: #4f4f4f;
    border-radius: 200px;
    width: 70px;
    height: 70px;
    padding-top: 21px;
    padding-left: 2px;
  }
</style>

<script>
import api from '../../../api.js';
import {numeric, required} from 'vuelidate/lib/validators';

function filteredFobs(fobType, search) {
  if (fobType === undefined) {
    return [];
  }
  const re = new RegExp(search, 'i');
  return fobType.filter(
    (fob) =>
      fob.card_number.toString().match(re)
  );
}

export default {
  name: 'BatchAssignFob',
  props: {
    building: Object,
    fob_types: Array,
  },
  data() {
    return {
      batch_keys: 0,
      disabled: false,
      buildingObject: this.building,
      loadingFobsContent: false,
      search: null,
      activeFobs: [],
      activePortfolioFobs: [],
      availableFobs: [],
      availablePortfolioFobs: [],
      fobsPaginationObject: {
        activeFobsStateObject: {
          activeFobsURI: null,
          activeFobsLoading: false,
        },
        activePortfolioFobsStateObject: {
          activePortfolioFobsURI: null,
          activePortfolioFobsLoading: false,
        },
        availableFobsStateObject: {
          availableFobsURI: null,
          availableFobsLoading: false,
        },
        availablePortfolioFobsStateObject: {
          availablePortfolioFobsURI: null,
          availablePortfolioFobsLoading: false,
        },
      },

      form: {
        start_number: '',
        end_number: '',
        facility_code: '',
        key_type: '',
      },
    };
  },
  validations: {
    form: {
      start_number: {
        numeric,
        required,
      },
      end_number: {
        numeric,
        required,
      },
      facility_code: {
        numeric,
        required,
      },
      key_type: {
        required,
      },
    },
  },
  computed: {
    searchEmptyString() {
      if (this.search === '') {
        return 'No keys assigned to this building.';
      }
      return 'No keys found.';
    },
    filteredAssigned() {
      return filteredFobs(this.activeFobs, this.search);
    },
    filteredAvailable() {
      return filteredFobs(this.availableFobs, this.search);
    },
    filteredAvailablePortfolio() {
      return filteredFobs(this.availablePortfolioFobs, this.search);
    },
    filteredAssignedPortfolio() {
      return filteredFobs(this.activePortfolioFobs, this.search);
    },
  },
  watch: {
    'search': function(newValue, oldValue) {
      if (this.buildingObject && this.buildingObject.id) {
        this.getFobs(this.buildingObject);
      }
    },
    'form.start_number': function(newValue, oldValue) {
      this.getBatchDiff();
    },
    'form.end_number': function(newValue, oldValue) {
      this.getBatchDiff();
    },
  },
  mounted() {
    const self = this;
    self.search = '';
  },
  methods: {
    hasNoFilteredFobs() {
      if (this.filteredAssigned.length === 0
        && this.filteredAssignedPortfolio.length === 0
        && this.filteredAvailable.length === 0
        && this.filteredAvailablePortfolio.length === 0
      ) {
        return true;
      }

      return false;
    },
    getDate(date) {
      return moment.utc(date).format('MMMM D, YYYY');
    },
    getBatchDiff() {
      let start = parseInt(this.form.start_number);
      let end = parseInt(this.form.end_number);
      if (isNaN(start) || isNaN(end) || start > end) {
        this.batch_keys = 0;
      } else {
        this.batch_keys = (end - start) + 1;
      }
    },
    getPaginatedActiveFobs(fobsPaginationObject, building) {
      const self = this;
      if (!fobsPaginationObject.activeFobsStateObject.activeFobsLoading && fobsPaginationObject.activeFobsStateObject.activeFobsURI) {
        fobsPaginationObject.activeFobsStateObject.activeFobsLoading = true;
        api.getPaginatedActiveFobs(this.search, building, fobsPaginationObject.activeFobsStateObject.activeFobsURI.split('?')[1])
          .then((response) => {
            if ($('.content').css('display') === 'none') {
              $('.content').css('display', 'block');
              $('.content').animateCss('fadeIn');
            }
            fobsPaginationObject.activeFobsStateObject.activeFobsURI = response.next_page_url;
            self.activeFobs = self.activeFobs.concat(response.data);
            fobsPaginationObject.activeFobsStateObject.activeFobsLoading = false;
          })
          .catch((error) => {
            fobsPaginationObject.activeFobsStateObject.activeFobsLoading = false;
            fobsPaginationObject.activeFobsStateObject.activeFobsURI = null;
          });
      }
    },
    getPaginatedActivePortfolioFobs(fobsPaginationObject, building) {
      const self = this;
      if (!fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsLoading && fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI) {
        fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsLoading = true;
        api.getPaginatedActivePortfolioFobs(this.search, building, fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI.split('?')[1])
          .then((response) => {
            if ($('.content').css('display') === 'none') {
              $('.content').css('display', 'block');
              $('.content').animateCss('fadeIn');
            }
            fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI = response.next_page_url;
            self.activePortfolioFobs = self.activePortfolioFobs.concat(response.data);
            fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsLoading = false;
          })
          .catch((error) => {
            fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsLoading = false;
            fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI = null;
          });
      }
    },
    getPaginatedAvailableFobs(fobsPaginationObject, building) {
      const self = this;
      if (!fobsPaginationObject.availableFobsStateObject.availableFobsLoading && fobsPaginationObject.availableFobsStateObject.availableFobsURI) {
        fobsPaginationObject.availableFobsStateObject.availableFobsLoading = true;
        api.getPaginatedAvailableFobs(this.search, building, fobsPaginationObject.availableFobsStateObject.availableFobsURI.split('?')[1])
          .then((response) => {
            if ($('.content').css('display') === 'none') {
              $('.content').css('display', 'block');
              $('.content').animateCss('fadeIn');
            }
            fobsPaginationObject.availableFobsStateObject.availableFobsURI = response.next_page_url;
            self.availableFobs = self.availableFobs.concat(response.data);
            fobsPaginationObject.availableFobsStateObject.availableFobsLoading = false;
          })
          .catch((error) => {
            fobsPaginationObject.availableFobsStateObject.availableFobsLoading = false;
            fobsPaginationObject.availableFobsStateObject.availableFobsURI = null;
          });
      }
    },
    getPaginatedAvailablePortfolioFobs(fobsPaginationObject, building) {
      const self = this;
      if (!fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsLoading && fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI) {
        fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsLoading = true;
        api.getPaginatedAvailablePortfolioFobs(this.search, building, fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI.split('?')[1])
          .then((response) => {
            if ($('.content').css('display') === 'none') {
              $('.content').css('display', 'block');
              $('.content').animateCss('fadeIn');
            }
            fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI = response.next_page_url;
            self.availablePortfolioFobs = self.availablePortfolioFobs.concat(response.data);
            fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsLoading = false;
          })
          .catch((error) => {
            fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsLoading = false;
            fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI = null;
          });
      }
    },
    onScroll({ target }) {
      let bottomOfWindow = target.scrollTop >= target.offsetHeight;

      if (bottomOfWindow && building && fobsPaginationObject) {
        this.getPaginatedActiveFobs(fobsPaginationObject, building)
        this.getPaginatedActivePortfolioFobs(fobsPaginationObject, building)
        this.getPaginatedAvailableFobs(fobsPaginationObject, building)
        this.getPaginatedAvailablePortfolioFobs(fobsPaginationObject, building)
      }
    },
    splicePaginationUrl(url) {
      return str.split('?')[1];
    },
    async getFobs(building) {
      this.loadingFobsContent = true;

      if(building){
        const activeFobsResp = await api.getActiveFobs(this.search, building)
        if ($('.content').css('display') === 'none') {
          $('.content').css('display', 'block');
          $('.content').animateCss('fadeIn');
        }
        this.fobsPaginationObject.activeFobsStateObject.activeFobsURI = activeFobsResp.next_page_url;
        this.activeFobs = activeFobsResp.data;

        const activePortFobsResp = await api.getActivePortfolioFobs(this.search, building.portfolio_id, building.id)
        if ($('.content').css('display') === 'none') {
          $('.content').css('display', 'block');
          $('.content').animateCss('fadeIn');
        }
        this.fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI = activePortFobsResp.next_page_url;
        this.activePortfolioFobs = activePortFobsResp.data;

        const availableFobsResp = await api.getAvailableFobs(this.search, building)
        if ($('.content').css('display') === 'none') {
          $('.content').css('display', 'block');
          $('.content').animateCss('fadeIn');
        }
        this.fobsPaginationObject.availablePortfolioFobsStateObject.availableFobsURI = availableFobsResp.next_page_url;
        this.availableFobs = availableFobsResp.data;

        const availablePortFobsResp = await api.getAvailablePortfolioFobs(this.search, building.portfolio_id, building.id)
        if ($('.content').css('display') === 'none') {
          $('.content').css('display', 'block');
          $('.content').animateCss('fadeIn');
        }
        this.fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI = availablePortFobsResp.next_page_url;
        this.availablePortfolioFobs = availablePortFobsResp.data;
      }

      this.loadingFobsContent = false;
    },
    showPortfolioConfirm(fobId) {
      const portfolio = $('#portfolio-' + fobId);
      const portfolioConfirm = $('#portfolioconfirm-' + fobId);
      const unassign = $('#unassign-' + fobId);
      const unassignConfirm = $('#unassignconfirm-' + fobId);

      if (portfolio.text() === 'Move to Portfolio') {
        portfolio.text('Cancel');
        portfolioConfirm.removeClass('d-none');
        unassign.addClass('d-none');
        unassignConfirm.addClass('d-none');
      } else {
        portfolio.text('Move to Portfolio');
        portfolioConfirm.addClass('d-none');
        unassign.removeClass('d-none');
        unassignConfirm.removeClass('d-none');
      }
    },
    addToPortfolio(fobId) {
      const self = this;
      if (self.disabled) {
        return;
      }
      self.disabled = true;

      api.deleteFobBuilding(fobId)
        .then((response) => {
          self.handleSuccess('Success - fob was removed from all buildings.');

          let assignedIndex = self.activeFobs.findIndex((x) => x.id === fobId);
          if (assignedIndex !== -1) {
            self.activeFobs.splice(assignedIndex, 1);
          }

          let assignedPortfolioIndex = self.activePortfolioFobs.findIndex((x) => x.id === fobId);
          if (assignedPortfolioIndex !== -1) {
            self.activePortfolioFobs.splice(assignedPortfolioIndex, 1);
          }

          let availableIndex = self.availableFobs.findIndex((x) => x.id === fobId);
          if (availableIndex !== -1) {
            self.availableFobs.splice(availableIndex, 1);
          }

          let availablePortfolioIndex = self.availablePortfolioFobs.findIndex((x) => x.id === fobId);
          if (availablePortfolioIndex !== -1) {
            self.availablePortfolioFobs.splice(availablePortfolioIndex, 1);
          }

          self.disabled = false;
        })
        .catch((error) => {
          self.handleError(error);
          self.disabled = false;
        });
    },
    showUnassignConfirm(fobId) {
      const unassign = $('#unassign-' + fobId);
      const unassignConfirm = $('#unassignconfirm-' + fobId);
      const portfolio = $('#portfolio-' + fobId);
      const portfolioConfirm = $('#portfolioconfirm-' + fobId);

      if (unassign.text() === 'Unassign') {
        unassign.text('Cancel');
        unassignConfirm.removeClass('d-none');
        portfolio.addClass('d-none');
        portfolioConfirm.addClass('d-none');
      } else {
        unassign.text('Unassign');
        unassignConfirm.addClass('d-none');
        portfolio.removeClass('d-none');
        portfolioConfirm.removeClass('d-none');
      }
    },
    unassignFob(fobId) {
      const self = this;
      if (self.disabled) {
        return;
      }
      self.disabled = true;

      api.deleteUnassignFob(fobId)
        .then((response) => {
          self.handleSuccess('Success - fob was unassigned.');
          self.getFobs(self.buildingObject);
          self.disabled = false;
        })
        .catch((error) => {
          self.handleError(error);
          self.disabled = false;
        });
    },
    assignPortfolioKeys() {
      const self = this;
      if (self.disabled || self.form.start_number > self.form.end_number) {
        return;
      }
      self.disabled = true;

      api.putAssignFobsToBuilding(self.form.start_number, self.form.end_number, self.form.facility_code, self.form.key_type, self.building.id, self.$route.params.id)
        .then((response) => {
          self.handleSuccess('Success - batch assignment complete.');
          self.getFobs(self.buildingObject);
          self.disabled = false;
          self.form.start_number = '';
          self.form.end_number = '';
          self.form.facility_code = '';
          self.form.key_type = '';
          this.$v.$reset();
        })
        .catch((error) => {
          self.handleError(error);
          self.disabled = false;
        });
    },
  },
  async created() {
    if (this.building == null) {
      return;
    }
    await this.getFobs(this.building);
  },
};
</script>
