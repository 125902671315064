<template>
<div>
  <div class="row">
    <div class="col-12 col-md-4">
      <span v-if="allegion === undefined">
        <h4>Allegion Account Not Connected</h4>
      </span>
      <span v-else>
        <h4 class="d-block mb-0">Allegion Account Connected</h4>
        <small>{{ allegion.external_account.username }}</small>
      </span>
    </div>
    <div class="col-12 col-md-4">
      <span v-if="brivo === undefined">
        <h4>Brivo Account Not Connected</h4>
      </span>
      <span v-else>
        <h4>Brivo Account Connected</h4>
        <small>{{ brivo.account.account_name }}</small>
      </span>
    </div>
    <div class="col-12 col-md-4">

    </div>
  </div>
  <h4 class="mt-4">Portfolio Keys</h4>
  <ModalWrapper
    :modalId="modalIdPortfolioKeys"
    :hidden="true"
    size="xlarge"
  >
    <template
      v-if="modalContentData.title != null"
      v-slot:modal-header
    >
      {{ modalContentData.title }}
    </template>
    <PortfolioKeysAssignContent
      v-if="modalContentData.type === 'AddEdit'"
      :fob_types="fob_types"
      :portfolio="modalContentData.portfolio"
      :modalType="modalContentData.type"
      @updated="$emit('updated')"
    ></PortfolioKeysAssignContent>
    <PortfolioKeysDeleteContent
        v-else-if="modalContentData.type === 'Delete'"
        :fob_types="fob_types"
        :portfolio="modalContentData.portfolio"
        :modalType="modalContentData.type"
        @updated="$emit('updated')"
    />
    <PortfolioKeysBuildingUnassignContent
        v-else-if="modalContentData.type === 'Unassign'"
        :fob_types="fob_types"
        :portfolio="modalContentData.portfolio"
        :modalType="modalContentData.type"
        @updated="$emit('updated')"
    />
  </ModalWrapper>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-3">
              {{ portfolio.business_name }}
            </div>
            <div class="col-12 col-md-2">
            </div>
            <div class="col-12 col-md-2">
            </div>
            <div class="col-12 col-md-2">
            </div>
            <div class="col-12 col-md-3 text-right">
              <DropdownMenu
                label="Actions"
                :disabled="false"
                :menuItems="portfolioMenuItems"
                @trigger-action-portfolio-delete="openModalBatchDeleteForPortfolioKeys"
                @trigger-action-portfolio-add-edit="openModalAddEditForPortfolioKeys"
                @trigger-action-portfolio-building-unassign="openModalBuildingUnassignForPortfolioKeys"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h4 class="mt-4">Building Keys</h4>
  <div class="row">

    <!-- @updated is from modal -->
    <BuildingKeysListItem
      v-for="building in portfolio.buildings"
      :key="building.id"
      :building="building"
      @update-building-keys-add-edit="updateBuildingKeysAddEdit"
      @update-building-keys-delete="updateBuildingKeysDelete"
      @updated="$emit('updated')"
    />

  </div>

  <ModalWrapper
    :modalId="modalIdBuildingKeys"
    :hidden="modalHiddenAddEdit"
    size="xlarge"
  >
    <template
      v-if="modalContentData.title != null"
      v-slot:modal-header
    >
      {{ modalContentData.title }}
    </template>
    <BatchAssignFob
      v-if="showModalAddEditContent()"
      :building="modalContentData.building"
      :fob_types="fob_types"
    />
    <BatchDeleteFob
      v-else-if="showModalDeleteContent()"
      :building="modalContentData.building"
      :fob_types="fob_types"
    />
  </ModalWrapper>

  <ModalWrapper
    modalId="confirm"
    :hidden="false"
  >
    <template
        v-slot:modal-header
    >
      Remove Key
    </template>
    <ConfirmFob
      :building="modalContent().building"
      :fob_types="fob_types"
    />
  </ModalWrapper>

  <ModalWrapper
      modalId="unassignConfirm"
      :hidden="false"
  >
    <template
        v-slot:modal-header
    >
      Unassign Key
    </template>
    <ConfirmUnassignFob
        :building="modalContent().building"
        :fob_types="fob_types"
    />
  </ModalWrapper>
</div>
</template>
<style scoped lang="scss">
.confirm-header{
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}
</style>

<script>
  import BuildingKeysListItem from './partials/BuildingKeysListItem.vue';
  import ModalWrapper from './partials/ModalWrapper.vue';
  import BuildingFobModal from '../components/modals/BuildingFobModal.vue';
  import Dropdown from '../components/elements/Dropdown.vue';
  import BatchAssignFob from '../components/modals/subModals/BatchAssignFob';
  import BatchDeleteFob from '../components/modals/subModals/BatchDeleteFob';
  import DropdownMenu from '../components/elements/DropdownMenu.vue';
  import PortfolioKeysAssignContent from './modals/subModals/PortfolioKeysAssignContent.vue';
  import ConfirmFob from '../components/modals/subModals/ConfirmFob';
  import api from '../api.js';
  import PortfolioKeysDeleteContent from "./modals/subModals/PortfolioKeysDeleteContent";
  import PortfolioKeysBuildingUnassignContent from "./modals/subModals/PortfolioKeysBuildingUnassignContent";
  import ConfirmUnassignFob from "./modals/subModals/ConfirmUnassignFob";

  export default {
    components: {
      ConfirmUnassignFob,
      PortfolioKeysBuildingUnassignContent,
      ConfirmFob,
      BuildingKeysListItem,
      ModalWrapper,
      BuildingFobModal,
      BatchAssignFob,
      BatchDeleteFob,
      Dropdown,
      DropdownMenu,
      PortfolioKeysAssignContent,
      PortfolioKeysDeleteContent
    },
    props: {
      // allegion: Object,
      // brivo: Object,
      fob_types: Array,
      portfolio: Object
    },
    data() {
      return {
        disabled: false,
        allegionObject: undefined,
        brivoObject: undefined,
        modalIdBuildingKeys: 'buildingKeysModal',
        modalContentData: {},
        modalIdPortfolioKeys: 'portfolioKeysModal',
        portfolioMenuItems: [
          {
            key: 'add-edit',
            label: 'Add/Edit',
            icon: 'plus-circle',
            event: {
              name: 'trigger-action-portfolio-add-edit',
              data: {
                name: 'portfolio-keys-add-edit',
              },
            },
          },
          {
            key: 'batch-unassign',
            label: 'Batch unassign',
            icon: 'minus-circle',
            event: {
              name: 'trigger-action-portfolio-building-unassign',
              data: {
                name: 'portfolio-keys-building-unassign',
              },
            },
          },
          {
            key: 'batch-delete',
            label: 'Batch delete',
            icon: 'trash-alt',
            event: {
              name: 'trigger-action-portfolio-delete',
              data: {
                name: 'portfolio-keys-delete',
              },
            },
          },
        ],
      };
    },
    computed: {
      brivo() {
        if (this.brivoObject) {
          return this.brivoObject;
        }
        return undefined;
      },
      allegion() {
        if (this.allegionObject) {
          return this.allegionObject;
        }
        return undefined;
      },
      modalContentPortfolio() {
        return this.modalContentFor(this.modalIdPortfolioKeys);
      },
      modalContentBuilding() {
        return this.modalContentFor(this.modalIdBuildingKeys);
      },
    },
    beforeMount() {
      const self = this;
      self.getBrivoUsers();
      self.getAllegionUsers();
    },
    methods: {
      openConfirm() {
        return this.$store.dispatch('modal/open', {modalId: "confirm"});
      },
      openUnassignConfirm() {
        return this.$store.dispatch('modal/open', {modalId: "unassignConfirm"});
      },
      modalContentFor(modalId) {
        const modalContentObj = this.$store.getters['modal/contentById'](modalId);
      },
      modalContent() {
        const modalContentObj = this.$store.getters['modal/contentById'](this.modalIdBuildingKeys);

        if (modalContentObj != null) {
          return modalContentObj;
        }

        return {};
      },
      showModalAddEditContent() {
        return this.modalContentData.type === 'AddEdit' && this.modalContentData.building != null;
      },
      showModalDeleteContent() {
        return this.modalContentData.type === 'Delete' && this.modalContentData.building != null;
      },
      openModalBatchDeleteForPortfolioKeys() {
        const dispatchPayload = {
          modalId: this.modalIdPortfolioKeys,
          contentData: {
            title: 'Portfolio Keys - Batch delete',
            type: 'Delete',
            portfolio: this.portfolio,
          },
        };
        this.modalContentData = dispatchPayload.contentData;
        this.$store.dispatch(
          'modal/open',
          dispatchPayload,
        );
      },
      openModalAddEditForPortfolioKeys() {
        const dispatchPayload = {
          modalId: this.modalIdPortfolioKeys,
          contentData: {
            title: 'Portfolio Keys - Add/Edit',
            type: 'AddEdit',
            portfolio: this.portfolio,
          },
        };
        this.modalContentData = dispatchPayload.contentData;
        this.$store.dispatch(
          'modal/open',
          dispatchPayload,
        );
      },
      openModalBuildingUnassignForPortfolioKeys() {
        const dispatchPayload = {
          modalId: this.modalIdPortfolioKeys,
          contentData: {
            title: 'Portfolio Keys - Batch Unassign',
            type: 'Unassign',
            portfolio: this.portfolio,
          },
        };
        this.modalContentData = dispatchPayload.contentData;
        this.$store.dispatch(
            'modal/open',
            dispatchPayload,
        );
      },
      getBrivoUsers() {
        const self = this;
        api.getBrivoUsers(self.$route.params.id)
          .then((response) => {
            self.brivoObject = response.brivo;
            $('.content').css('display', 'block');
            $('.content').animateCss('fadeIn');
          })
          .catch((error) => {
            self.handleError(error);
          });
      },
      getAllegionUsers() {
        const self = this;
        api.getAllegionUsers(self.$route.params.id)
          .then((response) => {
            self.allegionObject = response.allegion;
            $('.content').css('display', 'block');
            $('.content').animateCss('fadeIn');
          })
          .catch((error) => {
            self.handleError(error);
          });
      },
      updateBuildingKeysAddEdit(eventData) {
        const { building } = eventData;
        const dispatchPayload = {
          modalId: this.modalIdBuildingKeys,
          contentData: {
            title: 'Building Keys - Assign From Portfolio',
            type: 'AddEdit',
            building,
          },
        };
        this.modalContentData = dispatchPayload.contentData;
        this.$store.dispatch(
          'modal/open',
          dispatchPayload,
        );
      },
      updateBuildingKeysDelete(eventData) {
        const { building } = eventData;
        const dispatchPayload = {
          modalId: this.modalIdBuildingKeys,
          contentData: {
            title: 'Building Keys - Delete From Portfolio',
            type: 'Delete',
            building,
          },
        };
        this.modalContentData = dispatchPayload.contentData;
        this.$store.dispatch(
          'modal/open',
          dispatchPayload,
        );
      },
    },
  };
</script>
