<template>
  <div class="col-12 col-md-3 text-right">
    <DropdownMenu
      :disabled="disabled"
      :menuItems="menuItems"
      label="Actions"
      @add-unit="addUnits"
      @download-existing="downloadExisting"
      @download-template="downloadTemplate"
    />
  </div>
</template>

<script>
import DropdownMenu from '../elements/DropdownMenu.vue';

export default {
  name: 'UnitsListItem',

  components: {
    DropdownMenu,
  },

  props: {
    building: Object,
  },

  data() {
    return {
      actionsDisabled: false,
      disabled: false,
      modalHiddenAddEdit: true,
      csvUrl: '',
      templateUrl: '',
      menuItems: [
        {
          key: 'manually-add-units',
          label: 'Manually add units',
          icon: 'plus-circle',
          event: {
            name: 'add-unit',
            data: {
              name: 'addUnit',
            },
          },
        },
        {
          key: 'download-existing-unit-list',
          label: 'Download existing unit list',
          icon: 'download',
          event: {
            name: 'download-existing',
            data: {
              name: this.csvUrl,
            },
          },
        },
        {
          key: 'download-blank-template',
          label: 'Download blank csv template',
          icon: 'download',
          event: {
            name: 'download-template',
            data: {
              name: this.templateUrl,
            },
          },
        },
      ],
    };
  },

  methods: {
    addUnits(eventData) {
      this.$emit('add-unit', {building: this.building});
    },
    downloadTemplate(eventData) {
      this.$emit('download-template', {building: this.building});
    },
    downloadExisting(eventData) {
      this.$emit('download-existing', {building: this.building});
    },
  },
};
</script>
