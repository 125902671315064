<template>
  <div class="container content">
    <div class="row justify-content-center">
      <div class="col">
        <h4>{{ building.building_name }}</h4>
        <p
          v-if="building.portfolio !== null"
          class="subtitle"
        >
          <router-link
            :to="{name: 'portfolio', params: {'id': this.$route.params.id}}"
          >
            {{ building.portfolio.business_name }}
          </router-link>
        </p>
        <p
          v-else
          class="d-block"
          style="margin-bottom: 39px;"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-auto">
        <Navigation />
      </div>
      <div class="col">
        <form class="building-form">
          <h4 class="font-weight-normal">Setup Homebase Plan Features</h4>
          <BuildingFeature
            v-for="feature in features"
            :key="feature.id"
            :feature="feature"
          />
        </form>
      </div>
    </div>
    <footer class="building-footer">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col col-md-6 col-lg-3">
            <button
              :disabled="disabled"
              class="btn btn-primary btn-block"
              @click="saveChanges"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
    import {mapGetters} from 'vuex';
    import Navigation from '../../../components/BuildingNav.vue';
    import BuildingFeature from '../../../components/BuildingFeature.vue';

    export default {
         components: {
            Navigation,
            BuildingFeature,
        },
        computed: {
            ...mapGetters({
                building: 'plan/building',
                features: 'plan/features',
                disabled: 'plan/disabled',
            }),
        },
        beforeMount() {
            $('.loading').css('display', 'block');
            if (this.$route.params.building_id === 'undefined') {
                this.$router.go(-1);
            }
        },
        mounted() {
            const self = this;
            const buildingId = self.$route.params.building_id;
            self.$store.dispatch('plan/fetch', {buildingId})
                .then(()=> {
                    if ($('.content').css('display') === 'none') {
                        $('.content').css('display', 'block');
                        $('.content').animateCss('fadeIn');
                    }
                })
                .catch((error) => {
                    self.handleError(error);
                });
        },
        methods: {
            saveChanges() {
                const self = this;
                if (self.disabled) return;
                self.$store.dispatch('plan/save')
                    .then(() => {
                        self.$router.push({
                            name: 'buildingconfiguration',
                            params: {
                                'id': self.$route.params.id,
                                'building_id': self.$route.params.building_id,
                            },
                        });
                    })
                    .catch((error)=>{
                        self.handleError(error);
                    });
            },
        },
    };
</script>
