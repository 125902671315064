<template>
  <div class="row fob-table-border">
    <div class="col-6 col-lg-1 font-weight-bold">{{ fob.card_number }}</div>
    <div class="col-6 col-lg-1 font-weight-bold">{{ fob.facility_code }}</div>
    <div class="col-6 col-lg-1 font-weight-bold">{{ fob.bits }}</div>
    <div class="col-6 col-lg-2">

      <span v-if="fob.user_full_name === 'Unassigned'" class="unassigned-full-name">{{ fob.user_full_name }}</span>
      <span v-else>{{ fob.user_full_name }}</span>
    </div>
    <div class="col-6 col-lg-1">
      <span v-if="fob.user_type === 'App\\Models\\Tenant'">Tenant</span>
      <span v-else-if="fob.user_type === 'App\\Models\\Admin'">Admin</span>
      <span v-else-if="fob.user_type === 'App\\Models\\SuperAdmin'">Super Admin</span>
      <span v-else>-</span>
    </div>
    <div class="col-6 col-lg-2">
      <p v-for="building in fob.buildings">{{ building.building_name }}</p>
    </div>
    <div class="col-6 col-lg-2">{{ formatDate(fob.created_at) }}</div>
    <div class="col-6 col-lg-2">
      <DropdownMenu
        label="Actions"
        :disabled="false"
        :menuItems="fobMenuItems"
        @trigger-action-unassign="triggerActionUnassign"
        @trigger-action-delete="triggerActionDelete"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.unassigned-full-name {
  color: #999999
}
</style>
<script>
import ModalWrapper from './ModalWrapper.vue';
import DropdownMenu from '../elements/DropdownMenu.vue';
import api from "../../api";
export default {
  name: 'FobListItem',
  props: {
    fob: Object,
  },
  components: {
    ModalWrapper,
    DropdownMenu,
  },
  methods: {
    formatDate(date) {
      return moment.utc(date).format('MMMM D, YYYY');
    },

    async triggerActionDelete() {
      const msg = 'Are you sure? This key will be permanently removed from the Homebase system (*will quit working upon next sync)';
      if (confirm(msg)) {
        api.deleteFob(this.fob.id)
          .then(() => {
            this.emitRefresh()
          });
      }
    },

    async triggerActionUnassign() {

      const msg = 'Are you sure you want to unassign this fob?';
      if (confirm(msg)) {

        api.deleteUnassignFob(this.fob.id)
            .then(() => {
              this.emitRefresh()
            })
      }
    },

    emitRefresh() {
      this.$emit('trigger-reload');
    }
  },
  data() {
    return {
      fobMenuItems: [
        {
          key: 'fob-unassign',
          label: 'Unassign',
          event: {
            name: 'trigger-action-unassign',
            data: {
              name: 'trigger-action-unassign',
            },
          },
        },
        {
          key: 'fob-delete',
          label: 'Delete',
          color: 'red',
          event: {
            name: 'trigger-action-delete',
            data: {
              name: 'trigger-action-delete',
            },
          },
        },
      ],
    }
  }
}
</script>