<template>
  <div>
    <div v-if="allegion == null">
      <h4>Connect Allegion Account</h4>
      <form @submit.prevent="postPortfolioExternalAccount">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="username">Username</label>
              <input :class="{'is-invalid':$v.form.username.$anyError}" class="form-control" id="username" type="text" v-model.trim="$v.form.username.$model">
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="password">Password</label>
              <input :class="{'is-invalid':$v.form.password.$anyError}" class="form-control" id="password" type="password" v-model.trim="$v.form.password.$model">
            </div>
          </div>
          <div class="col-12 col-md-4" style="margin-top: 1.6rem;">
            <button :disabled="disabled || $v.$invalid" class="btn btn-primary btn-block">Save</button>
          </div>
        </div>
      </form>
    </div>
    <div v-if="allegion != null">
      <div class="row">
        <div class="col-auto">
          <h4 class="mb-0">Allegion Account Connected</h4>
          <p style="font-size: 24px;">{{ allegion.external_account.username }}</p>
        </div>
        <div class="col-12 col-md-5 ml-auto">
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-search fa-fw"></i></span>
            </div>
            <input class="form-control" placeholder="Search" type="text" v-model.trim="search" />
          </div>
        </div>
      </div>
      <div class="row">
        <AllegionUser :key="user.id" :user="user" v-for="user in filteredList" />
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../api.js';
    import AllegionUser from './AllegionUser.vue';
    import {maxLength, required} from 'vuelidate/lib/validators';

    export default {
        components: {
            AllegionUser,
        },
        props: {
            // allegion: Object,
            portfolio: Object,
        },
        data() {
            return {
                disabled: false,
                form: {
                    username: '',
                    password: '',
                },
                search: '',
                allegionObject: undefined,
            };
        },
        validations: {
            form: {
                username: {
                    maxLength: maxLength(255),
                    required,
                },
                password: {
                    maxLength: maxLength(255),
                    required,
                },
            },
        },
        computed: {
            allegion() {
              if (this.allegionObject) {
                return this.allegionObject;
              }
              return undefined;
            },
            filteredList() {
                if (this.allegion.application.allegion_users == null) return [];
                const re = new RegExp(this.search, 'i');
                return this.allegion.application.allegion_users.filter(
                    (user) =>
                        user.actorable.first_name.match(re) ||
                        user.actorable.last_name.match(re) ||
                        user.actorable.email.match(re)
                );
            },
        },
        watch: {
            allegion: {
                immediate: true,
                handler(newValue, oldValue) {
                    if (this.allegion !== undefined && this.allegion !== null && this.allegion.application.application_users !== undefined) {
                        this.allegion.application.allegion_users.sort((a, b) => (a.actorable_type > b.actorable_type) ? 1 : -1);
                    }
                },
            },
        },
        methods: {
          getAllegionUsers() {
            const self = this;
            api.getAllegionUsers(self.$route.params.id)
                .then((response) => {
                    self.allegionObject = response.allegion;
                    $('.content').css('display', 'block');
                    $('.content').animateCss('fadeIn');
                })
                .catch((error) => {
                    self.handleError(error);
                });
            },
            postPortfolioExternalAccount() {
                const self = this;
                if (self.disabled || self.$v.$invalid) return;
                self.disabled = true;

                api.postPortfolioAllegionAccount(
                    this.portfolio,
                    this.form.username,
                    this.form.password
                )
                    .then((response) => {
                        self.handleSuccess('Allegion successfully configured');
                    })
                    .catch((error) => {
                        self.handleError('Unable to configure Allegion at this time.');
                        self.disabled = false;
                    });
            },
        },
        beforeMount() {
            const self = this;
            self.getAllegionUsers();
        },
    };
</script>
