<template>
  <div
    aria-hidden="true"
    aria-labelledby="connectArubaModalLabel"
    class="modal fade"
    id="connectArubaModal"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="connectArubaModalLabel">
            {{ aruba_user ? "Manage" : "Connect" }} Aruba integration
          </h5>
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true"
              ><i class="fal fa-times-circle fa-fw"></i
            ></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <div class="col-auto mt-3 mb-3 text-center">
              <div
                class="
                  aruba-logo
                  d-flex
                  align-items-center
                  justify-content-center
                "
                @click="resetForm"
              >
                <img :src="arubaLogo" alt="Aruba logo" />
                <div v-if="aruba_user" class="floater floater-connected">
                  <i class="fas fa-check"></i>
                </div>
                <div v-else class="floater floater-connect">
                  <i class="fas fa-exchange"></i>
                </div>
              </div>
            </div>
          </div>
          <p v-if="aruba_user" class="text-center text-muted">
            Connected on {{ getConnectedAt }}
          </p>
          <form @submit.prevent="postUser">
            <div class="form-group">
              <label for="username">Email/Username</label>
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': $v.form.username.$anyError }"
                id="username"
                v-model.trim="$v.form.username.$model"
              />
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input
                type="password"
                class="form-control"
                :class="{ 'is-invalid': $v.form.password.$anyError }"
                id="password"
                v-model.trim="$v.form.password.$model"
              />
            </div>
            <div class="form-group">
              <label for="customer-id">Customer ID</label>
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': $v.form.customer_id.$anyError }"
                id="customer-id"
                v-model.trim="$v.form.customer_id.$model"
              />
            </div>
            <div class="form-group">
              <label for="client-id">Client ID</label>
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': $v.form.client_id.$anyError }"
                id="client-id"
                v-model.trim="$v.form.client_id.$model"
              />
            </div>
            <div class="form-group">
              <label for="client-secret">Client secret</label>
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': $v.form.client_secret.$anyError }"
                id="client-secret"
                v-model.trim="$v.form.client_secret.$model"
              />
            </div>
            <button class="d-none" type="submit"></button>
          </form>
        </div>
        <div class="modal-footer">
          <button
            :disabled="disabled || $v.$invalid"
            @click="postUser"
            class="btn btn-primary"
            type="button"
          >
            Connect
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.aruba-logo {
  border: 1.5px solid #dddddd;
  border-radius: 50%;
  width: 200px;
  height: 200px;
}

.floater {
  position: absolute;
  top: 0;
  right: -15px;
  color: #ffffff;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 30px;
}

.floater-connect {
  background: #00465f;
}

.floater-connected {
  background: #33c799;
}
</style>

<script>
import api from "../../api.js";
import { maxLength, required } from "vuelidate/lib/validators";

export default {
  name: "ConnectArubaModal",
  props: {
    aruba_user: Object,
  },
  data() {
    return {
      disabled: false,
      form: {
        username: "",
        password: "",
        customer_id: "",
        client_id: "",
        client_secret: "",
      },
      arubaLogo: "/img/integrations/aruba-logo.png",
    };
  },
  validations: {
    form: {
      username: {
        maxLength: maxLength(255),
        required,
      },
      password: {
        maxLength: maxLength(255),
        required,
      },
      customer_id: {
        maxLength: maxLength(255),
        required,
      },
      client_id: {
        maxLength: maxLength(255),
        required,
      },
      client_secret: {
        maxLength: maxLength(255),
        required,
      },
    },
  },
  methods: {
    resetForm() {
      this.$v.$reset();
      Object.assign(this.$data, this.$options.data.call(this));
    },
    postUser() {
      const self = this;
      if (self.disabled) return;
      self.disabled = true;

      api
        .postConnectArubaAccount(self.$route.params.building_id, self.form)
        .then((response) => {
          self.disabled = false;
          $("#connectArubaModal").modal("hide");
          self.$emit("updated");
          self.resetForm();
        })
        .catch((error) => {
          self.disabled = false;
          self.handleError(error);
        });
    },
  },
  computed: {
    getConnectedAt() {
      return moment.utc(this.aruba_user.created_at).format('MMM DD, YYYY [at] h:mm A');
    },
  },
};
</script>
