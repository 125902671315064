import axios from 'axios';
import store from './store';

export default {
    /**
     * Authentication
     */
    postLogin(form) {
        return axios.post('/chell/login', form)
            .then((response) => {
                return response.data;
            });
    },
    getResetLink(link) {
        return axios.get('/chell/reset/link', {
            params: {'reset_unique': link},
        }).then((response) => {
            return response.data;
        });
    },
    putResetPassword(form) {
        return axios.put('/chell/reset', form)
            .then((response) => {
                return response.data;
            });
    },

    /**
     * Admin
     */
    postAdmin(admin, buildingId) {
        return axios.post(
            '/account/building/' + buildingId + '/admin',
            {
                'homebase_admin_id': store.getters.user.id,
                'first_name': admin.first_name,
                'last_name': admin.last_name,
                'email': admin.email,
                'phone_number': admin.phone_number,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    postInstallerAdmin(admin, buildingId, portfolioId) {
        return axios.post(
            '/admin/installer',
            {
                'homebase_admin_id': store.getters.user.id,
                'admin_id': store.getters.user.id,
                'first_name': admin.first_name,
                'last_name': admin.last_name,
                'email': admin.email,
                'phone_number': admin.phone_number,
                'building': buildingId,
                'portfolio_id': portfolioId,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    getAvailableAdmins(buildingId) {
        return axios.get(
            '/account/building/' + buildingId + '/nonadmins',
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {'homebase_admin_id': store.getters.user.id},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    putAddAdmin(adminId, buildingId) {
        return axios.put(
            '/account/admin/' + adminId + '/building/' + buildingId,
            {'homebase_admin_id': store.getters.user.id},
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putAdmin(admin) {
        return axios.put(
            '/account/admin/' + admin.id,
            {
                'homebase_admin_id': store.getters.user.id,
                'first_name': admin.first_name,
                'last_name': admin.last_name,
                'phone_number': admin.phone_number,
                'job_title': admin.job_title,
                'first': admin.first,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    deleteRemoveAdmin(admin, buildingId) {
        return axios.delete(
            '/account/building/' + buildingId + '/admin/' + admin.id,
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {'homebase_admin_id': store.getters.user.id},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getExportHomebaseCustomers() {
        return axios.get(
            `/export/homebase-customers`,
            {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
                params: {'homebase_admin_id': store.getters.user.id},
                responseType: 'blob',
            });
    },
    /**
     * Brivo
     */
    postBrivoGroup(building_id, name, group_type) {
        return axios.post(
            '/brivo/group',
            {
                'homebase_admin_id': store.getters.user.id,
                'building_id': building_id,
                'name': name,
                'group_type': group_type,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    postConnectBrivoAccount(portfolio_id, form) {
        return axios.post(
            '/brivo/setup',
            {
                'homebase_admin_id': store.getters.user.id,
                'portfolio_id': portfolio_id,
                'account_name': form.account_name,
                'account_number': form.account_number,
                'client_id': form.client_id,
                'client_secret': form.client_secret,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putAssignGroupPermission(group_id, device_id, schedule_id) {
        return axios.put(
            'brivo/group/' + group_id + '/permission',
            {
                'homebase_admin_id': store.getters.user.id,
                'device_id': device_id,
                'schedule_id': schedule_id,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putSiteToBuilding(site_id, building_id) {
        return axios.put(
            '/brivo/site/' + site_id + '/building/' + building_id,
            {'homebase_admin_id': store.getters.user.id},
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putSyncBrivoGroups(portfolio_id) {
        return axios.put(
            '/brivo/groups',
            {
                'homebase_admin_id': store.getters.user.id,
                'portfolio_id': portfolio_id,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putRefreshBrivo(portfolio_id) {
        return axios.put(
            '/brivo/refresh/chell',
            {
                'homebase_admin_id': store.getters.user.id,
                'portfolio_id': portfolio_id,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putRenameGroup(group_id, name) {
        return axios.put(
            '/brivo/group/' + group_id,
            {
                'homebase_admin_id': store.getters.user.id,
                'name': name,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putSuspendUser(reason, user_id, user_type) {
        return axios.put(
            '/brivo/suspend/chell',
            {
                'homebase_admin_id': store.getters.user.id,
                'reason': reason,
                'user_id': user_id,
                'user_type': user_type,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putEnableOnAir(tenant_id) {
        return axios.put(
            'brivo/tenant/' + tenant_id + '/enable',
            {'homebase_admin_id': store.getters.user.id},
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putDisableOnAir(tenant_id) {
        return axios.put(
            'brivo/tenant/' + tenant_id + '/disable',
            {'homebase_admin_id': store.getters.user.id},
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putUserInGroup(group_id, user_id, user_type) {
        return axios.put(
            'brivo/group/' + group_id + '/user',
            {
                'homebase_admin_id': store.getters.user.id,
                'user_id': user_id,
                'user_type': user_type,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    deleteBrivoGroup(group_id) {
        return axios.delete(
            '/brivo/group/' + group_id,
            {
                data: {'homebase_admin_id': store.getters.user.id},
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    deleteUnassignGroupPermission(group_id, device_id) {
        return axios.delete(
            'brivo/group/' + group_id + '/permission',
            {
                data: {
                    'homebase_admin_id': store.getters.user.id,
                    'device_id': device_id,
                },
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    deleteBrivoUser(user_id, user_type) {
        return axios.delete(
            '/brivo/user/',
            {
                data: {
                    'homebase_admin_id': store.getters.user.id,
                    'user_id': user_id,
                    'user_type': user_type,
                },
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    deleteUserFromGroup(group_id, user_id, user_type) {
        return axios.delete(
            'brivo/group/' + group_id + '/user',
            {
                data: {
                    'homebase_admin_id': store.getters.user.id,
                    'user_id': user_id,
                    'user_type': user_type,
                },
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
            }
        )
            .then((response) => {
                return response.data;
            });
    },

    /**
     * Buildings
     */
    postBuilding(building) {
        return axios.post(
            '/account/portfolio/' + building.portfolio_id + '/building',
            {
                'homebase_admin_id': store.getters.user.id,
                'building_name': building.building_name,
                'building_type_id': building.building_type_id,
                'address_1': building.address_1,
                'address_2': building.address_2,
                'city_name': building.city_name,
                'state': building.state,
                'zip': building.zip,
                'help_number': building.help_number,
                'maintenance_number': building.maintenance_number,
                'number_of_floors': building.number_of_floors,
                'contact_email': building.contact_email,
                'timezone_string': building.timezone_string,
                'unit_count': building.unit_count,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    postBuildingPlan(buildingId) {
        return axios.post(
            '/account/building/' + buildingId + '/plan',
            {
                'homebase_admin_id': store.getters.user.id,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    postBuildingLogo(building_id, logo) {
        const data = new FormData;
        data.append('homebase_admin_id', store.getters.user.id);
        data.append('building_logo', logo);

        return axios.post(
            '/account/building/' + building_id + '/logo',
            data,
            {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    postBuildingConfiguration(configuration) {
        const { user } = store.getters;
        const {
            rent_due,
            rent_late,
            invoice_closing_date,
            prorated_rent,
            ach_fee_type_id,
            late_fee_type_id,
            late_daily_fee_amount,
            late_fee_amount,
            late_fee_percent,
            late_daily_percent,
        } = configuration;

        const buildingConfigData = {
            homebase_admin_id: user.id,
            rent_due,
            rent_late,
            invoice_closing_date,
            prorated_rent: prorated_rent === 'true',
            ach_fee_type_id,
            late_fee_type_id,
            late_daily_fee_amount,
            late_fee_amount,
            late_fee_percent,
            late_daily_percent,
        };

        const accessToken = user.access_token;

        return axios.post(
            `/account/building/${configuration.id}/configuration`,
            { ...buildingConfigData },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        ).then(response => response.data);
    },
    postBuildingLive(buildingId) {
        return axios.post(
            '/account/building/' + buildingId + '/live',
            {'homebase_admin_id': store.getters.user.id},
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    getBuilding(id) {
        return axios.get(
            '/account/building/' + id,
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {'homebase_admin_id': store.getters.user.id},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getBuildingAmeninitySpaces(id) {
        return axios.get(
            '/building/' + id + '/spaces/chell', {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
                params: {
                    'homebase_admin_id': store.getters.user.id,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getLateFees() {
        return axios.get(
            '/account/building/latefees',
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {'homebase_admin_id': store.getters.user.id},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getBuildingReady(id) {
        return axios.get(
            '/account/building/' + id + '/ready',
            {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
                params: {'homebase_admin_id': store.getters.user.id},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    putBuilding(building) {
        return axios.put(
            '/account/building/' + building.id,
            {
                'homebase_admin_id': store.getters.user.id,
                'building_name': building.building_name,
                'building_type_id': building.building_type_id,
                'number_of_floors': building.number_of_floors,
                'unit_count': building.unit_count,
                'address_1': building.address_1,
                'address_2': building.address_2,
                'city_name': building.city_name,
                'state': building.state,
                'zip': building.zip,
                'timezone_string': building.timezone_string,
                'contact_email': building.contact_email,
                'help_number': building.help_number,
                'maintenance_number': building.maintenance_number,
                'billing_id': building.billing_id,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putBuildingConfiguration(configuration) {
        const { user } = store.getters;
        const {
            rent_due,
            rent_late,
            invoice_closing_date,
            prorated_rent,
            ach_fee_type_id,
            late_fee_type_id,
            late_daily_fee_amount,
            late_fee_amount,
            late_fee_percent,
            late_daily_percent,
            id,
        } = configuration;

        const buildingConfigData = {
            homebase_admin_id: user.id,
            rent_due,
            rent_late,
            invoice_closing_date,
            prorated_rent: prorated_rent === 'true',
            ach_fee_type_id,
            late_fee_type_id,
            late_daily_fee_amount,
            late_fee_amount,
            late_fee_percent,
            late_daily_percent,
        };

        const accessToken = user.access_token;

        return axios.put(
            `/account/building/${id}/configuration`,
            { ...buildingConfigData },
            { headers: { Authorization: `Bearer ${accessToken}` } }
        ).then(response => response.data);
    },
    putBuildingAdjustment(buildingId, adjustment) {
        return axios.put(
            '/account/building/' + buildingId + '/adjustment',
            {
                'homebase_admin_id': store.getters.user.id,
                'adjustment': adjustment,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    patchBuildingFeature(buildingId, baseFeatureId, status) {
        return axios.patch(
            `/account/building/${buildingId}/feature/${baseFeatureId}?hide_loading_div`,
            {
                'homebase_admin_id': store.getters.user.id,
                'feature_status': status,
            },
            {headers: {'Authorization': `Bearer ${store.getters.user.access_token}`}}
        )
            .then((response) => {
                return response.data;
            });
    },

    /**
     * Employees
     */
    postEmployee(employee) {
        return axios.post(
            '/chell/employee',
            {
                'homebase_admin_id': store.getters.user.id,
                'first_name': employee.first_name,
                'last_name': employee.last_name,
                'email': employee.email,
                'phone_number': employee.phone_number,
                'title': employee.title,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    getEmployees() {
        return axios.get(
            '/chell/employees',
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {'homebase_admin_id': store.getters.user.id},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getPermissions() {
        return axios.get(
            '/permissions',
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {'homebase_admin_id': store.getters.user.id},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    putEmployee(employee, current_permissions, available_permissions) {
        return axios.put(
            '/chell/employee/' + employee.id,
            {
                'homebase_admin_id': store.getters.user.id,
                'first_name': employee.first_name,
                'last_name': employee.last_name,
                'email': employee.email,
                'phone_number': employee.phone_number,
                'title': employee.title,
                'current_permissions': current_permissions,
                'available_permissions': available_permissions,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putActivateEmployee(employee) {
        return axios.put(
            '/chell/employee/' + employee.id + '/activate',
            {'homebase_admin_id': store.getters.user.id},
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putResetEmployee(employee) {
        return axios.post(
            '/chell/employee/' + employee.id + '/reset',
            {'homebase_admin_id': store.getters.user.id},
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },

    getChellFobs(startNumber, endNumber, facilityCode, bits) {
        return axios.get(
            '/chell/fobs',
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {
                    'homebase_admin_id': store.getters.user.id,
                    'start_number': startNumber,
                    'end_number': endNumber,
                    'facility_code': facilityCode,
                    'bits': bits,
                },
            }
        ).then((response) => {
            return response.data;
        });
    },

    /**
     * Fobs
     */
    getActiveFobs(search, building) {
        return axios.get(
            '/building/' + building.id + '/fobs/assigned/chell',
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {
                    'homebase_admin_id': store.getters.user.id,
                    'search_text': search,
                    'building_id': building.id,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getActivePortfolioFobs(search, portfolio_id) {
        return axios.get(
            '/portfolio/' + portfolio_id + '/fobs/assigned/chell',
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {
                    'homebase_admin_id': store.getters.user.id,
                    'search_text': search,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getAvailableFobs(search, building) {
        return axios.get(
            '/building/' + building.id + '/fobs/available/chell',
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {
                    'homebase_admin_id': store.getters.user.id,
                    'search_text': search,
                    'building_id': building.id,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getAvailablePortfolioFobs(search, portfolio_id) {
        return axios.get(
            '/portfolio/' + portfolio_id + '/fobs/available/chell',
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {
                    'homebase_admin_id': store.getters.user.id,
                    'search_text': search,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getPaginatedActiveFobs(search, building, url) {
        return axios.get(
            '/building/' + building.id + '/fobs/assigned/chell?' + url,
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {
                    'homebase_admin_id': store.getters.user.id,
                    'search_text': search,
                    'building_id': building.id,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getPaginatedActivePortfolioFobs(search, portfolio_id, url) {
        return axios.get(
            '/portfolio/' + portfolio_id + '/fobs/assigned/chell?' + url,
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {
                    'homebase_admin_id': store.getters.user.id,
                    'search_text': search,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getPaginatedAvailableFobs(search, building, url) {
        return axios.get(
            '/building/' + building.id + '/fobs/available/chell?' + url,
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {
                    'homebase_admin_id': store.getters.user.id,
                    'search_text': search,
                    'building_id': building.id,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getPaginatedAvailablePortfolioFobs(search, portfolio_id, url) {
        return axios.get(
            '/portfolio/' + portfolio_id + '/fobs/available/chell?' + url,
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {
                    'homebase_admin_id': store.getters.user.id,
                    'search_text': search,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    postAddAllegionFobs(start_number, end_number, fob_type_id, bits, facility_code, portfolio_id, building_id) {
        return axios.post(
            'fobs',
            {
                'homebase_admin_id': store.getters.user.id,
                'start_number': start_number,
                'end_number': end_number,
                'fob_type_id': fob_type_id,
                'bits': bits,
                'facility_code': facility_code,
                'portfolio_id': portfolio_id,
                'building_id': building_id,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putAssignFobsToBuilding(start_number, end_number, facility_code, fob_type_id, building_id, portfolio_id) {
        return axios.put(
            'fobs/assign/building',
            {
                'homebase_admin_id': store.getters.user.id,
                'start_number': start_number,
                'end_number': end_number,
                'facility_code': facility_code,
                'fob_type_id': fob_type_id,
                'building_id': building_id,
                'portfolio_id': portfolio_id,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    disassociateFobs(start_number, end_number, facility_code, building_id, portfolio_id) {
        return axios.delete(
            'fobs/disassociate',
            {
                data: {
                    'homebase_admin_id': store.getters.user.id,
                    'start_number': start_number,
                    'end_number': end_number,
                    'facility_code': facility_code,
                    'building_id': building_id,
                    'portfolio_id': portfolio_id,
                },
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    deleteFobs(start_number, end_number, facility_code, portfolio_id) {
        return axios.delete(
            'fob/batch-delete',
            {
                data: {
                    'homebase_admin_id': store.getters.user.id,
                    'start_number': start_number,
                    'end_number': end_number,
                    'facility_code': facility_code,
                    'portfolio_id': portfolio_id,
                },
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    deleteFobBuilding(fob_id) {
        return axios.delete(
            '/fob/' + fob_id + '/buildings',
            {
                data: {'homebase_admin_id': store.getters.user.id},
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    deleteFob(fob_id) {
        return axios.delete(
            '/chell/fobs/' + fob_id,
            {
                data: {'homebase_admin_id': store.getters.user.id},
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    deleteUnassignFob(fob_id) {
        return axios.delete(
            '/fob/' + fob_id + '/unassign/chell',
            {
                data: {
                    'homebase_admin_id': store.getters.user.id,
                },
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
            }
        )
            .then((response) => {
                return response.data;
            });
    },

    /**
     * Internet
     */
    postBuildingInternetConfiguration(configuration, id) {
        return axios.post(
            '/account/building/' + id + '/internet',
            {
                'homebase_admin_id': store.getters.user.id,
                'up_speed': configuration.up_speed,
                'down_speed': configuration.down_speed,
                'carrier': configuration.carrier,
                'support_number': configuration.support_number,
                'account_number': configuration.account_number,
                'contact_name': configuration.contact_name,
                'credit_on_downgrade': configuration.credit_on_downgrade,
                'address_1': configuration.address_1,
                'address_2': configuration.address_2,
                'city': configuration.city,
                'state': configuration.state,
                'postal_code': configuration.postal_code,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    postBuildingInternetTier(tier, id) {
        return axios.post(
            '/account/building/' + id + '/tier',
            {
                'homebase_admin_id': store.getters.user.id,
                'name': tier.name,
                'up_speed': tier.up_speed,
                'down_speed': tier.down_speed,
                'cost': tier.cost * 100,
                'description': tier.description,
                'image_url': tier.image_url,
                'default': tier.default,
                'downgrade': tier.downgrade,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    postConnectArubaAccount(building_id, form) {
        return axios.post(
            '/aruba/building/' + building_id,
            {
                'homebase_admin_id': store.getters.user.id,
                'username': form.username,
                'password': form.password,
                'customer_id': form.customer_id,
                'client_id': form.client_id,
                'client_secret': form.client_secret,
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    postGenerateArubaTemplate(building_id) {
        return axios.post(
            '/aruba/building/' + building_id + '/generate-aruba-template',
            {
                'homebase_admin_id': store.getters.user.id,
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
            }
        );
    },
    getInternetTierImages() {
        return axios.get('/account/internet/images', {
            headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
            params: {'homebase_admin_id': store.getters.user.id},
        }).then((response) => {
            return response.data;
        });
    },
    putBuildingInternetConfiguration(configuration, id) {
        return axios.put(
            '/account/building/' + id + '/internet',
            {
                'homebase_admin_id': store.getters.user.id,
                'up_speed': configuration.up_speed,
                'down_speed': configuration.down_speed,
                'carrier': configuration.carrier,
                'support_number': configuration.support_number,
                'account_number': configuration.account_number,
                'contact_name': configuration.contact_name,
                'credit_on_downgrade': configuration.credit_on_downgrade,
                'address_1': configuration.address_1,
                'address_2': configuration.address_2,
                'city': configuration.city,
                'state': configuration.state,
                'postal_code': configuration.postal_code,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putBuildingInternetTier(tier, id) {
        return axios.put(
            '/account/building/' + id + '/tier',
            {
                'homebase_admin_id': store.getters.user.id,
                'tier_id': tier.tier_id,
                'name': tier.name,
                'up_speed': tier.up_speed,
                'down_speed': tier.down_speed,
                'cost': tier.cost * 100,
                'description': tier.description,
                'image_url': tier.image_url,
                'default': tier.default,
                'downgrade': tier.downgrade,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    deleteBuildingInternetTier(buildingId, tierId) {
        return axios.delete(
            '/account/building/' + buildingId + '/tier/' + tierId,
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {'homebase_admin_id': store.getters.user.id},
            }
        )
            .then((response) => {
                return response.data;
            });
    },

    /**
     * Portfolios
     */
    postPortfolio(portfolio) {
        const data = new FormData;
        data.append('homebase_admin_id', store.getters.user.id);
        data.append('first_name', portfolio.first_name);
        data.append('last_name', portfolio.last_name);
        data.append('email', portfolio.email);
        data.append('phone_number', portfolio.phone_number);
        data.append('business_name', portfolio.business_name);
        data.append('portfolio_logo', portfolio.portfolio_logo);
        data.append('address_1', portfolio.address_1);
        data.append('address_2', portfolio.address_2);
        data.append('business_city', portfolio.business_city);
        data.append('business_state', portfolio.business_state);
        data.append('postal_code', portfolio.postal_code);
        data.append('business_classification', portfolio.business_classification);
        data.append('business_type', portfolio.business_type);
        data.append('ein', portfolio.ein);
        data.append('tos_accepted_by', portfolio.tos_accepted_by);
        data.append('primary_contact_email', portfolio.primary_contact_email);

        return axios.post(
            '/account/create',
            data,
            {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    postPortfolioLogo(portfolio_id, logo) {
        const data = new FormData;
        data.append('homebase_admin_id', store.getters.user.id);
        data.append('portfolio_logo', logo);

        return axios.post(
            '/account/portfolio/' + portfolio_id + '/logo',
            data,
            {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getBusinessClassifications() {
        return axios.get('/account/business/classifications', {
            headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
            params: {'homebase_admin_id': store.getters.user.id},
        }).then((response) => {
            return response.data;
        });
    },
    getBusinessTypes() {
        return axios.get('/account/business/types', {
            headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
            params: {'homebase_admin_id': store.getters.user.id},
        }).then((response) => {
            return response.data;
        });
    },
    getPortfolios() {
        return axios.get('/account/portfolios', {
            headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
            params: {'homebase_admin_id': store.getters.user.id},
        }).then((response) => {
            return response.data;
        });
    },
    getPortfolio(id) {
        return new Promise(function(resolve, reject) {
            let portfolioObject = {};
            setTimeout(() => {
                axios.get(
                    '/account/portfolio/' + id, {
                        headers: {
                            'Authorization': 'Bearer ' + store.getters.user.access_token,
                        },
                        params: {
                            'homebase_admin_id': store.getters.user.id,
                        },
                    }
                )
                    .then((response) => {
                        portfolioObject.portfolio = response.data.portfolio;

                        axios.get(
                            '/account/portfolio/' + id + '/data', {
                                headers: {
                                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                                },
                                params: {
                                    'homebase_admin_id': store.getters.user.id,
                                },
                            }
                        )
                            .then((response) => {
                                portfolioObject.fob_types = response.data.fob_types;
                                portfolioObject.building_types = response.data.building_types;
                                axios.get(
                                    '/account/portfolio/' + id + '/data/admins', {
                                        headers: {
                                            'Authorization': 'Bearer ' + store.getters.user.access_token,
                                        },
                                        params: {
                                            'homebase_admin_id': store.getters.user.id,
                                        },
                                    }
                                )
                                    .then((response) => {
                                        portfolioObject.portfolio_admins = response.data.admins;
                                        resolve(portfolioObject);
                                    });
                            });
                    });
            });
        }).then((response) => {
            return response;
        });
    },
    getBrivoUsers(id) {
        return axios.get(
            '/account/portfolio/' + id + '/data/brivo', {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
                params: {
                    'homebase_admin_id': store.getters.user.id,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getAllegionUsers(id) {
        return axios.get(
            '/account/portfolio/' + id + '/data/allegion', {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
                params: {
                    'homebase_admin_id': store.getters.user.id,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getPortfolioAdmins(id) {
        return axios.get(
            '/account/portfolio/' + id + '/admins',
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {'homebase_admin_id': store.getters.user.id},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    getPortfolioBuildings(id) {
        return axios.get(
            '/account/portfolio/' + id + '/buildings',
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {'homebase_admin_id': store.getters.user.id},
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    putPortfolio(portfolio) {
        return axios.put(
            '/account/portfolio/' + portfolio.id,
            {
                'homebase_admin_id': store.getters.user.id,
                'business_name': portfolio.business_name,
                'address_1': portfolio.address_1,
                'address_2': portfolio.address_2,
                'business_city': portfolio.business_city,
                'business_state': portfolio.business_state,
                'postal_code': portfolio.postal_code,
                'business_classification': portfolio.business_classification,
                'business_type': portfolio.business_type,
                'ein': portfolio.ein,
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    postPortfolioAllegionAccount(portfolio, username, password) {
        return axios.post(
            'portfolio/' + portfolio.id + '/allegion/setup',
            {
                'homebase_admin_id': store.getters.user.id,
                'username': username,
                'password': password,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },

    /**
     * Super Admins
     */
    postSuperAdmin(admin) {
        return axios.post(
            '/account/superadmin',
            {
                'homebase_admin_id': store.getters.user.id,
                'first_name': admin.first_name,
                'last_name': admin.last_name,
                'email': admin.email,
                'phone_number': admin.phone_number,
                'job_title': admin.job_title,
                'portfolio_id': admin.portfolio_id,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putSuperAdmin(admin) {
        return axios.put(
            '/account/admin/' + admin.id + '/superadmin',
            {'homebase_admin_id': store.getters.user.id},
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    deleteSuperAdmin(admin) {
        return axios.delete(
            '/account/admin/' + admin.id + '/superadmin',
            {
                headers: {'Authorization': 'Bearer ' + store.getters.user.access_token},
                params: {'homebase_admin_id': store.getters.user.id},
            }
        )
            .then((response) => {
                return response.data;
            });
    },

    /**
     * Units
     */
    getUnitsForBuildingExport(buildingId) {
        return axios.get(
            `/export/unitlist/building/${buildingId}?homebase_admin_id=${store.getters.user.id}`,
            {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
                responseType: 'blob',
            });
    },

    postUnit(unit, buildingId) {
        return axios.post(
            '/account/building/' + buildingId + '/unit',
            {
                'homebase_admin_id': store.getters.user.id,
                'unit_number': unit.unit_number,
                'floor': unit.floor,
                'bedrooms': unit.bedrooms,
                'bathrooms': unit.bathrooms,
                'max_residents': unit.max_residents,
                'total_rent': unit.total_rent * 100,
                'description': unit.description,
                'square_feet': unit.square_feet,
                'unit_ssid': unit.unit_ssid,
                'unit_password': unit.unit_password,
                'device_ssid': unit.device_ssid,
                'device_password': unit.device_password,
                'ap_serial': unit.ap_serial,
                'switch_serial': unit.switch_serial,
                'ap_mac_address': unit.ap_mac_address,
                'switch_mac_address': unit.switch_mac_address,
                'switch_vlan': unit.switch_vlan,
                'ap_vlan': unit.ap_vlan,
                'ap_identifier': unit.ap_identifier,
                'switch_identifier': unit.switch_identifier,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    postUnits(units, buildingId) {
        return axios.post(
            '/account/building/' + buildingId + '/units',
            {
                'homebase_admin_id': store.getters.user.id,
                units,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putUnit(unit) {
        return axios.put(
            '/account/unit/' + unit.id,
            {
                'homebase_admin_id': store.getters.user.id,
                'unit_number': unit.unit_number,
                'floor': unit.floor,
                'bedrooms': unit.bedrooms,
                'bathrooms': unit.bathrooms,
                'max_residents': unit.max_residents,
                'total_rent': unit.total_rent * 100,
                'description': unit.description,
                'square_feet': unit.square_feet,
                'unit_ssid': unit.unit_ssid,
                'unit_password': unit.unit_password,
                'device_ssid': unit.device_ssid,
                'device_password': unit.device_password,
                'ap_serial': unit.ap_serial,
                'switch_serial': unit.switch_serial,
                'ap_mac_address': unit.ap_mac_address,
                'switch_mac_address': unit.switch_mac_address,
                'switch_vlan': unit.switch_vlan,
                'ap_vlan': unit.ap_vlan,
                'ap_identifier': unit.ap_identifier,
                'switch_identifier': unit.switch_identifier,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },

    putTljUnit(unit, room_name, area_id = null, floor_id = null) {
        return axios.put(
            'units/' + unit + '/tlj-unit',
            {
                'homebase_admin_id': store.getters.user.id,
                'tlj_room_name': room_name,
                'tlj_area_id': area_id,
                'tlj_floor_id': floor_id,
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
            })
            .then((response) => {
                return response.data;
            });
    },

    putTljSpace(space, room_name, area_id = null, floor_id = null) {
        return axios.put(
            'spaces/' + space + '/tlj-space',
            {
                'homebase_admin_id': store.getters.user.id,
                'tlj_room_name': room_name,
                'tlj_area_id': area_id,
                'tlj_floor_id': floor_id,
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
            })
            .then((response) => {
                return response.data;
            });
    },

    /**
     * Spaces
     */
    getSpacesForBuildingExport(buildingId) {
        return axios.get(
            `/export/spacelist/building/${buildingId}?homebase_admin_id=${store.getters.user.id}`,
            {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
                responseType: 'blob',
            });
    },
    postSpace(space, buildingId) {
        return axios.post(
            'chell/building/' + buildingId + '/space',
            {
                'homebase_admin_id': store.getters.user.id,
                'space_name': space.space_name,
                'floor': space.floor,
                'description': space.description,
                'restricted': space.restricted,
                'device_type_restrictions': space.device_type_restrictions,
            },
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    putSpaces(spaces, buildingId) {
        return axios.put(
            '/spaces/import/buildings/' + buildingId,
            {
                'homebase_admin_id': store.getters.user.id,
                spaces,
            }, {
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
    putSpace(space) {
        return axios.put(
            'chell/building/' + space.building_id + '/space/' + space.id,
            {
                'homebase_admin_id': store.getters.user.id,
                'space_name': space.space_name,
                'floor': space.floor,
                'description': space.description,
                'restricted': space.restricted,
                'device_type_restrictions': space.device_type_restrictions,
            },
            { headers: { 'Authorization': 'Bearer ' + store.getters.user.access_token } }
        )
            .then((response) => {
                return response.data;
            });
    },
    deleteSpace: function(space) {
        return axios.delete(
            'buildings/' + space.building_id + '/spaces/' + space.id,
            {
                data: {
                    'homebase_admin_id': store.getters.user.id,
                },
                headers: {
                    'Authorization': 'Bearer ' + store.getters.user.access_token,
                },
            }).then((response) => {
            return response.data;
        });
    },

    /**
     * Users
     */
    enableNextDayPayments(tenant_id) {
        return axios.put(
            'tenant/' + tenant_id + '/next-day/grant',
            {'homebase_admin_id': store.getters.user.id},
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    disableNextDayPayments(tenant_id) {
        return axios.put(
            'tenant/' + tenant_id + '/next-day/revoke',
            {'homebase_admin_id': store.getters.user.id},
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    tenantResetPassword(tenant_id) {
        return axios.put(
            'tenant/' + tenant_id + '/password',
            {'homebase_admin_id': store.getters.user.id},
            {headers: {'Authorization': 'Bearer ' + store.getters.user.access_token}}
        )
            .then((response) => {
                return response.data;
            });
    },
    revokeAllegionAccess(user) {
        return axios.delete(
            'user/' + user.id + '/allegion/access',
            {
                headers: {
                    'Authorization':
                        'Bearer ' + store.getters.user.access_token,
                },
                params: {
                    'homebase_admin_id': store.getters.user.id,
                    'user_type': user.type,
                },
            }
        )
            .then((response) => {
                return response.data;
            });
    },
};