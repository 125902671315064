<template>
  <div class="container content">
    <div class="row justify-content-center">
      <div class="col">
        <h4>{{ building.building_name }}</h4>
        <p class="subtitle" v-if="building.portfolio !== null">
          <router-link :to="{name: 'portfolio', params: {'id': this.$route.params.id}}">{{ building.portfolio.business_name }}</router-link>
        </p>
        <p v-else class="d-block" style="margin-bottom: 39px;"></p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-auto">
        <Navigation />
      </div>
      <div class="col">
        <h4 class="font-weight-normal">Configure Brivo Sites</h4>
        <div class="row justify-content-center mt-5" v-if="!brivo">
          <div class="col-10 text-center">
            <h2>🔐</h2>
            <p>This portfolio does not have a connected Brivo account.</p>
          </div>
        </div>
        <form @submit.prevent="syncBuilding" class="building-form" v-if="brivo">
          <div class="form-group">
            <label for="building">Select Brivo Site</label>
            <div class="input-group">
              <select :disabled="disabled || selected" class="form-control" id="building" required style="height: 37px;" v-model="selected_building">
                <option :key="site.id" :value="site.id" v-for="site in sites">{{ site.name }}</option>
              </select>
              <div class="input-group-append">
                <button :disabled="disabled || selected" @click="syncBuilding" class="btn btn-primary" style="min-width: 125px;">Sync</button>
              </div>
            </div>
          </div>
        </form>
        <div v-if="brivo && selected">
          <h6>{{ building.building_name }} - Doors ({{ building.brivo_devices.length }})</h6>
          <div :key="device.id" class="card" v-for="device in building.brivo_devices">
            <div class="card-body maintenance-category">
              {{ device.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="building-footer">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col col-md-6 col-lg-3">
            <router-link :to="{name: 'brivogroups', params: {'id': $route.params.id, 'building_id': $route.params.building_id}}" class="btn btn-primary btn-block" tag="button">Next</router-link>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
    import api from '../../../api.js';
    import Navigation from '../../../components/BuildingNav.vue';
    import Unit from '../../../components/Unit.vue';

    export default {
        name: 'BrivoSite',
        components: {
            Navigation,
            Unit,
        },
        data() {
            return {
                brivo: false,
                building: {
                    building_name: '',
                    portfolio: {
                        business_name: '',
                    }
                },
                disabled: false,
                selected: false,
                selected_building: '',
                sites: [],
            };
        },
        beforeMount() {
            const self = this;
            if (self.$route.params.building_id === 'undefined') {
                self.$router.go(-1);
                return;
            }

            self.getBuilding();
        },
        methods: {
            getBuilding() {
                const self = this;
                api.getBuilding(self.$route.params.building_id)
                    .then((response) => {
                        self.building = response.building;

                        if (self.building.portfolio === null) {
                            self.brivo = false;
                            if ($('.content').css('display') !== 'block') {
                                $('.content').css('display', 'block');
                                $('.content').animateCss('fadeIn');
                            }
                            return;
                        }

                        if (self.building.portfolio.brivo_account !== null) {
                            self.brivo = true;
                            self.sites = self.building.portfolio.brivo_account.sites;
                        }

                        if (self.building.brivo_site !== null) {
                            self.selected = true;
                            self.selected_building = self.building.brivo_site.id;
                        }

                        if ($('.content').css('display') !== 'block') {
                            $('.content').css('display', 'block');
                            $('.content').animateCss('fadeIn');
                        }
                    })
                    .catch((error) => {
                        self.handleError(error);
                    });
            },
            syncBuilding() {
                const self = this;
                self.disabled = true;
                api.putSiteToBuilding(self.selected_building, self.$route.params.building_id)
                    .then((response) => {
                        self.getBuilding();
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    })
            }
        },
    };
</script>
