<template>
  <div class="container content">
    <div class="row justify-content-center">
      <div class="col">
        <h4>{{ building.building_name }}</h4>
        <p class="subtitle" v-if="building.portfolio !== null">
          <router-link :to="{name: 'portfolio', params: {'id': this.$route.params.id}}">{{ building.portfolio.business_name }}</router-link>
        </p>
        <p v-else class="d-block" style="margin-bottom: 39px;"></p>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-auto">
        <Navigation />
      </div>
      <div class="col">
        <form @submit.prevent="putBuildingInformation" class="building-form">
          <h4 class="font-weight-normal">Building Details</h4>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="building_name">Building Name</label>
                <input :class="{'is-invalid':$v.form.building_name.$anyError}" class="form-control" id="building_name" type="text" v-model.trim="$v.form.building_name.$model">
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="business_type">Business Type</label>
                <select :class="{'is-invalid':$v.form.building_type_id.$anyError}" class="form-control" id="business_type" v-model.trim="$v.form.building_type_id.$model">
                  <option :key="type.id" :value="type.id" v-for="type in building_types">{{ type.name }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="number_of_floors">Number of Floors</label>
                <input :class="{'is-invalid':$v.form.number_of_floors.$anyError}" class="form-control" id="number_of_floors" min="1" step="1" type="number" v-model.trim="$v.form.number_of_floors.$model">
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="unit_count">Number of Units</label>
                <input :class="{'is-invalid':$v.form.unit_count.$anyError}" class="form-control" id="unit_count" min="1" step="1" type="number" v-model.trim="$v.form.unit_count.$model">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-2">
                <label for="billing_id">Homebase Billing ID Number</label>
                <input class="form-control" id="billing_id" type="text" v-model.trim="form.billing_id">
              </div>
              <span>The Homebase Billing ID Number is the account number is found in SalesForce on the Opportunity and Project pages.</span>
            </div>
          </div>
          <h4 class="font-weight-normal mt-3">Building Location</h4>
          <div class="row">
            <div class="col-12">
              <p class="warning-text">
                Warning: You MUST use the address formatting from Google. Copy the address into a Google search, and paste the EXACT address format that Google returns into the form below.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="address_1">Address 1</label>
                <input :class="{'is-invalid':$v.form.address_1.$anyError}" class="form-control" id="address_1" type="text" v-model.trim="$v.form.address_1.$model">
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="address_2">Address 2 (Optional)</label>
                <input class="form-control" id="address_2" type="text" v-model.trim="form.address_2">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="city_name">City</label>
                <input :class="{'is-invalid':$v.form.city_name.$anyError}" class="form-control" id="city_name" type="text" v-model.trim="$v.form.city_name.$model">
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="state">State</label>
                <select :class="{'is-invalid':$v.form.state.$anyError}" class="form-control" id="state" v-model.trim="$v.form.state.$model">
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="zip">Zip Code</label>
                <input :class="{'is-invalid':$v.form.zip.$anyError}" class="form-control" id="zip" type="text" v-model.trim="$v.form.zip.$model">
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="timezone_string">Timezone</label>
                <select :class="{'is-invalid':$v.form.timezone_string.$anyError}" class="form-control" id="timezone_string" v-model.trim="$v.form.timezone_string.$model">
                  <option value="America/New_York">Eastern (New York)</option>
                  <option selected="selected" value="America/Chicago">Central (Chicago)</option>
                  <option value="America/Denver">Mountain (Denver)</option>
                  <option value="America/Phoenix">Mountain, no DST (Phoenix)</option>
                  <option value="America/Los_Angeles">Pacific (Los Angeles)</option>
                  <option value="America/Anchorage">Alaskan (Anchorage)</option>
                  <option value="America/Adak">Hawaiian (Adak)</option>
                  <option value="Pacific/Honolulu">Hawaiian, no DST (Honolulu)</option>
                </select>
              </div>
            </div>
          </div>
          <h4 class="font-weight-normal mt-3">Building Contact Information</h4>
          <div class="form-group">
            <label for="contact_email">Building Email</label>
            <input :class="{'is-invalid':$v.form.contact_email.$anyError}" class="form-control" id="contact_email" type="email" v-model.trim="$v.form.contact_email.$model">
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="help_number">Help Number</label>
                <input :class="{'is-invalid':$v.form.help_number.$anyError}" class="form-control" id="help_number" type="text" v-model.trim="$v.form.help_number.$model">
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="maintenance_number">Maintenance Number</label>
                <input :class="{'is-invalid':$v.form.maintenance_number.$anyError}" class="form-control" id="maintenance_number" type="text" v-model.trim="$v.form.maintenance_number.$model">
              </div>
            </div>
          </div>
          <button class="d-none" type="submit"></button>
        </form>
      </div>
    </div>
    <footer class="building-footer">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col col-md-6 col-lg-3">
            <button :disabled="disabled || $v.$invalid" @click="putBuildingInformation" class="btn btn-primary btn-block">Save</button>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<style scoped lang="scss">
  .warning-text {
    font-size: 16px;
    color: #f20000;
  }
</style>
<script>
    import api from '../../../api.js';
    import Navigation from '../../../components/BuildingNav.vue';
    import {email, integer, maxLength, minValue, required} from 'vuelidate/lib/validators';

    export default {
        components: {
            Navigation,
        },
        data() {
            return {
                disabled: false,
                building: {
                    building_name: '',
                    portfolio: {
                        business_name: '',
                    },
                },
                building_types: [],
                form: {
                    building_name: '',
                    building_type_id: 1,
                    number_of_floors: 1,
                    unit_count: 1,
                    address_1: '',
                    address_2: '',
                    city_name: '',
                    state: 'MO',
                    zip: '',
                    timezone_string: 'America/Chicago',
                    contact_email: '',
                    help_number: '',
                    maintenance_number: '',
                    billing_id: null,
                },
                portfolio: {
                    business_name: '',
                },
            };
        },
        validations: {
            form: {
                building_name: {
                    maxLength: maxLength(255),
                    required
                },
                building_type_id: {
                    integer,
                    required
                },
                number_of_floors: {
                    integer,
                    minValue: minValue(1),
                    required
                },
                unit_count: {
                    integer,
                    minValue: minValue(1),
                    required
                },
                address_1: {
                    required
                },
                city_name: {
                    required,
                },
                state: {
                    required
                },
                zip: {
                    maxValue: maxLength(6),
                    required
                },
                timezone_string: {
                    required
                },
                contact_email: {
                    email,
                    required
                },
                help_number: {
                    required
                },
                maintenance_number: {
                    required
                }
            }
        },
        beforeMount() {
            const self = this;
            if (self.$route.params.building_id === 'undefined') {
                self.$router.go(-1);
                return;
            }

            api.getBuilding(self.$route.params.building_id)
                .then((response) => {
                    self.building = response.building;
                    self.building_types = response.building_types;

                    self.form.building_name = self.building.building_name;
                    self.form.building_type_id = self.building.building_type_id;
                    self.form.number_of_floors = self.building.number_of_floors;
                    self.form.unit_count = self.building.unit_count;
                    self.form.address_1 = self.building.address_1;
                    self.form.address_2 = self.building.address_2;
                    self.form.city_name = self.building.city_name;
                    self.form.state = self.building.state;
                    self.form.zip = self.building.zip;
                    self.form.timezone_string = self.building.timezone_string;
                    self.form.contact_email = self.building.contact_email;
                    self.form.help_number = self.building.help_number;
                    self.form.maintenance_number = self.building.maintenance_number;
                    self.form.billing_id = self.building.billing_id;

                    $('.content').css('display', 'block');
                    $('.content').animateCss('fadeIn');
                })
                .catch((error) => {
                    self.handleError(error);
                });
        },
        methods: {
            resetForm() {
                this.$v.$reset();
                Object.assign(this.$data, this.$options.data.call(this));
            },
            putBuildingInformation() {
                const self = this;
                if (self.disabled || self.$v.$invalid) return;
                self.disabled = true;

                self.form.id = self.$route.params.building_id;
                api.putBuilding(self.form)
                    .then((response) => {
                        self.$router.push({name: 'buildingplan', params: {id: this.$route.params.id, building_id: this.$route.params.building_id}});
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                        $(window).scrollTop(0);
                    });
            },
        },
    };
</script>
