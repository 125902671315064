<template>
  <transition name="modal">
    <div
      v-if="!isHidden"
      :aria-hidden="isHidden"
      class="modal"
      :class="modalClasses"
      role="dialog"
      tabindex="-1"
    >

      <!-- Following bootstrap structure and styling -->
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"><slot name="modal-header">Modal title</slot></h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="() => closeModal()"
            >
              <span aria-hidden="true">
                <i class="fal fa-times-circle fa-fw"></i>
              </span>
            </button>
          </div>
          <div class="modal-body">
            <slot><p>Modal body text goes here.</p></slot>
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>

<style scoped lang="scss">
  .modal-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
  }

  .modal {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal.modal-open {
    background-color: rgb(0,0,0,0.4);
  }

  .modal__size-xl .modal-dialog {
    max-width: 85vw;
  }

  .modal-content {
    border: 1px solid rgba(0,0,0,.2);
  }

  //
  // TRANSITION CLASSES
  // - these are added by vuejs based on the name given to transition
  // ==================================================

  .modal-enter-active,
  .modal-leave-active {
    transition: opacity 0.5s ease;
  }

  .modal-enter-from,
  .modal-leave-to {
    opacity: 0;
  }
</style>

<script>
export default {
  name: 'ModalWrapper',
  props: {
    hidden: Boolean,
    modalId: String,
    size: String,
  },
  computed: {
    modalClasses() {
      let classes = this.isHidden ? '' : 'modal-open';

      if (this.size === 'xlarge') {
        classes = `${classes} modal__size-xl`;
      }

      return classes;
    },
    isHidden() {
      const storeIsOpen = this.$store.getters['modal/openById'](this.modalId);

      return !storeIsOpen;
    },
  },

  methods: {
    closeModal() {
      this.$store.dispatch('modal/close', { modalId: this.modalId });
    },
    openModal() {
      this.$store.dispatch('modal/open', { modalId: this.modalId });
    },
  },

  //
  // LIFECYCLE
  // ----------------------------------------

  beforeDestroy() {
    if (!this.isHidden) {
      this.closeModal();
    }
  },
}
</script>
