<template>
  <div class="row">
    <div class="col">
      <router-link :to="'/portfolios/'+portfolio.id" class="card-link">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <h3 class="col font-weight-bold">{{ portfolio.business_name }}</h3>
            </div>
            <div class="row justify-content-center">
              <div class="col-sm-6 col-lg-4 col-xl-2">{{ getOwnerName }}</div>
              <div class="col-sm-6 col-lg-4 col-xl-2">Active {{ getUpdatedAt }}</div>
              <div class="col-sm-6 col-lg-4 col-xl-2">Added {{ getAddedAt }}</div>
              <div class="col-sm-6 col-lg-4 col-xl-2">{{ getPropertyCount }}</div>
              <div class="col-sm-6 col-lg-4 col-xl-2">{{ getUnitCount }}</div>
              <div class="col-sm-6 col-lg-4 col-xl-2">{{ getResidentCount }}</div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>

</template>

<script>
    export default {
        name: 'Portfolio',
        props: {
            initialPortfolio: Object,
        },
        data() {
            return {
                portfolio: null,
            };
        },
        computed: {
            getOwnerName() {
                let superAdmin = this.portfolio.super_admins[0];
                if (superAdmin) {
                    let admin = superAdmin.admin;
                    return admin.first_name + ' ' + admin.last_name;
                }
                return '— —';
            },
            getUpdatedAt() {
                return moment.utc(this.portfolio.updated_at).fromNow();
            },
            getAddedAt() {
                return moment.utc(this.portfolio.created_at).fromNow();
            },
            getPropertyCount() {
                let count = this.portfolio.buildings_count;
                let text = ' Properties';
                if (count === 1) text = ' Property';
                return count + text;
            },
            getUnitCount() {
                let count = this.portfolio.units_count;
                let text = ' Units';
                if (count === 1) text = ' Unit';
                return count + text;
            },
            getResidentCount() {
                let count = this.portfolio.tenants_count;
                let text = ' Residents';
                if (count === 1) text = ' Resident';
                return count + text;
            },
        },
        beforeMount() {
            this.portfolio = this.initialPortfolio;
        },
    };
</script>
