<template>
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h4>{{ building.building_name }}</h4>
        <p class="subtitle" v-if="building.portfolio !== null">
          <router-link :to="{name: 'portfolio', params: {'id': this.$route.params.id}}">{{ building.portfolio.business_name }}</router-link>
        </p>
        <p v-else class="d-block" style="margin-bottom: 39px;"></p>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-12 col-md-3">
        <Navigation />
      </div>
      <div class="col-md-6">
        <div>
          <div class="row">
            <h5 class="col-6">
              Community Name
            </h5>
            <div class="col-6 text-right">
              {{ building.building_name }}
            </div>
          </div>
          <hr />
          <div class="row">
            <h6 class="col-6">
              Location
            </h6>
            <div class="col-6 text-right">
              {{ building.address_1 }}, {{ building.city_name }}, {{ building.state }}, {{ building.zip }}
            </div>
          </div>
          <hr />
          <div class="row">
            <h6 class="col-6">
              Units
            </h6>
            <div class="col-6 text-right">
              <span v-if="building.unit_count !== null">{{ building.unit_count }} </span>
              <span v-else>0 </span>
              unit<span v-if="building.unit_count !== 1">s</span>
            </div>
          </div>
          <hr />
          <div class="row">
            <h6 class="col-6">
              Spaces
            </h6>
            <div class="col-6 text-right">
              <span v-if="building.space_count !== null">{{ building.spaces_count }} </span>
              <span v-else>0 </span>
              space<span v-if="building.space_count !== 1">s</span>
            </div>
          </div>
          <hr />
          <div class="row">
            <h6 class="col-6">
              Features
            </h6>
            <div class="col-6 text-right">
              <span v-for="feature in enabled_features">
                {{ feature }}<br/>
              </span>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div class="col-1"></div>
    </div>
    <footer class="building-footer">
      <div class="row">
        <div class="col-12 col-md-8 col-lg-9 col-xl-10">
          <p class="mt-1 mb-1 mb-md-0" v-if="!ready && !building_live">{{ ready_message }}</p>
        </div>
        <div class="col-12 col-md-4 col-lg-3 col-xl-2">

          <button :disabled="!ready && !building_live" @click="launchButton" class="btn btn-primary btn-block">{{ buttonText }}</button>
        </div>
      </div>
    </footer>
    <div aria-hidden="true" aria-labelledby="goLiveLabel" class="modal fade" id="goLive" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="goLiveLabel">Launch Building</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-8">
                <h5>Are you sure?</h5>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Cancel</button>
            <button :disabled="disabled" @click="launchBuilding" class="btn btn-primary" type="button">Launch Building</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../../../api.js';
    import Navigation from '../../../components/BuildingNav.vue';

    export default {
        components: {
            Navigation,
        },
        data() {
            return {
                building: {
                    building_name: '',
                    portfolio: {
                        business_name: '',
                    },
                    unit_count: 0,
                    spaces_count: 0,
                    address_1: '',
                    address_2: '',
                    city_name: '',
                    state: '',
                    zip: '',
                },
                enabled_features: [],
                building_live: false,
                disabled: false,
                ready: false,
                ready_message: '',
            };
        },
        computed: {
            buttonText() {
                if (this.building_live) {
                    return 'Launch 🚀';
                }
                return 'Launch Building';
            },
            today() {
                let d = new Date();
                let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                return months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
            },
            day() {
                let d = new Date();
                let i = d.getDate();
                let j = i % 10;
                let k = i % 100;
                if (j === 1 && k !== 11) return i + 'st';
                if (j === 2 && k !== 12) return i + 'nd';
                if (j === 3 && k !== 13) return i + 'rd';
                return i + 'th';
            },
        },
        beforeMount() {
            const self = this;
            $('.loading').css('display', 'block');
            if (self.$route.params.building_id === 'undefined') {
                self.$router.go(-1);
                return;
            }

            api.getBuilding(self.$route.params.building_id)
                .then((response) => {
                    self.building = response.building;
                    if (self.building.saas_building_checklist === null) {
                        self.handleError('This building does not have a plan configured!');
                        $('.content').css('display', 'block');
                        $('.content').animateCss('fadeIn');
                        return;
                    }

                    if (self.building.saas_building_checklist.next_state === 'complete') {
                        self.building_live = true;
                    }

                    let has_payments = false;
                    let enabled_features = [];

                    self.building.new_features.forEach(function (feature) {
                        if (feature.base_feature.title === 'Payments') {
                            has_payments = true;
                        }
                        enabled_features.push(feature.base_feature.title);
                    });

                    self.enabled_features = enabled_features;
                    self.ready = true;

                    $('.content').css('display', 'block');
                    $('.content').animateCss('fadeIn');
                })
                .catch((error) => {
                    self.handleError(error);
                });
        },
        methods: {
            launchButton() {
                if (this.building_live) {
                    this.$router.push({name: 'portfolio', params: {'id': this.$route.params.id}});
                } else {
                    $('#goLive').modal('show');
                }
            },
            launchBuilding() {
                const self = this;

                if (self.disabled) return;

                self.disabled = true;
                api.postBuildingLive(self.$route.params.building_id)
                    .then((response) => {
                        window.alert(self.building.building_name + ' is live!');
                        $('#goLive').modal('hide');
                        self.$router.push({name: 'portfolio', params: {'id': self.$route.params.id}});
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
        },
    };
</script>
