<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span>{{ group.name }}</span>
            </div>
            <div class="col-auto">
              <span class="badge badge-primary" v-if="group.group_type === 'tenant'">Resident</span>
              <span class="badge badge-primary" v-if="group.group_type === 'admin'">Admin</span>
            </div>
            <div class="col-auto text-right">
              {{ doorCount }} Door<span v-if="doorCount !== 1">s</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    export default {
        name: "BrivoGroup",
        props: {
            admins: Array,
            devices: Array,
            group: Object,
            schedules: Array,
            tenants: Array
        },
        computed: {
            doorCount()
            {
                if (this.group.permissions === undefined) {
                    return 0;
                }
                return this.group.permissions.length;
            }
        }
    }
</script>