<template>
  <div :id="'superadmin'+super_admin.id" aria-hidden="true" aria-labelledby="editSuperAdminLabel" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editSuperAdminLabel">Update Homebase Support</h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="putSuperAdmin">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label :for="'first_name'+super_admin.id">First Name</label>
                  <input :class="{'is-invalid':$v.form.first_name.$anyError}" :id="'first_name'+super_admin.id" class="form-control" type="text" v-model.trim="$v.form.first_name.$model">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label :for="'last_name'+super_admin.id">Last Name</label>
                  <input :class="{'is-invalid':$v.form.last_name.$anyError}" :id="'last_name'+super_admin.id" class="form-control" type="text" v-model.trim="$v.form.last_name.$model">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label :for="'email'+super_admin.id">Email</label>
                  <input :class="{'is-invalid':$v.form.email.$anyError}" :id="'email'+super_admin.id" class="form-control" readonly type="email" v-model.trim="$v.form.email.$model">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label :for="'phone_number'+super_admin.id">Phone Number</label>
                  <input :class="{'is-invalid':$v.form.phone_number.$anyError}" :id="'phone_number'+super_admin.id" class="form-control" type="text" v-model.trim="$v.form.phone_number.$model">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label :for="'job_title'+super_admin.id">Job Title</label>
                  <input :class="{'is-invalid':$v.form.job_title.$anyError}" :id="'job_title'+super_admin.id" class="form-control" type="text" v-model.trim="$v.form.job_title.$model">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group mb-0">
                  <input :id="'first'+super_admin.id" class="form-control d-inline-block mr-2" type="checkbox" v-model="form.first">
                  <label :for="'first'+super_admin.id">Primary Homebase Support</label>
                </div>
              </div>
            </div>
            <button class="d-none" type="submit"></button>
          </form>
        </div>
        <div class="modal-footer d-block">
          <div class="row">
            <div class="col-12 col-md-6">
              <button :disabled="disabled" @click="deleteSuperAdmin" class="btn btn-danger" type="button">Demote to building staff</button>
            </div>
            <div class="col-12 col-md-6 text-right">
              <button :disabled="disabled || $v.$invalid" @click="putSuperAdmin" class="btn btn-primary" type="button">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../../api.js';
    import {email, maxLength, numeric, required} from 'vuelidate/lib/validators';

    export default {
        name: "EditSuperAdmin",
        props: {
            super_admin: Object
        },
        data() {
            return {
                disabled: false,
                form: {
                    id: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: '',
                    job_title: '',
                    first: false
                }
            }
        },
        validations: {
            form: {
                first_name: {
                    maxLength: maxLength(255),
                    required
                },
                last_name: {
                    maxLength: maxLength(255),
                    required
                },
                email: {
                    maxLength: maxLength(255),
                    email,
                    required
                },
                phone_number: {
                    maxLength: maxLength(255),
                    numeric,
                    required
                },
                job_title: {
                    maxLength: maxLength(255),
                }
            }
        },
        watch: {
            super_admin: function () {
                this.setFormData();
            }
        },
        mounted() {
            this.setFormData();
        },
        methods: {
            setFormData() {
                this.form.id = this.super_admin.admin.id;
                this.form.first_name = this.super_admin.admin.first_name;
                this.form.last_name = this.super_admin.admin.last_name;
                this.form.email = this.super_admin.admin.email;
                this.form.phone_number = this.super_admin.admin.phone_number;
                this.form.job_title = this.super_admin.admin.job_title;
                this.form.first = this.super_admin.first;
            },
            putSuperAdmin() {
                const self = this;
                if (self.disabled || self.$v.$invalid) return;
                self.disabled = true;

                api.putAdmin(self.form)
                    .then((response) => {
                        self.disabled = false;
                        $('#superadmin' + self.super_admin.id).modal('hide');
                        self.admin = response.admin;
                        self.$emit('updated');
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
            deleteSuperAdmin() {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                if (confirm('Are you sure you want to remove super admin permissions for ' + self.super_admin.admin.first_name + ' ' + self.super_admin.admin.last_name + '?')) {
                    api.deleteSuperAdmin(self.form)
                        .then((response) => {
                            self.disabled = false;
                            $('#superadmin' + self.super_admin.id).modal('hide');
                            self.$emit('updated');
                        })
                        .catch((error) => {
                            self.disabled = false;
                            self.handleError(error);
                        });
                } else {
                    self.disabled = false;
                }
            },
        }
    }
</script>
