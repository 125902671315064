<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h2 class="confirm-header">Are you sure?</h2>
        <p>This key will be permanently removed from the Homebase system (*will quit working upon next sync)</p>
      </div>
    </div>
    <hr/>
    <div class="row justify-content-between">
      <div class="col-6 col-md-4">
        <button
          class="btn btn-md btn-block btn-outline-primary"
          @click="cancelDelete"
        >
          Cancel
        </button>
      </div>
      <div class="col-6 col-md-4">
        <button
          type="submit"
          class="btn btn-md btn-block btn-danger"
          @click="deleteFobs"
        >
          Yes, delete <strong>{{ fobCount }}</strong> Key<span v-if="fobCount !== 1">s</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../api.js';
import {numeric, required} from 'vuelidate/lib/validators';

export default {
  name: 'ConfirmFob',
  data() {
    return {
      form: {
        start_number: '',
        end_number: '',
        facility_code: '',
      },
    };
  },
  validations: {
    form: {
      start_number: {
        numeric,
        required,
      },
      end_number: {
        numeric,
        required,
      },
      facility_code: {
        numeric,
        required,
      },
    },
  },
  computed: {
    fobCount() {
      const formData = this.$store.getters['deleteKeyFobs/deleteFobForm'];
      return (formData.end_number - formData.start_number) + 1;
    }
  },
  mounted() {
    const self = this;
    self.search = '';
  },
  methods: {
    async cancelDelete() {
      await this.$store.dispatch('deleteKeyFobs/clearFormData');
      await this.$store.dispatch('modal/close', {modalId: 'confirm'});
    },
    deleteFobs() {
      const formData = this.$store.getters['deleteKeyFobs/deleteFobForm'];
      api.deleteFobs(formData.start_number, formData.end_number, formData.facility_code, this.$route.params.id)
          .then((response) => {
            this.handleSuccess('Success - batch delete complete.');
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.$store.dispatch('modal/close', {modalId: 'confirm'})
          });
    },
  },
};
</script>

<style scoped>

</style>