<template>
  <div class="container content">
    <div class="container search-box">
      <div class="row d-flex">
        <div class="col-12 col-sm">
          <h3>{{ portfolio.business_name }}</h3>
        </div>
        <div class="col-12 col-sm-auto">
          <router-link :to="{ name: 'editportfolio', params: { id: $route.params.id }}" class="btn btn-outline-primary w-100">Edit Portfolio</router-link>
        </div>
        <div class="col-12 mt-2 col-sm-auto mt-sm-0" v-if="isNewButtonVisible">
          <button class="btn btn-primary w-100" v-on:click="newButton">{{ newButtonText }}</button>
        </div>
      </div>
      <ul class="nav nav-tabs mt-1" id="myTab" role="tablist">
        <li class="nav-item"><a aria-controls="buildings" aria-selected="true" class="nav-link active" data-toggle="tab" href="#buildings" id="buildings-tab" role="tab" v-on:click="setTab('buildings')">Buildings ({{ portfolio.buildings_count }})</a></li>
        <li class="nav-item"><a aria-controls="superadmins" aria-selected="false" class="nav-link" data-toggle="tab" href="#superadmins" id="super-admins-tab" role="tab" v-on:click="setTab('superadmins')">Homebase Support Accounts ({{ portfolio.super_admins_count }})</a></li>
        <li class="nav-item"><a aria-controls="allegionmanagement" aria-selected="false" class="nav-link" data-toggle="tab" href="#allegionmanagement" id="allegion-management-tab" role="tab" v-on:click="setTab('allegionmanagement')">Allegion Management</a></li>
        <li class="nav-item"><a aria-controls="brivomanagement" aria-selected="false" class="nav-link" data-toggle="tab" href="#brivomanagement" id="brivo-management-tab" role="tab" v-on:click="setTab('brivomanagement')">Brivo Management</a></li>
        <li class="nav-item"><a aria-controls="keymanagement" aria-selected="false" class="nav-link" data-toggle="tab" href="#keymanagement" id="key-management-tab" role="tab" v-on:click="setTab('keymanagement')">Key Management</a></li>
      </ul>
    </div>
    <div class="tab-content" id="overview-content">
      <div v-if="active_tab === 'buildings'" aria-labelledby="buildings-tab" class="tab-pane fade show active" id="buildings" role="tabpanel">
        <div class="row justify-content-center" v-if="portfolio.buildings_count === 0">
          <div class="col-10 text-center">
            <h2>👻</h2>
            <p>No current buildings. This portfolio is a ghost town.</p>
            <router-link :to="'/portfolios/' + $route.params.id + '/building/create'" class="btn btn-primary">New Building</router-link>
          </div>
        </div>
        <Building :building="building" :key="building.id" v-for="building in portfolio.buildings" />
      </div>
      <div v-if="active_tab === 'superadmins'" aria-labelledby="super-admins-tab" class="tab-pane fade pt-sm-4 pt-md-0" id="superadmins" role="tabpanel">
        <CreateSuperAdmin :existing_admins="existing_admins" @updated="getPortfolio" />
        <div class="row">
          <div :key="super_admin.id" class="col-12" v-for="super_admin in portfolio.super_admins">
            <SuperAdmin :super_admin="super_admin" @updated="getPortfolio" />
          </div>
        </div>
      </div>
      <div v-if="active_tab === 'allegionmanagement'" aria-labelledby="allegion-management-tab" class="tab-pane fade" id="allegionmanagement" role="tabpanel">
        <AllegionTab :allegion="allegion" :key="portfolio.id" :portfolio="portfolio" />
      </div>
      <div v-if="active_tab === 'brivomanagement'" aria-labelledby="brivo-management-tab" class="tab-pane fade pt-sm-4 pt-md-0" id="brivomanagement" role="tabpanel">
        <BrivoTab :brivo="brivo" :key="portfolio.id" :portfolio="portfolio" @updated="getPortfolio" />
      </div>
      <div v-if="active_tab === 'keymanagement'" aria-labelledby="brivo-management-tab" class="tab-pane fade pt-sm-4 pt-md-0" id="keymanagement" role="tabpanel">
        <KeyTab :allegion="allegion" :brivo="brivo" :fob_types="fob_types" :portfolio="portfolio" @updated="getPortfolio" />
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../../api.js';
    import Building from '../../components/Building.vue';
    import CreateSuperAdmin from '../../components/modals/CreateSuperAdmin.vue';
    import SuperAdmin from '../../components/SuperAdmin.vue';
    import AllegionTab from '../../components/AllegionTab.vue';
    import BrivoTab from '../../components/BrivoTab.vue';
    import KeyTab from '../../components/KeyTab.vue';

    export default {
        components: {
            AllegionTab,
            BrivoTab,
            Building,
            CreateSuperAdmin,
            KeyTab,
            SuperAdmin,
        },
        data() {
            return {
                active_tab: 'buildings',
                admins: [],
                allegion: null,
                brivo: null,
                disabled: false,
                fobs: null,
                fob_types: [],
                existing_admins: [],
                portfolio: {
                    business_name: '',
                    buildings: [],
                    buildings_count: 0,
                    super_admins_count: 0,
                    super_admins: [],
                },
            };
        },
        computed: {
            newButtonText() {
                switch (this.active_tab) {
                    case 'buildings':
                        return 'New Building';
                    case 'superadmins':
                        return 'New Homebase Support Account';
                    case 'brivomanagement':
                        return 'Connect Brivo Account';
                }
            },
            isNewButtonVisible() {
                return !(this.active_tab === 'allegionmanagement' || this.active_tab === 'keymanagement');
            },
        },
        beforeMount() {
            const self = this;
            self.getPortfolio();
        },
        methods: {
            setTab(tab) {
                this.active_tab = tab;
            },
            newButton() {
                $('.modal').modal('hide');
                if (this.active_tab === 'buildings') {
                    this.$router.push({name: 'createbuilding'});
                } else if (this.active_tab === 'superadmins') {
                    $('#createSuperAdmin').modal('show');
                } else if (this.active_tab === 'brivomanagement') {
                    $('#connectBrivo').modal('show');
                }
            },
            getPortfolio() {
                const self = this;
                api.getPortfolio(self.$route.params.id)
                    .then((response) => {
                        // self.allegion = response.allegion;
                        // self.brivo = response.brivo;
                        self.fobs = response.fobs;
                        self.fob_types = response.fob_types;
                        self.portfolio = response.portfolio;

                        if (response.portfolio_admins.length === null) {
                            self.existing_admins = [];
                        } else {
                            self.existing_admins = response.portfolio_admins;
                        }

                        if ($('.content').css('display') === 'none') {
                            $('.content').css('display', 'block');
                            $('.content').animateCss('fadeIn');
                        }
                    })
                    .catch((error) => {
                        self.handleError(error);
                    });
            },
        },
    };
</script>
