import { render, staticRenderFns } from "./BrivoGroup.vue?vue&type=template&id=7599e570&"
import script from "./BrivoGroup.vue?vue&type=script&lang=js&"
export * from "./BrivoGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports