export default {
    namespaced: true,
    state: {
        unassignFobForm: {},
    },
    getters: {
        unassignFobForm: (state) => state.unassignFobForm,
    },
    mutations: {
        SET_FORM_DATA: (state, formData) => {
            state.unassignFobForm = {...formData};
        },
        CLEAR_FORM_DATA: (state) => {
            state.unassignFobForm = {};
        },
    },
    actions: {
        setFormData: ({commit}, formData) => {
            commit('SET_FORM_DATA', formData);
        },
        clearFormData: ({commit}) => commit('CLEAR_FORM_DATA'),
    },
};
