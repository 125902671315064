<template>
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h4>{{ building.building_name }}</h4>
        <p class="subtitle" v-if="building.portfolio !== null">
          <router-link :to="{name: 'portfolio', params: {'id': this.$route.params.id}}">{{ building.portfolio.business_name }}</router-link>
        </p>
        <p v-else class="d-block" style="margin-bottom: 39px;"></p>
      </div>
    </div>
    <div class="row justify-content-center mb-5">
      <div class="col-12 col-md-3">
        <Navigation />
      </div>
      <div class="col-md-7">
        <h4 class="font-weight-normal mb-0">Residents</h4>
        <small class="d-block mb-3">There are {{ building.tenants_count }} resident{{ building.tenants_count === 1 ? '' : 's' }} in this building.</small>
        <form>
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-search fa-fw"></i></span>
            </div>
            <input class="form-control" placeholder="Search for residents" type="text" v-model="search" />
          </div>
        </form>
        <div>
          <Resident :key="tenant.id" :tenant="tenant" :brivo="brivo" v-for="tenant in filteredList"></Resident>
        </div>
      </div>
    </div>
    <footer class="building-footer">
      <div class="row">
        <div class="col-6 col-md-4 col-lg-3 col-xl-2"></div>
        <div class="col-6 col-md-4 offset-md-4 col-lg-3 offset-lg-6 col-xl-2 offset-xl-8">
          <router-link :to="{name: 'buildinglaunch', params: {'id': $route.params.id, 'building_id': $route.params.building_id}}" class="btn btn-primary btn-block" tag="button">Next</router-link>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
    import api from '../../../api.js';
    import Navigation from '../../../components/BuildingNav.vue';
    import Resident from '../../../components/Resident.vue';
    import Unit from '../../../components/Unit.vue';

    export default {
        name: 'BuildingResidents',
        components: {
            Navigation,
            Resident,
            Unit,
        },
        data() {
            return {
                brivo: false,
                building: {
                    building_name: '',
                    portfolio: {
                        business_name: '',
                    }
                },
                search: ''
            };
        },
        beforeMount() {
            const self = this;
            if (self.$route.params.building_id === 'undefined') {
                self.$router.go(-1);
                return;
            }

            self.getBuilding();
        },
        computed: {
            filteredList() {
                if (this.building.tenants == null) return [];
                const re = new RegExp(this.search, 'i');

                return this.building.tenants.filter((tenant) =>
                        tenant.first_name.match(re) ||
                        tenant.last_name.match(re) ||
                        (tenant.first_name + ' ' + tenant.last_name).match(re)
                );
            },
        },
        methods: {
            getBuilding() {
                const self = this;
                api.getBuilding(self.$route.params.building_id)
                    .then((response) => {
                        self.building = response.building;

                        if (self.building.brivo_site !== null) {
                            self.brivo = true;
                        }

                        if ($('.content').css('display') !== 'block') {
                            $('.content').css('display', 'block');
                            $('.content').animateCss('fadeIn');
                        }
                    })
                    .catch((error) => {
                        self.handleError(error);
                    });
            },

        },
    };
</script>
