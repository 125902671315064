<template>
  <div class="col-12 text-right">
    <DropdownMenu
        :disabled="disabled"
        :menuItems="menuItems"
        label="Actions"
        @add-space="addSpaces"
        @download-existing="downloadExisting"
        @download-template="downloadTemplate"
    />
  </div>
</template>

<script>
import DropdownMenu from '../elements/DropdownMenu.vue';

export default {
  name: 'SpacesListItem',

  components: {
    DropdownMenu,
  },

  props: {
    building: Object,
  },

  data() {
    return {
      actionsDisabled: false,
      disabled: false,
      modalHiddenAddEdit: true,
      csvUrl: '',
      templateUrl: '',
      menuItems: [
        {
          key: 'manually-add-spaces',
          label: 'Manually add spaces',
          icon: 'plus-circle',
          event: {
            name: 'add-space',
            data: {
              name: 'addSpace',
            },
          },
        },
        {
          key: 'download-existing-space-list',
          label: 'Download existing space list',
          icon: 'download',
          event: {
            name: 'download-existing',
            data: {
              name: this.csvUrl,
            },
          },
        },
        {
          key: 'download-blank-template',
          label: 'Download blank csv template',
          icon: 'download',
          event: {
            name: 'download-template',
            data: {
              name: this.templateUrl,
            },
          },
        },
      ],
    };
  },

  methods: {
    downloadTemplate(eventData) {
      this.$emit('download-template', {
        building: this.building,
      });
    },
    addSpaces(eventData) {
      this.$emit('add-space', {
        building: this.building,
      });
    },
    downloadExisting(eventData) {
      this.$emit('download-existing', {building: this.building});
    },
  },
};
</script>
