<template>
  <div class="container content">
    <div class="row justify-content-center">
      <div class="col">
        <h4>{{ building.building_name }}</h4>
        <p
          v-if="building.portfolio !== null"
          class="subtitle">
          <router-link :to="{name: 'portfolio', params: {'id': this.$route.params.id}}">{{ building.portfolio.business_name }}</router-link>
        </p>
        <p
          v-else
          class="d-block"
          style="margin-bottom: 39px;"/>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-auto">
        <Navigation />
      </div>
      <div class="col">
        <form
          class="building-form"
          @submit.prevent="postBuildingConfiguration">
          <h4 class="font-weight-normal mt-3">Rent</h4>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="rent_due">What day of the month is rent due?</label>
                <select
                  id="rent_due"
                  :class="{'is-invalid':$v.form.rent_due.$anyError}"
                  v-model.trim="$v.form.rent_due.$model"
                  class="form-control">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                  <option>16</option>
                  <option>17</option>
                  <option>18</option>
                  <option>19</option>
                  <option>20</option>
                  <option>21</option>
                  <option>22</option>
                  <option>23</option>
                  <option>24</option>
                  <option>25</option>
                  <option>26</option>
                  <option>27</option>
                  <option>28</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="rent_late">What day of the month is rent late?</label>
                <select
                  id="rent_late"
                  :class="{'is-invalid':$v.form.rent_late.$anyError}"
                  v-model.trim="$v.form.rent_late.$model"
                  class="form-control">
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                  <option>16</option>
                  <option>17</option>
                  <option>18</option>
                  <option>19</option>
                  <option>20</option>
                  <option>21</option>
                  <option>22</option>
                  <option>23</option>
                  <option>24</option>
                  <option>25</option>
                  <option>26</option>
                  <option>27</option>
                  <option>28</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="prorated_rent">Is rent prorated?</label>
                <select
                  id="prorated_rent"
                  :class="{'is-invalid':$v.form.prorated_rent.$anyError}"
                  v-model.trim="$v.form.prorated_rent.$model"
                  class="form-control">
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
          </div>
          <h4 class="font-weight-normal mt-3">Late Fee</h4>
          <div class="form-group">
            <label for="late_fee_type_id">Structure</label>
            <select
              id="late_fee_type_id"
              :class="{'is-invalid':$v.form.late_fee_type_id.$anyError}"
              v-model.trim="$v.form.late_fee_type_id.$model"
              class="form-control">
              <option
                v-for="type in late_fee_types"
                :key="type.id"
                :value="type.id">{{ type.name }}</option>
            </select>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div
                v-if="form.late_fee_type_id === 1 || form.late_fee_type_id === 3"
                class="form-group">
                <label for="late_fee_amount">Fee</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input
                    id="late_fee_amount"
                    :class="{'is-invalid':$v.form.late_fee_amount.$anyError}"
                    v-model.trim="$v.form.late_fee_amount.$model"
                    class="form-control"
                    step=".01"
                    type="number">
                </div>
              </div>
              <div
                v-if="form.late_fee_type_id === 2"
                class="form-group">
                <label for="late_daily_fee_amount">Daily Fee</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input
                    id="late_daily_fee_amount"
                    :class="{'is-invalid':$v.form.late_daily_fee_amount.$anyError}"
                    v-model.trim="$v.form.late_daily_fee_amount.$model"
                    class="form-control"
                    step=".01"
                    type="number">
                </div>
              </div>
              <div
                v-if="form.late_fee_type_id === 4"
                class="form-group">
                <label for="late_fee_percent">Flat Percentage Fee</label>
                <div class="input-group">
                  <input
                    id="late_fee_percent"
                    :class="{'is-invalid':$v.form.late_fee_percent.$anyError}"
                    v-model.trim="$v.form.late_fee_percent.$model"
                    class="form-control"
                    step="1"
                    type="number">
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
              <div
                v-if="form.late_fee_type_id === 5"
                class="form-group">
                <label for="late_daily_percent">Daily Percentage Fee</label>
                <div class="input-group">
                  <input
                    id="late_daily_percent"
                    :class="{'is-invalid':$v.form.late_daily_percent.$anyError}"
                    v-model.trim="$v.form.late_daily_percent.$model"
                    class="form-control"
                    step="1"
                    type="number">
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div
                v-if="form.late_fee_type_id === 3"
                class="form-group">
                <label for="late_daily_fee_amount_2">Daily Fee</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                  </div>
                  <input
                    id="late_daily_fee_amount_2"
                    :class="{'is-invalid':$v.form.late_daily_fee_amount.$anyError}"
                    v-model.trim="$v.form.late_daily_fee_amount.$model"
                    class="form-control"
                    step=".01"
                    type="number">
                </div>
              </div>
            </div>
          </div>
          <div v-if="logo">
            <hr >
            <h4 class="font-weight-normal mt-3">Upload Building Logo</h4>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="logo">Building Logo - (375px x 85px | PNG file format)</label>
                  <input
                    id="logo"
                    ref="file"
                    type="file"
                    class="d-block"
                    accept="png"
                    @change="upload($event.target.files)">
                </div>
              </div>
              <div
                v-if="building.logo_link !== null"
                class="col-12">
                <img
                  :src="building.logo_link"
                  class="mb-3" >
              </div>
            </div>
          </div>
          <div v-if="internet">
            <hr >
            <h4 class="font-weight-normal mt-3">WiFi - Building Settings</h4>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="up_speed">Building - Up Speed</label>
                  <div class="input-group">
                    <input
                      id="up_speed"
                      :class="{'is-invalid':$v.form.up_speed.$anyError}"
                      v-model.trim="$v.form.up_speed.$model"
                      class="form-control"
                      step="1"
                      type="number">
                    <div class="input-group-append">
                      <span class="input-group-text">Mbps</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="down_speed">Building - Down Speed</label>
                  <div class="input-group">
                    <input
                      id="down_speed"
                      :class="{'is-invalid':$v.form.down_speed.$anyError}"
                      v-model.trim="$v.form.down_speed.$model"
                      class="form-control"
                      step="1"
                      type="number">
                    <div class="input-group-append">
                      <span class="input-group-text">Mbps</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="carrier">Carrier</label>
                  <input
                    id="carrier"
                    :class="{'is-invalid':$v.form.carrier.$anyError}"
                    v-model.trim="$v.form.carrier.$model"
                    class="form-control"
                    type="text">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="support_number">Support Phone Number</label>
                  <input
                    id="support_number"
                    :class="{'is-invalid':$v.form.support_number.$anyError}"
                    v-model.trim="$v.form.support_number.$model"
                    class="form-control"
                    type="text">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="account_number">Account Number</label>
                  <input
                    id="account_number"
                    :class="{'is-invalid':$v.form.account_number.$anyError}"
                    v-model.trim="$v.form.account_number.$model"
                    class="form-control"
                    type="text">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="contact_name">Contact - Full Name</label>
                  <input
                    id="contact_name"
                    :class="{'is-invalid':$v.form.contact_name.$anyError}"
                    v-model.trim="$v.form.contact_name.$model"
                    class="form-control"
                    type="text">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <input
                    id="credit_on_downgrade"
                    v-model.trim="form.credit_on_downgrade"
                    class="form-control d-inline-block"
                    style="vertical-align: top;"
                    onclick="return false"
                    type="checkbox">
                  <label for="credit_on_downgrade">Credit on Downgrade</label>
                </div>
              </div>
            </div>
            <h4 class="font-weight-normal mt-3">WiFi - Billing Address</h4>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="address_1">Address 1</label>
                  <input
                    id="address_1"
                    :class="{'is-invalid':$v.form.address_1.$anyError}"
                    v-model.trim="$v.form.address_1.$model"
                    class="form-control"
                    type="text">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="address_2">Address 2 (Optional)</label>
                  <input
                    id="address_2"
                    :class="{'is-invalid':$v.form.address_2.$anyError}"
                    v-model.trim="$v.form.address_2.$model"
                    class="form-control"
                    type="text">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="city">City</label>
                  <input
                    id="city"
                    :class="{'is-invalid':$v.form.city.$anyError}"
                    v-model.trim="$v.form.city.$model"
                    class="form-control"
                    type="text">
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="state">State</label>
                  <select
                    id="state"
                    :class="{'is-invalid':$v.form.state.$anyError}"
                    v-model.trim="$v.form.state.$model"
                    class="form-control">
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="postal_code">Zipcode</label>
                  <input
                    id="postal_code"
                    :class="{'is-invalid':$v.form.postal_code.$anyError}"
                    v-model.trim="$v.form.postal_code.$model"
                    class="form-control"
                    type="text">
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <footer class="building-footer">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col col-md-6 col-lg-3">
            <button
              :disabled="disabled || $v.$invalid"
              class="btn btn-primary btn-block"
              @click="postBuildingConfiguration">Save</button>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import api from '../../../api.js';
import Navigation from '../../../components/BuildingNav.vue';
import {decimal, integer, maxLength, maxValue, minValue, required} from 'vuelidate/lib/validators';

export default {
    name: 'Configuration',
    components: {
        Navigation,
    },
    data() {
        return {
            building: {
                building_config: [],
                building_name: '',
                conversations: [],
                new_features: [],
                internet_configuration: {
                    address: {},
                },
                portfolio: {
                    business_name: '',
                },
            },
            disabled: false,
            editable: false,
            internet: false,
            logo: false,
            form: {
                building_logo: '',
                rent_due: 1,
                rent_late: 1,
                prorated_rent: true,
                late_fee_type_id: 1,
                late_fee_amount: 0.00,
                late_daily_fee_amount: 0.00,
                late_fee_percent: 0,
                late_daily_percent: 0,
                up_speed: 0,
                down_speed: 0,
                carrier: '',
                support_number: '',
                account_number: '',
                contact_name: '',
                credit_on_downgrade: 1,
                address_1: '',
                address_2: '',
                city: '',
                state: 'MO',
                postal_code: '',
            },
            late_fee_types: [],
        };
    },
    validations: {
        form: {
            rent_due: {
                integer,
                required,
            },
            rent_late: {
                integer,
                required,
            },
            prorated_rent: {
                required,
            },
            late_fee_type_id: {
                integer,
                required,
            },
            late_fee_amount: {
                decimal,
                minValue: minValue(0),
                required,
            },
            late_daily_fee_amount: {
                decimal,
                minValue: minValue(0),
                required,
            },
            late_fee_percent: {
                maxValue: maxValue(100),
                minValue: minValue(0),
                required,
            },
            late_daily_percent: {
                maxValue: maxValue(100),
                minValue: minValue(0),
                required,
            },
            up_speed: {
                integer,
                minValue: minValue(0),
            },
            down_speed: {
                integer,
                minValue: minValue(0),
            },
            carrier: {
                maxLength: maxLength(255),
            },
            support_number: {
                maxLength: maxLength(255),
            },
            account_number: {
                maxLength: maxLength(255),
            },
            contact_name: {
                maxLength: maxLength(255),
            },
            address_1: {
                maxLength: maxLength(255),
            },
            address_2: {
                maxLength: maxLength(255),
            },
            city: {
                maxLength: maxLength(255),
            },
            state: {
                maxLength: maxLength(255),
            },
            postal_code: {
                maxLength: maxLength(255),
            },
        },
    },
    beforeMount() {
        const self = this;
        if (self.$route.params.building_id === 'undefined') {
            self.$router.go(-1);
            self.handleError('You must create a building first!');
            return;
        }

        api.getBuilding(self.$route.params.building_id)
            .then((response) => {
                self.building = response.building;

                if (self.building.new_features.some((e) => e.identifier === 'internet')) {
                    self.internet = true;
                }

                if (self.building.new_features.some((e) => e.identifier === 'building-logo')) {
                    self.logo = true;
                }

                if (self.building.saas_building_checklist === null || self.building.saas_building_checklist.building_configuration === 0) {
                    self.editable = true;
                } else {
                    self.resetForm();
                }

              if ($('.content').css('display') === 'none') {
                    $('.content').css('display', 'block');
                    $('.content').animateCss('fadeIn');
                }
            })
            .catch((error) => {
                self.handleError(error);
            });

        api.getLateFees()
            .then((response) => {
                self.late_fee_types = response.late_fee_types;
            })
            .catch((error) => {
                self.handleError(error);
            });
    },
    methods: {
        resetForm() {
            const self = this;
            self.form.rent_due = self.building.building_config.rent_due;
            self.form.rent_late = self.building.building_config.rent_late;
            self.form.prorated_rent = self.building.building_config.prorated_rent === 1;
            self.form.late_fee_type_id = self.building.late_fees[0].late_fee_type_id;
            self.form.late_fee_amount = self.building.late_fees[0].fee / 100;
            self.form.late_daily_fee_amount = self.building.late_fees[0].daily / 100;
            self.form.late_fee_percent = self.building.late_fees[0].fee_percent;
            self.form.late_daily_percent = self.building.late_fees[0].fee_percent;

            if (self.internet && self.building.internet_configuration !== null) {
                self.form.up_speed = self.building.internet_configuration.up_speed;
                self.form.down_speed = self.building.internet_configuration.down_speed;
                self.form.carrier = self.building.internet_configuration.carrier;
                self.form.support_number = self.building.internet_configuration.support_number;
                self.form.account_number = self.building.internet_configuration.account_number;
                self.form.contact_name = self.building.internet_configuration.contact_name;
                self.form.credit_on_downgrade = 1;

                if (self.building.internet_configuration.address !== null) {
                    self.form.address_1 = self.building.internet_configuration.address.address_1;
                    self.form.address_2 = self.building.internet_configuration.address.address_2;
                    self.form.city = self.building.internet_configuration.address.city;
                    self.form.state = self.building.internet_configuration.address.state;
                    self.form.postal_code = self.building.internet_configuration.address.postal_code;
                }
            }
        },
        upload(fileList) {
            this.form.building_logo = fileList[0];

            api.postBuildingLogo(this.building.id, this.form.building_logo)
                .then((response) => {
                    this.building.logo_link = response.building.logo_link;
                    this.handleSuccess('The building logo was uploaded successfully.');
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        postBuildingConfiguration() {
            const self = this;

            if (self.disabled) return;
            self.disabled = true;
            const configuration = {
              ...self.form,
              late_fee_amount: this.form.late_fee_amount * 100,
              late_daily_fee_amount: this.form.late_daily_fee_amount * 100
            }

            if (self.internet) {
                if (self.building.internet_configuration === null) {
                    api.postBuildingInternetConfiguration(configuration, self.$route.params.building_id)
                        .catch((error) => {
                            self.disabled = false;
                            self.handleError(error);
                        });
                } else {
                    api.putBuildingInternetConfiguration(configuration, self.$route.params.building_id)
                        .catch((error) => {
                            self.disabled = false;
                            self.handleError(error);
                        });
                }
            }

            configuration.id = self.$route.params.building_id;
            if (self.editable) {
                api.postBuildingConfiguration(configuration)
                    .then((response) => {
                        self.disabled = false;
                        if (self.internet) {
                            self.$router.push({name: 'buildingwifi', params: {'id': self.$route.params.id, 'building_id': self.$route.params.building_id}});
                        } else {
                            self.$router.push({name: 'buildingadmins', params: {'id': self.$route.params.id, 'building_id': self.$route.params.building_id}});
                        }
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            } else {
                api.putBuildingConfiguration(configuration)
                    .then((response) => {
                         self.disabled = false;
                         if (self.internet) {
                             self.$router.push({name: 'buildingwifi', params: {'id': self.$route.params.id, 'building_id': self.$route.params.building_id}});
                         } else {
                             self.$router.push({name: 'buildingadmins', params: {'id': self.$route.params.id, 'building_id': self.$route.params.building_id}});
                         }
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            }
        },
    },
};
</script>
