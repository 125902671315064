<template>
  <div class="dropdown-menu-wrapper">
    <button
      type="button"
      class="btn btn-sm btn-outline-primary"
      data-toggle="dropdown"
      aria-haspopup="false"
      aria-expanded="false"
      :disabled="disabled"
    >
      <label class="form-check-label">
        {{ label }}
        <i class="fas fa-caret-down"></i>
      </label>
    </button>

    <!-- MENU ITEMS -->
    <div class="dropdown-menu dropdown-menu-right">
      <button
        v-for="menuItem in menuItems"
        :key="menuItem.key"
        class="dropdown-item ellipsis-menu"
        :class="`${menuItem.color !== undefined ? 'text-' + menuItem.color : ''}`"
        type="button"
        @click="emitEvent(menuItem.event)"
      >
        <template v-if="menuItem.icon">
          <i :class="`menu-item-icon far fa-${menuItem.icon}`"></i>
        </template>
        <slot name="item" :item="menuItem">
          {{ menuItem.label }}
        </slot>
      </button>
    </div>
    <!-- END MENU ITEMS -->
  </div>
</template>

<style scoped lang="scss">
  .dropdown-menu-wrapper label {
    font-weight: 400;
  }

  .form-check-label {
    cursor: pointer;
  }

  .dropdown-item + .dropdown-item {
    margin-top: 1px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 0;
      left: 6px;
      right: 6px;
      top: -1px;
      border-top: 1px solid #e9ecef;
    }
  }

  .dropdown-menu {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

    .dropdown-item {
      &.text-homebase-blue {
        color: #3596D9;
      }
    }
  }

  .menu-item-icon {
    padding-left: 2px;
    padding-right: 6px;
  }

  .text-red {
    color: #BA4C5B;
  }
</style>

<script>
/**
 * @prop menuItems array
 * menuItems > item = {
 *    key: string;
 *    label: string;
 *    color?: string;
 *    icon?: string;
 *    hidden?: boolean;
 *    event: {
 *      name: string;
 *      data?: any;
 *    };
 * }
 */
export default {
  name: 'DropdownMenu',
  props: {
    menuItems: Array,
    label: { type: String, default: '' },
    disabled: Boolean,
  },
  data() {
    return {
      isExpanded: false,
      isDisabled: false,
      selectedItem: this.selected,
    };
  },

  //
  // BEHAVIOR
  // ========================================

  methods: {
    getStyle({ color }) {
      return {
        color: color !== undefined ? color : '#212529',
      };
    },
    emitEvent(event) {
      this.$emit(event.name, event.data);
    },
  },
}
</script>