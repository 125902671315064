<template>
  <div
    :class="{'show': isExpanded}"
    class="dropdown-wrapper my-2">
    <button
      :id="id"
      :aria-expanded="isExpanded"
      :disabled="disabled"
      type="button"
      class="btn btn-light-alt btn-block dropdown-toggle text-justify"
      aria-haspopup="true"
      @click.prevent="toggleDropDownMenu"
    >
      <div
        :class="selectedClass"
        class="d-inline-block dropdown-label font-weight-normal">
        <slot
          :selected="selectedItem"
          name="selected" >
          {{ selectedItem ? selectedItem.text: '' }}
        </slot>
      </div>
    </button>
    <!-- DROPDOWN MENU -->
    <div
      :class="{'show': isExpanded}"
      class="dropdown-menu mt-0 pt-0 mb-0 pb-0 pl-2 pr-2"
    >
      <a
        v-for="(item, index) in filterItems"
        :key="index"
        class="dropdown-item p-0 p-1 pl-0 pr-0 pt-2"
        @click="selectItem(item)">
        <slot
          :item="item"
          name="item">
          <label v-if="item">{{ item.text }}</label>
        </slot>
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .text-grey-light {
    color: #888888;
  }

  .connect {
    width:100%;
    border-radius: 0 !important;
  }

  .dropdown-wrapper {
    position: relative;
    width: 100%;

    button {
      width: 100%;
      border: 1px solid #DDDDDD !important;
    }
    .dropdown-label {
      width: 98%;
    }

    .dropdown-menu {
      width: 100%;
      overflow-y: auto;
      max-height: 500px;
      border-left: 1px solid #DDDDDD !important;
      border-right: 1px solid #DDDDDD !important;
      border-bottom: 1px solid #DDDDDD !important;
    }

    .dropdown-item {
      border-top: 1px solid #DDDDDD !important;
    }
  }
</style>

<script>

const defaultDropdown = [{
  id: 'default',
  text: 'Select',
}];

  export default {
    name: 'Dropdown',
    props: ['id', 'items', 'selected', 'disabled'],

    data() {
      return {
        isExpanded: false,
        isDisabled: false,
        selectedItem: this.selected,
        label: '',
      };
    },

    computed: {
      selectedClass() {
        if (this.selectedItem && this.selectedItem.value === '') {
          return 'text-grey-light';
        }

        return '';
      },

      filterItems() {
          if (this.items && this.items.length > 0) {
            return this.items.filter((item) => item && item.value !== '');
          }

          return [];
       },
    },

    watch: {
      selectedItem(val) {
        this.$emit('update:selected', val);
      },
      selected(val) {
        this.selectedItem = val;
      },
    },

    beforeMount() {
      const dropdown = $(`#${this.id}`);

      $('body').on('click', (e) => {
        if (!dropdown.is(e.target) &&
             dropdown.has(e.target).length === 0 &&
            $('.show').has(e.target).length === 0) {
          this.isExpanded = false;
        }
      });
    },

    methods: {
      toggleDropDownMenu() {
        const self = this;
        self.isExpanded = !this.isExpanded;
      },
      selectItem(value) {
        this.toggleDropDownMenu();
        this.selectedItem = value;
      },
    },
};
</script>
