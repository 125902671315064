<template>
  <div class="content">
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h4>{{ building.building_name }}</h4>
        <p class="subtitle" v-if="building.portfolio !== null">
          <router-link :to="{name: 'portfolio', params: {'id': this.$route.params.id}}">{{ building.portfolio.business_name }}</router-link>
        </p>
        <p v-else class="d-block" style="margin-bottom: 39px;"></p>
      </div>
    </div>
    <div class="row justify-content-center" style="padding-bottom: 55px">
      <div class="col-12 col-md-3">
        <Navigation />
      </div>
      <div class="col-md-7" style="margin-bottom: 55px;">
        <h4 class="font-weight-normal">Edit Spaces</h4>
        <div class="row justify-content-center" v-if="listSpaces.length === 0">
          <div class="col-10 text-center">
            <h1>🏗️</h1>
            <h5>Add a space to get started.</h5>
            <small class="d-block">Or skip for now.</small>
          </div>
        </div>
        <!-- BEGIN UPLOAD CSV -->
        <div class="row justify-content-center">
          <div class="card text-center col-10 py-3 my-3 upload">
            <div class="card-body">
              <div class="mb-3">
                <i class="fal fa-upload p-4 rounded-circle" style="font-size: 26px;"></i>
              </div>
              <h5 class="card-title">Upload a file to add/update spaces</h5>
              <p class="card-text">Only .csv file type supported</p>
              <input
                  ref="fileInput"
                  id="fileInput"
                  type="file"
                  name="file"
                  style="display: none;"
                  accept=".csv, text/csv, application/csv, text/comma-separated-values"
                  @change="fileUpdated()"
              />
              <button class="btn btn-primary px-5 py-3" @click.prevent="$refs.fileInput.click()">
                Upload
              </button>
            </div>
          </div>
        </div>
        <!-- END UPLOAD CSV -->

        <!-- BEGIN AMENITY SPACE ACTION MENU -->
        <div class="row mb-3">
          <SpacesListItem
              @add-space="addSpaces"
              @download-existing="downloadExisting"
              @download-template="downloadTemplate"
          />
        </div>
        <!-- END AMENITY SPACE ACTION MENU -->

        <!-- BEGIN AMENITY SPACE LIST ITEM -->
        <AmenitySpace
            :tlj_rooms="tlj_rooms"
            :tlj_building_enabled="tljBuildingEnabled"
            v-for="space in listSpaces"
            :key="space.id"
            :space="space"
            @updated="getBuildingAmeninitySpaces"
        />
        <!-- END AMENITY SPACE LIST ITEM -->
      </div>
    </div>
    <footer class="building-footer">
      <div class="row">
        <div class="col-6 col-md-4 col-lg-3 col-xl-2"></div>
        <div class="col-6 col-md-4 offset-md-4 col-lg-3 offset-lg-6 col-xl-2 offset-xl-8">
          <router-link :to="{name: 'buildingwifi', params: {'id': $route.params.id, 'building_id': $route.params.building_id}}" class="btn btn-primary btn-block" tag="button">Next</router-link>
        </div>
      </div>
    </footer>
    <div aria-hidden="true" aria-labelledby="addSpaceLabel" class="modal fade" id="addSpace" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addSpaceLabel">Add Space</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="postSpace">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Name</label>
                    <input class="form-control" required type="text" v-model.trim="form.space_name">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Floor</label>
                    <input class="form-control" required type="text" v-model.trim="form.floor">
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Room permission</label>
                    <p>Unrestricted - By default, both residents and staff can access this space. Used for public spaces.</p>
                    <p>Restricted - By default, only staff can access this space. Used for private spaces.</p>
                    <input class="form-control" required type="hidden" v-model.trim="form.restricted">
                  </div>
                </div>
                <div class="col-12 mb-1">
                  <div class="btn-group" style="width: 100%">
                    <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="width: 100%">
                      {{form.restricted === true ? 'Restricted':'Unrestricted'}}
                    </button>
                    <div class="dropdown-menu" style="width: 100%">
                      <a class="dropdown-item" v-on:click="updatePermission(true)">Restricted</a>
                      <a class="dropdown-item" v-on:click="updatePermission(false)">Unrestricted</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group" style="display: flex; align-items: flex-start;">
                  <input
                    id="device_type_restrictions"
                    v-model.trim="form.device_type_restrictions"
                    class="form-control"
                    style="vertical-align: middle;"
                    type="checkbox"
                  >
                  <label for="device_type_restrictions" style="margin-left: 8px; font-weight: normal;">
                    Lock only device control for residents <b>Highly recommended</b>
                    Unchecking this will grant full device permissions to residents added to this space. Only uncheck this for shared private spaces such as student housing suites.
                  </label>
                </div>
              </div>
              <div class="form-group">
                <label>Description</label>
                <textarea class="form-control" maxlength="255" v-model.trim="form.description" />
              </div>
              <div v-if="tljBuildingEnabled">
                <div class="row" >
                  <div class="col-12">
                    <div class="form-group">
                      <label>TLJ Room ID</label>
                      <input class="form-control" type="text" v-model.trim="form.tlj_room_name">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>TLJ Area ID</label>
                      <input class="form-control" type="text" v-model.trim="form.tlj_area_id">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>TLJ Floor ID</label>
                      <input class="form-control" type="text" v-model.trim="form.tlj_floor_id">
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div v-if="internet">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Device WiFi - SSID</label>
                      <input class="form-control" type="text" v-model.trim="form.device_ssid">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Device WiFi - Password</label>
                      <input class="form-control" type="text" v-model.trim="form.device_password">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Unit WiFi - SSID</label>
                      <input class="form-control" type="text" v-model.trim="form.unit_ssid">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Unit WiFi - Password</label>
                      <input class="form-control" type="text" v-model.trim="form.unit_password">
                    </div>
                  </div>
                </div>
              </div> -->
              <button class="d-none" type="submit"></button>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Close</button>
            <button :disabled="disabled" @click="postSpace" class="btn btn-primary" type="button">Save changes</button>
          </div>
        </div>
      </div>
    </div>

    <!-- BEGIN ADD SPACES -->
    <div aria-hidden="true" aria-labelledby="addSpacesLabel" class="modal fade" id="addSpaces" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addSpacesLabel">Add Spaces</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="postSpace">
              <table class="table table-hover d-block overflow-auto">
                <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" nowrap="nowrap">Name</th>
                  <th scope="col">Floor</th>
                  <th scope="col">Type</th>
                  <th scope="col">Description</th>
                  <th scope="col">Device Type Restrictions</th>
                  <th scope="col">TLJ Room ID</th>
                  <th scope="col">TLJ Area ID</th>
                  <th scope="col">TLJ Floor ID</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(space, index) in formArray" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ space.space_name }}</td>
                  <td>{{ space.floor }}</td>
                  <td>{{ space.access_type }}</td>
                  <td>{{ space.description }}</td>
                  <td v-if="space.device_type_restrictions">
                    enabled
                  </td>
                  <td v-else>
                    disabled
                  </td>
                  <td>{{ space.tlj_room_name }}</td>
                  <td>{{ space.tlj_area_id }}</td>
                  <td>{{ space.tlj_floor_id }}</td>
                </tr>
                </tbody>
              </table>
              <button class="d-none" type="submit"></button>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Close</button>
            <button :disabled="disabled" @click="putSpaces" class="btn btn-primary" type="button">Save {{ formArray.length }} spaces</button>
          </div>
        </div>
      </div>
    </div>
    <!-- END ADD SPACES -->
  </div>
</template>
<style scoped lang="scss">
  .upload {
    border: 2px dashed rgba(53, 150, 217, 0.4) !important;
  }
  .fa-upload {
    background-color: #F0F5FA;
  }

  .btn {
    cursor: pointer;
  }
  .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      float: right;
      margin-top: 10px;
  }
  .dropdown-toggle{
    border: 1px solid #979797;
  }
  .dropdown-item {
    border-bottom: 1px solid #979797;
    border-left: 1px solid #979797;
    border-right: 1px solid #979797;
  }
</style>
<script>
    import api from '../../../api.js';
    import Navigation from '../../../components/BuildingNav.vue';
    import AmenitySpace from '../../../components/AmenitySpace.vue';
    import SpacesListItem from '../../../components/partials/SpacesListItem.vue';
    import Papa from 'papaparse';

    export default {
        name: 'AmenitySpaces',
        components: {
            Navigation,
            AmenitySpace,
            SpacesListItem,
        },
        data() {
            return {
                tlj_rooms: [],
                building: {
                    building_name: '',
                    new_features: [],
                    portfolio: {
                        business_name: '',
                    },
                    units: [],
                    spaces: []
                },
                disabled: false,
                form: {
                    floor: '',
                    space_name: '',
                    description: 'No description',
                    restricted: true,
                    device_type_restrictions: true,
                },
                formArray: [],
                internet: false,
                dataTemplate: [{
                  'Id': '',
                  'Space name': '',
                  'Floor': '',
                  'Access type': '',
                  'Description': '',
                  'Device Type Restrictions': null,
                  'TLJ Room ID': null,
                  'TLJ Area ID': null,
                  'TLJ Floor ID': null,
                }],
            };
        },
        computed: {
            listSpaces() {
                if (!this.building.spaces) return [];
                return this.building.spaces;
            },
            fileName() {
              if (!this.building) return 'Building Spaces';
              return this.building.building_name + ' - Building Spaces.csv';
            },
            restrictedValue() {
              const self = this;
              if (self.form.restricted === true) {
                return 'Restricted';
              }
              return 'Unrestricted';
            },
            tljBuildingEnabled() {
              return this.building?.new_features.some((e) => e.identifier === 'tlj-locks');
            },
        },
        async beforeMount() {
            const self = this;
            if (self.$route.params.building_id === 'undefined') {
                self.$router.go(-1);
                return;
            }
            self.getBuilding()
                .then(() => self.getBuildingAmeninitySpaces());
        },
        mounted() {
          const self = this;
          if (self.listSpaces.legnth > 0) {
            self.listSpaces.forEach(function(space){
              $('#space'+ space.id).on("hide.bs.modal", function(){
                self.getBuildingAmeninitySpaces();
              });
            })
          }
        },
        methods: {
            getBuilding() {
              const self = this;
              return new Promise((resolve) => {
                api.getBuilding(self.$route.params.building_id)
                    .then(({ building }) => {
                      self.building.building_name = building.building_name;
                      self.building.new_features = building.new_features;
                      self.building.portfolio.business_name = building.portfolio.business_name;
                      const tljRooms =  building?.tlj_building?.tlj_rooms;
                      self.tlj_rooms = tljRooms !== null ? tljRooms : null;
                      resolve(true);
                    })
                    .catch((error) => {
                      self.handleError(error);
                    });
              });
            },
            getBuildingAmeninitySpaces() {
                const self = this;
                api.getBuildingAmeninitySpaces(self.$route.params.building_id)
                    .then((response) => {
                      self.building.spaces = [];
                      this.$forceUpdate();
                      self.building.spaces = response.spaces;
                      if ($('.content').css('display') !== 'block') {
                        $('.content').css('display', 'block');
                          $('.content').animateCss('fadeIn');
                      }
                      this.$forceUpdate();
                    })
                    .catch((error) => {
                        self.handleError(error);
                    });
            },
            postSpace() {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;
                api.postSpace(self.form, self.$route.params.building_id)
                    .then((response) => {
                        self.disabled = false;

                        if (self.tljBuildingEnabled && self.form.tlj_room_name) {
                          api.putTljSpace(
                              response.space.id,
                              self.form.tlj_room_name,
                              self.form.tlj_room_area_id,
                              self.form.tlj_room_floor_id
                          ).catch((error) => {
                            self.disabled = false;
                            self.handleError(error);
                          });
                        }

                        Object.assign(this.$data, this.$options.data.call(this));

                        $('#addSpace').modal('hide');
                        self.getBuildingAmeninitySpaces();
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
            putSpaces() {
              const self = this;
              if (self.disabled) return;
              self.disabled = true;

              api.putSpaces(self.formArray, self.$route.params.building_id)
                  .then((response) => {
                    self.disabled = false;

                    Object.assign(this.$data, this.$options.data.call(this));

                    $('#addSpaces').modal('hide');
                    self.getBuildingAmeninitySpaces();
                  })
                  .catch((error) => {
                    self.disabled = false;
                    self.handleError(error);
                  });
            },
            updatePermission(value) {
              const self = this;
              this.$nextTick(function () {
                  self.form.restricted = value;
                  this.$forceUpdate();
              });
            },
            async csvToJson(rawFile) {
              return new Promise((resolve, reject) => {
                Papa.parse(rawFile, {
                  header: true,
                  skipEmptyLines: true,
                  transformHeader(headerString) {
                    return headerString.split(/\b\s+/)
                        .map(word => word.toLowerCase())
                        .join('_');
                  },
                  complete(results, file) {
                    resolve(results.data);
                  },
                  error(err, file) {
                    reject(err);
                  },
                });
              });
            },
            async jsonToCsv(rawData) {
              return Papa.unparse(rawData, { delimiter: ',' });
            },
            async fileUpdated() {
              const input = document.getElementById('fileInput');
              const [file] = input.files;
              const array = await this.csvToJson(file);
              this.formArray = [];
              array.forEach(space => {
                if (space['device_type_restrictions'].toLowerCase() === 'enabled') {
                  space['device_type_restrictions'] = 1;
                } else if (space['device_type_restrictions'].toLowerCase() === 'disabled') {
                  space['device_type_restrictions'] = 0;
                } else {
                  alert('Error: Device Type Restrictions must be either "enabled" or "disabled". Must re-add ' + space['space_name'] + ' as this space will be skipped during creation.');
                  return;
                }
                // Filter out empty rows
                if (!Object.values(space).every(x => x === null || x === '')) {
                  space['tlj_room_name'] = space['tlj_room_id'];
                  delete space['tlj_room_id'];
                  this.formArray.push(space);
                }
              });
              $('#addSpaces').modal('show');
            },
            downloadExisting(eventdata) {
              const self = this;
              api.getSpacesForBuildingExport(this.$route.params.building_id)
                  .then((response) => {
                    // Generate CSV File of current units
                    const newFile = response.data;
                    const blob = new Blob([newFile], );
                    const csvUrl = window.URL.createObjectURL(blob);
                    //Create an a tag with the file attached
                    const fileLink = document.createElement('a');
                    fileLink.href = csvUrl;
                    fileLink.setAttribute('download', this.fileName);
                    // File is downloaded on click
                    fileLink.click();
                  })
                  .catch((error) => {
                    self.handleError(error);
                  });
            },
            async downloadTemplate(eventdata) {
              // Generate CSV Template
              const csvString = await this.jsonToCsv(this.dataTemplate);
              const blob = new Blob([csvString]);
              const templateUrl = window.URL.createObjectURL(blob);
              //Create an a tag with the file attached
              const fileLink = document.createElement('a');
              fileLink.href = templateUrl;
              fileLink.setAttribute('download', 'space-template.csv');
              //File downloaded on click
              fileLink.click();
            },
            addSpaces(eventData) {
              $('#addSpace').modal('show');
            },
        },
    };
</script>
