<template>
  <div>
    <iframe class="queue" frameBorder="0" src="/beanstalkd/tubes" />
  </div>
</template>

<script>
    export default {
        name: 'Queue',
        mounted() {
            $('.loading').css('display', 'none');
        },
    };
</script>
