<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light building-nav">
    <button aria-controls="buildingNav" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler w-100" data-target="#buildingNav" data-toggle="collapse" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="buildingNav">
      <ul class="nav flex-column w-100">
        <li class="nav-item">
          <router-link :to="{ name: 'editbuilding', params: { id: this.$route.params.id, building_id: this.$route.params.building_id}}" class="nav-link">Building Information</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'buildingplan', params: { id: this.$route.params.id, building_id: this.$route.params.building_id}}" class="nav-link">Homebase Plan</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'buildingconfiguration', params: { id: this.$route.params.id, building_id: this.$route.params.building_id}}" class="nav-link">Configuration</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'buildingunits', params: { id: this.$route.params.id, building_id: this.$route.params.building_id}}" class="nav-link">Units</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'buildingamenityspaces', params: { id: this.$route.params.id, building_id: this.$route.params.building_id}}" class="nav-link">Spaces</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'buildingwifi', params: { id: this.$route.params.id, building_id: this.$route.params.building_id}}" class="nav-link">Manage WiFi Tiers</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'brivosite', params: { id: this.$route.params.id, building_id: this.$route.params.building_id}}" class="nav-link">Brivo Site</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'brivogroups', params: { id: this.$route.params.id, building_id: this.$route.params.building_id}}" class="nav-link">Brivo Groups</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'buildingresidents', params: { id: this.$route.params.id, building_id: this.$route.params.building_id}}" class="nav-link">Residents</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'buildinglaunch', params: { id: this.$route.params.id, building_id: this.$route.params.building_id}}" class="nav-link">Launch Building</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
    export default {
        name: 'BuildingNav',
    };
</script>
