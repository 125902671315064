<template>
  <div class="row">
    <div class="col">
      <div :data-target="'#admin'+admin.id" class="card card-link" data-toggle="modal">
        <div class="card-body">
          <!-- <div class="row"> -->
            <!-- <div class="col-12">
              <span>{{ admin.first_name }} {{ admin.last_name }}</span>
              <span class="text-muted"></span>
            </div> -->
          <div class="row">
            <div class="col-6">
              <span class="font-weight-bold">{{ admin.first_name }} {{ admin.last_name}}</span>
              <br />
              <span>{{ adminType }}</span>
              <br />
              <span>{{ admin.email }}</span>
            </div>
            <div class="col-6 text-md-right mt-3">
              <div class="form-group mb-0" v-if="admin">
                <label :for="'brivo' + admin.id">Brivo OnAir Pass</label>
                <input type="checkbox" class="form-control d-inline-block" :id="'brivo' + admin.id" v-model="admin.brivo" />
              </div>
              <button class="btn btn-danger btn-sm d-inline-block" @click="deleteBrivoUser(admin)">Delete Brivo User</button>
              <button class="btn btn-light btn-sm d-inline-block" @click="resetPassword">Reset Password</button>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div :id="'admin'+admin.id" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Update Admin</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 modal-messages" />
            </div>
            <form @submit.prevent="putAdmin">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>First Name</label>
                    <input class="form-control" maxlength="255" name="first_name" required type="text" v-model.trim="form.first_name">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Last Name</label>
                    <input class="form-control" name="last_name" required type="text" v-model.trim="form.last_name">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Email</label>
                    <input class="form-control" maxlength="255" name="email" readonly type="email" v-model.trim="form.email">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Phone Number</label>
                    <input class="form-control" name="phone_number" required type="text" v-model.trim="form.phone_number">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Job Title</label>
                    <input class="form-control" name="job_title" type="text" v-model.trim="form.job_title">
                  </div>
                </div>
              </div>
              <button class="d-none" type="submit" />
            </form>
          </div>
          <div class="modal-footer">
            <button :disabled="disabled" @click="deleteAdmin" class="btn btn-danger mr-auto" type="button" v-if="!admin.super_admin">Revoke Permissions</button>
            <button class="btn btn-secondary" data-dismiss="modal" type="button">Close</button>
            <button :disabled="disabled" @click="putAdmin" class="btn btn-primary" type="button">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../api.js';

    export default {
        name: 'Admin',
        props: {
            admin: Object,
        },
        data() {
            return {
                disabled: false,
                form: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: '',
                    job_title: '',
                },
            };
        },
        computed: {
            adminType() {
                if (this.admin.installer_detail !== null) {
                    return 'Installer';
                }

                if (this.admin.super_admin !== null) {
                    return 'Super Admin';
                }

                return 'Admin';
            },
        },
        beforeMount() {
            const self = this;
            self.form.first_name = self.admin.first_name;
            self.form.last_name = self.admin.last_name;
            self.form.email = self.admin.email;
            self.form.phone_number = self.admin.phone_number;
            self.form.job_title = self.admin.job_title;
        },
        methods: {
            putAdmin() {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                self.form.id = self.admin.id;
                api.putAdmin(self.form)
                    .then((response) => {
                        self.disabled = false;
                        $('#admin' + self.admin.id).modal('hide');
                        self.$emit('updated');
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
            deleteAdmin() {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                self.form.id = self.admin.id;
                api.deleteRemoveAdmin(self.form, self.$route.params.building_id)
                    .then((response) => {
                        self.disabled = false;
                        $('#admin' + self.admin.id).modal('hide');
                        self.$emit('updated');
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
            deleteBrivoUser(user) {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                if (!confirm('Are you sure you want to delete the Brivo account for ' + user.first_name + ' ' + user.last_name + '?')) {
                    self.disabled = false;
                    return;
                }
                api.deleteBrivoUser(user.id,'App\\Models\\Admin')
                    .then((response) => {
                        self.handleSuccess('Success - ' + user.first_name + ' ' + user.last_name + ' was deleted from Brivo.');
                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.handleError(error);
                        self.disabled = false;
                    });
            },
        },
    };
</script>
