<template>
  <div class="row">
    <div class="col">
      <router-link :to="'/portfolios/'+building.portfolio_id+'/building/'+building.id+'/information'" class="card-link">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h6>{{ building.building_name }}</h6>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-sm-6 col-lg-4 col-xl-3">{{ building.address_1 }}, {{ building.city_name }}, {{ building.state }}</div>
              <div class="col-sm-6 col-lg-4 col-xl-2">Active {{ active }}</div>
              <div class="col-sm-6 col-lg-4 col-xl-2">Added {{ added }}</div>
              <div class="col-sm-6 col-lg-4 col-xl-2">
                {{ building.number_of_floors }} Floor<span v-if="building.number_of_floors !== 1">s</span>
              </div>
              <div class="col-sm-6 col-lg-4 col-xl-2">
                {{ building.unit_count }} Unit<span v-if="building.unit_count !== 1">s</span>
              </div>
              <div class="col-sm-6 col-lg-4 col-xl-1">
                {{ building.admins_count }} Admin<span v-if="building.admins_count !== 1">s</span>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'Building',
        props: {
            building: Object,
        },
        computed: {
            active() {
                return moment.utc(this.building.updated_at).fromNow();
            },
            added() {
                return moment.utc(this.building.created_at).fromNow();
            },
        },
    };
</script>
