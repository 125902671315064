<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <span class="font-weight-bold">{{ tenant.first_name }} {{ tenant.last_name}}</span>
              <br />
              <span v-if="tenant.my_unit">Unit {{ tenant.my_unit.unit_number }}</span>
              <span v-else>N/A</span>
              <br />
              <span v-if="tenant.lease !== null">{{ getLeaseStartDate }} - {{ getLeaseEndDate }}</span>
              <span v-else>N/A</span>
            </div>
            <div class="col-6 text-md-right">
              <div class="form-group mb-0" v-if="tenant.next_day_payment_enabled !== null">
                <label :for="'nextday' + tenant.id">Next-Day Payments</label>
                <input type="checkbox" class="form-control d-inline-block" :id="'nextday' + tenant.id" v-model="tenant.next_day_payment_enabled" />
              </div>
              <div class="form-group mb-0" v-if="brivo && tenant.my_unit !== null">
                <label :for="'brivo' + tenant.id">Brivo OnAir Pass</label>
                <input type="checkbox" class="form-control d-inline-block" :id="'brivo' + tenant.id" v-model="tenant.brivo" />
              </div>
              <button class="btn btn-danger btn-sm d-inline-block" @click="deleteBrivoUser(tenant)">Delete Brivo User</button>
              <button class="btn btn-light btn-sm d-inline-block" @click="resetPassword">Reset Password</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../api.js';

    export default {
        name: "Resident",
        props: {
            brivo: Boolean,
            tenant: Object,
        },
        data() {
            return {
                disabled: false
            }
        },
        watch: {
            'tenant.next_day_payment_enabled': function(newValue, oldValue){
                if (newValue) {
                    this.enableNextDayPayments();
                } else {
                    this.disableNextDayPayments();
                }
            },
            'tenant.brivo': function(newValue, oldValue){
                if (newValue) {
                    this.enableBrivoOnAir();
                } else {
                    this.disableBrivoOnAir();
                }
            },
        },
        computed: {
            getLeaseStartDate() {
                return moment.utc(this.tenant.lease.start_date).format('MMMM D, YYYY');
            },
            getLeaseEndDate() {
                return moment.utc(this.tenant.lease.start_date).format('MMMM D, YYYY');
            },
        },
        methods: {
            enableNextDayPayments() {
                const self = this;
                if (self.disabled) {
                    return;
                }
                self.disabled = true;

                api.enableNextDayPayments(self.tenant.id)
                    .then((response) => {
                        self.handleSuccess("Success - Next-day payments enabled for " + self.tenant.first_name + " " + self.tenant.last_name + ".");
                        self.tenant.next_day_payment_enabled = true;
                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.handleError(error);
                        self.disabled = false;
                    });
            },
            disableNextDayPayments() {
                const self = this;
                if (self.disabled) {
                    return;
                }
                self.disabled = true;

                api.disableNextDayPayments(self.tenant.id)
                    .then((response) => {
                        self.handleSuccess("Success - Next-day payments disabled for " + self.tenant.first_name + " " + self.tenant.last_name + ".");
                        self.tenant.next_day_payment_enabled = false;
                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.handleError(error);
                        self.disabled = false;
                    });
            },
            enableBrivoOnAir() {
                const self = this;
                if (self.disabled) {
                    return;
                }
                self.disabled = true;

                api.putEnableOnAir(self.tenant.id)
                    .then((response) => {
                        self.handleSuccess("Success - Brivo OnAir has been enabled for " + self.tenant.first_name + " " + self.tenant.last_name + ".");
                        self.tenant.brivo = true;
                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.handleError(error);
                        self.disabled = false;
                    });
            },
            disableBrivoOnAir() {
                const self = this;
                if (self.disabled) {
                    return;
                }
                self.disabled = true;

                api.putDisableOnAir(self.tenant.id)
                    .then((response) => {
                        self.handleSuccess("Success - Brivo OnAir has been disabled for " + self.tenant.first_name + " " + self.tenant.last_name + ".");
                        self.tenant.brivo = false;
                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.handleError(error);
                        self.disabled = false;
                    });
            },
            resetPassword() {
                const self = this;
                if (!confirm('Are you sure you want to reset ' + self.tenant.first_name + ' ' + self.tenant.last_name + '\'s password?')) {
                return;
                }

                api.tenantResetPassword(self.tenant.id)
                        .then((response) => {
                        self.handleSuccess(self.tenant.first_name + ' ' + self.tenant.last_name + '\'s password has been reset. They can update it in the mobile resident app.');
                        })
                        .catch((error) => {
                        self.handleError(error);
                        });
            },
            deleteBrivoUser(user) {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                if (!confirm('Are you sure you want to delete the Brivo account for ' + user.first_name + ' ' + user.last_name + '?')) {
                    self.disabled = false;
                    return;
                }

                // let user_type = '';
                // if (user.user_type === 'Tenant') {
                //     user_type = 'App\\Models\\Tenant';
                // } else if (user.user_type === 'Super Admin' || user.user_type === 'Admin') {
                //     user_type = 'App\\Models\\Admin';
                // } else {
                //     self.handleError('The user is not a tenant or admin.');
                //     self.disabled = false;
                //     return;
                // }
                api.deleteBrivoUser(user.id,'App\\Models\\Tenant')
                    .then((response) => {
                        let index = self.users.findIndex(x => x.id === user.id);
                        if (index !== -1) {
                            self.users.splice(index, 1);
                        }
                        self.handleSuccess('Success - ' + user.first_name + ' ' + user.last_name + ' was deleted from Brivo.');
                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.handleError(error);
                        self.disabled = false;
                    });
            }
        }
    }
</script>
