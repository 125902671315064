<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <h2 class="confirm-header">Are you sure?</h2>
        <p>Are you sure you want to unassign this batch of fobs?</p>
      </div>
    </div>
    <hr/>
    <div class="row justify-content-between">
      <div class="col-6 col-md-4">
        <button
            class="btn btn-md btn-block btn-outline-primary"
            @click="cancelUnassign"
        >
          Cancel
        </button>
      </div>
      <div class="col-6 col-md-4">
        <button
            type="submit"
            class="btn btn-md btn-block btn-danger"
            @click="disassociateFobs"
        >
          Yes, unassign <strong>{{ fobCount }}</strong> Key<span v-if="fobCount !== 1">s</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {numeric, required} from "vuelidate/lib/validators";
import api from "../../../api";

export default {
  name: 'ConfirmUnassignFob',
  data() {
    return {
      form: {
        start_number: '',
        end_number: '',
        facility_code: '',
        building_id: '',
      },
    };
  },
  validations: {
    form: {
      start_number: {
        numeric,
        required,
      },
      end_number: {
        numeric,
        required,
      },
      facility_code: {
        numeric,
        required,
      },
      building_id: {
        numeric,
        required,
      },
    },
  },
  computed: {
    fobCount() {
      const formData = this.$store.getters['unassignKeyFobs/unassignFobForm'];
      return (formData.end_number - formData.start_number) + 1;
    }
  },
  mounted() {
    const self = this;
    self.search = '';
  },
  methods: {
    async cancelUnassign() {
      await this.$store.dispatch('unassignKeyFobs/clearFormData');
      await this.$store.dispatch('modal/close', {modalId: 'unassignConfirm'});
    },
    disassociateFobs() {
      const formData = this.$store.getters['unassignKeyFobs/unassignFobForm'];
      api.disassociateFobs(formData.start_number, formData.end_number, formData.facility_code, formData.building_id, this.$route.params.id)
          .then((response) => {
            this.handleSuccess('Success - batch unassignment complete.');
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.$store.dispatch('modal/close', {modalId: 'unassignConfirm'})
          });
    },
  },
};
</script>

<style scoped>

</style>