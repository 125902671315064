<template>
  <div>
    <div class="container search-box">
      <div class="row justify-content-center d-flex">
        <div class="col">
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-search fa-fw"></i></span>
            </div>
            <input class="form-control" placeholder="Search" type="text" v-model="search" />
          </div>
        </div>
        <div class="col-auto">
          <router-link class="btn btn-primary float-right" to="/portfolios/create">New Portfolio</router-link>
        </div>
      </div>
    </div>
    <div class="container content">
      <div class="row justify-content-center">
        <div class="col p-0 pl-sm-3 pr-sm-3">
          <portfolio :initial-portfolio="portfolio" :key="portfolio.id" v-for="portfolio in filteredList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../../api.js';
    import Portfolio from '../../components/Portfolio.vue';

    export default {
        components: {
            Portfolio,
        },
        data() {
            return {
                portfolios: [],
                search: ''
            };
        },
        computed: {
            filteredList() {
                if (this.portfolios == null) return [];
                const re = new RegExp(this.search, 'i');
                return this.portfolios.filter(
                    (portfolio) =>
                        portfolio.business_name.match(re) ||
                        portfolio.buildings.some(b => b.building_name.match(re))
                );
            },
        },
        beforeMount() {
            const self = this;
            if (!self.$store.getters.getPermission('view_portfolios')) {
                self.$router.push({name: 'index'});
                return;
            }
            api.getPortfolios()
                .then((response) => {
                    self.portfolios = response.portfolios;
                    $('.content').css('display', 'block');
                    $('.content').animateCss('fadeIn');
                })
                .catch((error) => {
                    self.handleError(error);
                });
        },
    };
</script>
