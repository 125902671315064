<template>
  <div>
    <CreateEmployee @updated="getEmployees" />
    <div class="container search-box">
      <div class="row justify-content-center d-flex">
        <div class="col">
          <div class="form-group input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-search fa-fw"></i></span>
            </div>
            <input class="form-control" placeholder="Search" type="text" v-model.trim="search">
          </div>
        </div>
        <div class="col-auto">
          <a class="btn btn-primary text-white" data-target="#createEmployee" data-toggle="modal">New Employee</a>
        </div>
      </div>
      <div class="row">
        <i class="fas fa-exclamation-circle active-warning ml-3 mr-1" style="margin-top: 2px;"></i>
        <h6 class="d-inline font-weight-bold">Disabled</h6>
      </div>
    </div>
    <div class="container content" style="margin-top: 153px;">
      <div class="row justify-content-center">
        <div class="col p-0 pl-sm-3 pr-sm-3">
          <Employee :initial-employee="employee" :initial-permissions="permissions" :key="employee.id" v-for="employee in filteredList" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../api.js';
    import CreateEmployee from '../components/modals/CreateEmployee.vue';
    import Employee from '../components/Employee.vue';

    export default {
        components: {
            'CreateEmployee': CreateEmployee,
            'Employee': Employee,
        },
        data() {
            return {
                disabled: false,
                employees: [],
                permissions: [],
                search: '',
            };
        },
        computed: {
            filteredList() {
                if (this.employees == null) return [];
                const re = new RegExp(this.search, 'i');
                return this.employees.filter(
                    (employee) =>
                        employee.email.match(re) ||
                        employee.first_name.match(re) ||
                        employee.last_name.match(re) ||
                        (employee.first_name + ' ' + employee.last_name).match(re) ||
                        employee.phone_number.match(re) ||
                        employee.title.match(re)
                );
            },
        },
        beforeMount() {
            this.getEmployees();
        },
        methods: {
            getEmployees() {
                const self = this;
                api.getEmployees()
                    .then((response) => {
                        self.employees = response.employees;
                        self.permissions = response.permissions;

                        if ($('.content').css('display') === 'none') {
                            $('.content').css('display', 'block');
                            $('.content').animateCss('fadeIn');
                        }
                    })
                    .catch((error) => {
                        self.handleError(error);
                    });
            }
        },
    };
</script>