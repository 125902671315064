<template>
  <div class="row">
    <div class="col-12">
      <div :id="'card'+admin.id" @click="toggleChecked" class="card card-link card-select">
        <div class="card-body">
          <div class="row justify-content-center">
            <div class="col-0 col-md-12">
              {{ admin.first_name }} {{ admin.last_name }}
              <span class="text-muted">• {{ admin.email }}</span>
            </div>
            <div class="col-4 col-md-0">
              {{ admin.first_name }} {{ admin.last_name }}
            </div>
            <div class="col-8 col-md-0">
              {{ admin.email }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../../api.js';

    export default {
        name: 'Admin',
        props: {
            admin: Object,
        },
        data() {
            return {
                disabled: false,
                is_checked: false,
            };
        },
        mounted() {
            const self = this;
            self.is_checked = self.admin.is_checked || false;
            if (self.admin.is_checked) {
                $('#card' + self.admin.id).addClass('checked');
            }
        },
        methods: {
            toggleChecked() {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                self.is_checked = !self.is_checked;

                if (self.is_checked) {
                    $('#card' + self.admin.id).addClass('checked');
                    self.putAdmin();
                } else {
                    $('#card' + self.admin.id).removeClass('checked');
                    self.deleteAdmin();
                }
            },
            putAdmin() {
                const self = this;

                api.putAddAdmin(self.admin.id, self.$route.params.building_id)
                    .then((response) => {
                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
            deleteAdmin() {
                const self = this;

                api.deleteRemoveAdmin(self.admin, self.$route.params.building_id)
                    .then((response) => {
                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
        },
    };
</script>
