import Axios from 'axios';

window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('popper.js').default;
    require('bootstrap');
} catch (e) {
}


// Axios Configuration
Axios.defaults.baseURL = process.env.MIX_HOMEBASE_API_ROOT;
Axios.defaults.headers.common.Accept = 'application/vnd.homebase.chell+json';

Axios.interceptors.request.use((config) => {
    if (config.url.match(/hide_loading_div/g)) {
        config.url = config.url.replace(/hide_loading_div/g, '');
    } else {
        $('.loading').css('display', 'block');
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

Axios.interceptors.response.use((response) => {
    $('.loading').css('display', 'none');
    return response;
}, (error) => {
    $('.loading').css('display', 'none');
    return Promise.reject(error);
});

// Animate CSS
$.fn.extend({
    animateCss: function (animationName, callback) {
        let animationEnd = (function (el) {
            let animations = {
                animation: 'animationend',
                OAnimation: 'oAnimationEnd',
                MozAnimation: 'mozAnimationEnd',
                WebkitAnimation: 'webkitAnimationEnd',
            };

            for (let t in animations) {
                if (el.style[t] !== undefined) {
                    return animations[t];
                }
            }
        })(document.createElement('div'));

        this.addClass('animated ' + animationName).one(animationEnd, function () {
            $(this).removeClass('animated ' + animationName);

            if (typeof callback === 'function') {
                callback();
            }
        });

        return this;
    },
});
