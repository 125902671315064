<template>
  <div v-if="portfolio != null" @scroll="onScroll">
    <form @submit.prevent="openConfirm">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label for="start_number">Start Number</label>
            <input :class="{'is-invalid':$v.form.start_number.$anyError}" class="form-control" id="start_number" type="text" v-model.trim="$v.form.start_number.$model">
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label for="end_number">End Number</label>
            <input :class="{'is-invalid':$v.form.end_number.$anyError}" class="form-control" id="end_number" type="text" v-model.trim="$v.form.end_number.$model">
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="form-group">
            <label for="facility_code">Facility Code</label>
            <input :class="{'is-invalid':$v.form.facility_code.$anyError}" class="form-control" id="facility_code" type="text" v-model.trim="$v.form.facility_code.$model">
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group">
            <label for="building_id">Community</label>
            <select class="form-control" id="building_id" v-model.trim="form.building_id">
              <option value="">Select a building...</option>
              <option v-for="building in portfolio.buildings" :value="building.id" :key="building.id">{{ building.building_name }}</option>
            </select>
          </div>
        </div>
        <div class="col"></div>
        <div class="col-auto">
          <button
              type="submit"
              class="btn btn-primary w-100 mt-"
              @click="openConfirm"
              :disabled="disabled || form.start_number > form.end_number || form.start_number === '' || form.end_number === '' || form.facility_code === ''|| form.building_id === ''"
          >
            Unassign <strong>{{ batch_keys }}</strong> Key<span v-if="batch_keys !== 1">s</span>
          </button>
        </div>
      </div>
    </form>

    <hr />

    <!-- FOB SEARCH -->
    <div class="row">
      <div class="col-12">
        <div class="form-group input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search fa-fw"></i></span>
          </div>
          <input type="text" class="form-control" placeholder="Search card numbers..." v-model.trim="search">
        </div>
      </div>
    </div>

    <!-- LOADING FOBS -->
    <div class="row justify-content-center text-center" v-if="loadingFobsContent">
      <div class="col-md-6">
        <i class="far fa-key fa-fw fa-2x d-block m-auto iconBadge"></i>
        <h6 class="mt-2">Loading keys</h6>
      </div>
    </div>
    <!-- FILTERED FOBS CONTENT -->
    <div class="row justify-content-center text-center" v-else-if="hasNoFilteredFobs()">
      <div class="col-md-6">
        <i class="far fa-key fa-fw fa-2x d-block m-auto" style="background-color: #eeeeee; color: #4f4f4f; border-radius: 200px; width: 70px; height: 70px; padding-top: 21px; padding-left: 2px;"></i>
        <h6 class="mt-2">{{ searchEmptyString }}</h6>
      </div>
    </div>
    <!-- FOBS CONTENT -->
    <div v-else>
      <div class="row fob-table-header font-weight-bold">
        <div class="col-6 col-md-2">Key <span class="float-right font-italic">FC</span></div>
        <div class="col-6 col-md-3">Assignment</div>
        <div class="col-6 col-md-2">Type</div>
        <div class="col-6 col-md-3">Date Added</div>
        <div class="col-12 col-md-2"></div>
      </div>
      <div class="row fob-table-border" v-for="fob in filteredAssigned" :key="fob.id">
        <div class="col-6 col-md-2">{{ fob.card_number }} <span class="float-right font-italic">{{ fob.facility_code }}</span></div>
        <div class="col-6 col-md-3">
          <span v-if="fob.user !== null">
            {{ fob.user.first_name }} {{ fob.user.last_name }}
            <span v-if="fob.user_type === 'App\\Models\\Tenant'" class="float-right font-italic">Tenant</span>
            <span v-if="fob.user_type === 'App\\Models\\Admin'" class="float-right font-italic">Admin</span>
            <span v-if="fob.user_type === 'App\\Models\\SuperAdmin'" class="float-right font-italic">Super Admin</span>
          </span>
          <span v-else class="text-muted font-italic">Unknown User</span>
        </div>
        <div class="col-6 col-md-2"><span v-if="fob.fob_type !== null">{{ fob.fob_type.name }}</span><span v-else class="text-muted font-italic">N/A</span></div>
        <div class="col-6 col-md-3">{{ getDate(fob.created_at) }}</div>
        <div class="col-12 col-md-2 font-weight-bold text-right">
          <span class="text-primary mr-3 d-none" :id="'confirm-'+fob.id" @click="unassignFob(fob.id)" style="cursor:pointer;">Confirm</span>
          <span class="text-danger" :id="'unassign-'+fob.id" @click="showUnassignConfirm(fob.id)" style="cursor:pointer;">Unassign</span>
        </div>
      </div>
      <div class="row fob-table-border" v-for="fob in filteredAvailable" :key="fob.id">
        <div class="col-6 col-md-2">{{ fob.card_number }} <span class="float-right font-italic">{{ fob.facility_code }}</span></div>
        <div class="col-6 col-md-3 text-muted font-italic">Unassigned</div>
        <div class="col-6 col-md-2"><span v-if="fob.fob_type !== null">{{ fob.fob_type.name }}</span><span v-else class="text-muted">N/A</span></div>
        <div class="col-6 col-md-3">{{ getDate(fob.created_at) }}</div>
        <div class="col-12 col-md-2"></div>
      </div>
    </div>
  </div>
  <div v-else>
    <!-- LOADING FOBS -->
    <div class="row justify-content-center text-center">
      <div class="col-md-6">
        <i class="far fa-key fa-fw fa-2x d-block m-auto iconBadge"></i>
        <h6 class="mt-2">Loading portfolio</h6>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../api.js';
import {integer, required} from 'vuelidate/lib/validators';

function filteredFobs(fobType, search) {
  if (fobType === undefined) {
    return [];
  }
  const re = new RegExp(search, 'i');
  return fobType.filter(
      (fob) =>
          fob.card_number.toString().match(re)
  );
}

export default {
  name: 'PortfolioKeysBuildingUnassignContent',
  props: {
    portfolio: Object,
    fob_types: Array,
  },
  data() {
    return {
      batch_keys: 0,
      disabled: false,
      portfolioObject: this.portfolio,
      form: {
        start_number: '',
        end_number: '',
        facility_code: '',
        building_id: '',
      },
      loadingFobsContent: false,
      activePortfolioFobs: [],
      availablePortfolioFobs: [],
      fobsPaginationObject: {
        activePortfolioFobsStateObject: {
          activePortfolioFobsURI: null,
          activePortfolioFobsLoading: false,
        },
        availablePortfolioFobsStateObject: {
          availablePortfolioFobsURI: null,
          availablePortfolioFobsLoading: false,
        },
      },
      search: null,
    };
  },
  validations: {
    form: {
      start_number: {
        integer,
        required,
      },
      end_number: {
        integer,
        required,
      },
      facility_code: {
        integer,
        required,
      },
      building_id: {
        integer,
        required,
      }
    },
  },
  computed: {
    searchEmptyString() {
      if (this.search === '') {
        return 'No keys assigned to this portfolio.';
      }
      return 'No keys found.';
    },
    filteredAssigned() {
      return filteredFobs(this.activePortfolioFobs, this.search);
    },
    filteredAvailable() {
      return filteredFobs(this.availablePortfolioFobs, this.search);
    },
  },
  watch: {
    'search': function(newValue, oldValue) {
      if (this.portfolioObject && this.portfolioObject.id) {
        this.getFobs(this.portfolioObject.id);
      }
    },
    'portfolio': function(newValue, oldValue) {
      if ((!oldValue.id && newValue.id) || (newValue !== oldValue && newValue.id !== oldValue.id)) {
        this.portfolioObject = newValue;
        this.getFobs(newValue.id);
      }
    },
    'form.start_number': function(newValue, oldValue) {
      this.getBatchDiff();
    },
    'form.end_number': function(newValue, oldValue) {
      this.getBatchDiff();
    },
  },
  methods: {
    async openConfirm() {
      if (this.disabled || this.form.start_number > this.form.end_number) {
        return;
      }
      this.disabled = true;

      await this.$store.dispatch('unassignKeyFobs/setFormData', this.form);
      await this.$store.dispatch('modal/open', {modalId: "unassignConfirm"});
      await this.$store.dispatch('modal/close', {modalId: 'portfolioKeysModal'});
    },
    hasNoFilteredFobs() {
      if (this.filteredAssigned.length === 0 && this.filteredAvailable.length === 0)
      {
        return true;
      }
      return false;
    },
    getDate(date) {
      return moment.utc(date).format('MMMM D, YYYY');
    },
    getBatchDiff() {
      let start = parseInt(this.form.start_number);
      let end = parseInt(this.form.end_number);
      if (isNaN(start) || isNaN(end) || start > end) {
        this.batch_keys = 0;
      } else {
        this.batch_keys = (end - start) + 1;
      }
    },
    getPaginatedAvailablePortfolioFobs(fobsPaginationObject, portfolioId) {
      const self = this;
      if (!fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsLoading && fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI) {
        fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsLoading = true;
        api.getPaginatedAvailablePortfolioFobs(this.search, portfolioId, fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI.split('?')[1])
            .then((response) => {
              if ($('.content').css('display') === 'none') {
                $('.content').css('display', 'block');
                $('.content').animateCss('fadeIn');
              }
              fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI = response.next_page_url;
              self.availablePortfolioFobs = self.availablePortfolioFobs.concat(response.data);
              fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsLoading = false;
            })
            .catch((error) => {
              fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsLoading = false;
              fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI = null;
            });
      }
    },
    getPaginatedActivePortfolioFobs(fobsPaginationObject, portfolioId) {
      const self = this;
      if (!fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsLoading && fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI) {
        fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsLoading = true;
        api.getPaginatedActivePortfolioFobs(this.search, portfolioId, fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI.split('?')[1])
            .then((response) => {
              if ($('.content').css('display') === 'none') {
                $('.content').css('display', 'block');
                $('.content').animateCss('fadeIn');
              }
              fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI = response.next_page_url;
              self.activePortfolioFobs = self.activePortfolioFobs.concat(response.data);
              fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsLoading = false;
            })
            .catch((error) => {
              fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsLoading = false;
              fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI = null;
            });
      }
    },
    splicePaginationUrl(url) {
      return str.split('?')[1];
    },
    async getFobs(portfolioId) {
      this.loadingFobsContent = true;

      if(portfolioId){
        const portfolioActiveFobsResp = await api.getActivePortfolioFobs(this.search, portfolioId)
        if ($('.content').css('display') === 'none') {
          $('.content').css('display', 'block');
          $('.content').animateCss('fadeIn');
        }
        this.fobsPaginationObject.activePortfolioFobsStateObject.activePortfolioFobsURI = portfolioActiveFobsResp.next_page_url;
        this.activePortfolioFobs = portfolioActiveFobsResp.data;

        try {
          const portfolioAvailableFobsResp = await api.getAvailablePortfolioFobs(this.search, portfolioId)
          if ($('.content').css('display') === 'none') {
            $('.content').css('display', 'block');
            $('.content').animateCss('fadeIn');
          }
          this.fobsPaginationObject.availablePortfolioFobsStateObject.availablePortfolioFobsURI = portfolioAvailableFobsResp.next_page_url;
          this.availablePortfolioFobs = portfolioAvailableFobsResp.data;
        } catch (error) {
          this.handleError(error);
          this.disabled = false;
        }
      }

      this.loadingFobsContent = false;
    },
    showUnassignConfirm(fobId) {
      const unassign = $('#unassign-' + fobId);
      const confirm = $('#confirm-' + fobId);

      if (unassign.text() === 'Unassign') {
        unassign.text('Cancel');
        confirm.removeClass('d-none');
      } else {
        unassign.text('Unassign');
        confirm.addClass('d-none');
      }
    },
    unassignFob(fobId) {
      const self = this;
      if (self.disabled) {
        return;
      }
      self.disabled = true;

      api.deleteUnassignFob(fobId)
          .then((response) => {
            self.handleSuccess('Success - fob was unassigned.');
            self.getFobs(self.portfolioObject.id);
            self.disabled = false;
          })
          .catch((error) => {
            self.handleError(error);
            self.disabled = false;
          });
    },
    onScroll({ target }) {
      let bottomOfWindow = target.scrollTop >= target.offsetHeight;

      if (bottomOfWindow && this.portfolioObject && portfolioId && fobsPaginationObject) {
        this.getPaginatedActivePortfolioFobs(fobsPaginationObject, portfolioId)
        this.getPaginatedAvailablePortfolioFobs(fobsPaginationObject, portfolioId)
      }
    },
  },
  //
  // Lifecycle functions
  // ----------------------------------------
  mounted() {
    this.search = '';
  },
  async created() {
    if (this.portfolio != null && this.portfolio.id) {
      this.portfolioObject = this.portfolio;
      this.getFobs(this.portfolio.id);
    }
  },
};
</script>
