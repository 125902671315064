<template>
  <div aria-hidden="true" aria-labelledby="connectBrivoLabel" class="modal fade" id="connectBrivo" role="dialog" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="connectBrivoLabel">Connect Brivo Account</h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="connectBrivo">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="account_name">Account Name</label>
                  <input :class="{'is-invalid':$v.form.account_name.$anyError}" class="form-control" id="account_name" type="text" v-model.trim="$v.form.account_name.$model">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="account_number">Account Number</label>
                  <input :class="{'is-invalid':$v.form.account_number.$anyError}" class="form-control" id="account_number" type="text" v-model.trim="$v.form.account_number.$model">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="client_id">Client ID</label>
                  <input :class="{'is-invalid':$v.form.client_id.$anyError}" class="form-control" id="client_id" type="text" v-model.trim="$v.form.client_id.$model">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="client_secret">Client Secret</label>
                  <input :class="{'is-invalid':$v.form.client_secret.$anyError}" class="form-control" id="client_secret" type="text" v-model.trim="$v.form.client_secret.$model">
                </div>
              </div>
            </div>
            <button class="d-none" type="submit"></button>
          </form>
        </div>
        <div class="modal-footer d-block">
          <div class="row">
            <div class="col-auto">
              <button :disabled="disabled || brivo === null" @click="refreshBrivo" class="btn btn-outline-primary" type="button"><i class="far fa-sync-alt fa-fw" id="refresh-button"></i> Refresh Brivo</button>
            </div>
            <div class="col text-right">
              <button :disabled="disabled" class="btn btn-outline-primary" data-dismiss="modal" type="button">Close</button>
              <button :disabled="disabled || $v.$invalid" class="btn btn-primary" type="button" v-on:click="connectBrivo">Connect</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../../api.js';
    import {maxLength, numeric, required} from 'vuelidate/lib/validators';

    export default {
        name: "ConnectBrivo",
        props: {
            brivo: Object,
        },
        data() {
            return {
                disabled: false,
                form: {
                    account_name: '',
                    account_number: '',
                    client_id: '',
                    client_secret: '',
                },
            }
        },
        validations: {
            form: {
                account_name: {
                    maxLength: maxLength(255),
                    required
                },
                account_number: {
                    maxLength: maxLength(255),
                    numeric,
                    required
                },
                client_id: {
                    maxLength: maxLength(255),
                    required
                },
                client_secret: {
                    maxLength: maxLength(255),
                    required
                }
            }
        },
        mounted() {
            const self = this;
            $('#connectBrivo').on('hidden.bs.modal', function () {
                self.$emit('updated');
            });
        },
        methods: {
            connectBrivo() {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                api.postConnectBrivoAccount(self.$route.params.id, self.form)
                    .then((response) => {
                        let url = [response.url, $.param({
                            'portfolio_id': self.$route.params.id,
                            'homebase_admin_id': self.$store.getters.user.id,
                            'access_token': response.access_token
                        })].join('?');
                        let win = window.open(url, '_blank');
                        if (win) win.focus();
                        self.disabled = false;
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    })
            },
            refreshBrivo() {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                $('#refresh-button').addClass('fa-spin');

                api.putRefreshBrivo(self.$route.params.id)
                    .then((response) => {
                        $('#refresh-button').removeClass('fa-spin');
                        self.disabled = false;
                        $('#connectBrivo').modal('hide');
                        self.handleSuccess("The Brivo account is being refreshed in the background. Check back shortly for changes.");
                    })
                    .catch((error) => {
                        $('#refresh-button').removeClass('fa-spin');
                        self.disabled = false;
                        self.handleError(error);
                    });
            }
        }
    }
</script>