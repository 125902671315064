<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <router-link to="/"><img :src="'/img/logo.png'" alt="" class="logo" /></router-link>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 alerts"></div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body p-4">
            <form @submit.prevent="login">
              <div class="form-group">
                <label for="email">Email</label>
                <input autofocus class="form-control" id="email" type="text" v-model.trim="$v.form.email.$model">
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input class="form-control" id="password" type="password" v-model.trim="$v.form.password.$model">
              </div>
              <div class="row">
                <div class="col col-md-auto ml-auto">
                  <button :disabled="disabled || $v.$invalid" class="btn btn-primary w-100" style="min-width: 200px;" type="submit">Sign in</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../../api.js';
    import {email, required} from 'vuelidate/lib/validators';

    export default {
        data() {
            return {
                disabled: false,
                form: {
                    email: '',
                    password: '',
                },
            };
        },
        validations: {
            form: {
                email: {
                    email,
                    required
                },
                password: {
                    required
                }
            }
        },
        mounted() {
            $('.alerts').html('');
            $('.loading').css('display', 'none');
        },
        methods: {
            login() {
                const self = this;
                if (self.disabled || self.$v.$invalid) return;
                self.disabled = true;
                api.postLogin(self.form)
                    .then((response) => {
                        self.disabled = false;
                        localStorage.setItem('user', JSON.stringify(response.homebase_admin));
                        self.$store.commit('loginUser');
                        self.$router.push({name: 'portfolios'});
                    })
                    .catch((error) => {
                        self.disabled = false;
                        this.$v.$reset();
                        Object.assign(this.$data, this.$options.data.call(this));
                        self.handleError(error);
                    });
            },
        },
    };
</script>
