<template>
  <div class="row">
    <div class="col">
      <div :data-target="'#employee'+employee.id" class="card employee card-link" data-toggle="modal">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <h6>{{ employee.first_name }} {{ employee.last_name }}</h6>
              <span v-if="!employee.active"><i class="fas fa-exclamation-circle active-warning"></i></span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-lg-3">
              {{ employee.title }}
            </div>
            <div class="col-sm-6 col-lg-3">
              {{ employee.email }}
            </div>
            <div class="col-sm-6 col-lg-3">
              {{ phone }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :id="'employee'+employee.id" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Update Employee</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true"><i class="fal fa-times-circle fa-fw"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item"><a :href="'#information-' + employee.id" aria-controls="information" aria-selected="true" class="nav-link active" data-toggle="tab" id="information-tab" role="tab">Information</a></li>
              <li class="nav-item"><a :href="'#permissions-' + employee.id" aria-controls="permissions" aria-selected="false" class="nav-link" data-toggle="tab" id="permissions-tab" role="tab">Permissions</a></li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div :id="'information-' + employee.id" aria-labelledby="information-tab" class="tab-pane fade show active" role="tabpanel">
                <form @submit.prevent="putEmployee">
                  <div class="row justify-content-center">
                    <div class="col-lg-10">
                      <div class="row">
                        <div class="col-12 col-md-6">
                          <div class="form-group">
                            <label for="first_name">First Name</label>
                            <input :class="{'is-invalid':$v.form.first_name.$anyError}" class="form-control" id="first_name" maxlength="255" type="text" v-model.trim="$v.form.first_name.$model">
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="form-group">
                            <label for="last_name">Last Name</label>
                            <input :class="{'is-invalid':$v.form.last_name.$anyError}" class="form-control" id="last_name" maxlength="255" type="text" v-model.trim="$v.form.last_name.$model">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-md-6">
                          <div class="form-group">
                            <label for="email">Email</label>
                            <input :class="{'is-invalid':$v.form.email.$anyError}" class="form-control" id="email" maxlength="255" type="email" v-model.trim="$v.form.email.$model">
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <div class="form-group">
                            <label for="phone_number">Phone Number</label>
                            <input :class="{'is-invalid':$v.form.phone_number.$anyError}" class="form-control" id="phone_number" maxlength="255" type="text" v-model.trim="$v.form.phone_number.$model">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label for="title">Title</label>
                            <input :class="{'is-invalid':$v.form.title.$anyError}" class="form-control" id="title" maxlength="255" type="text" v-model.trim="$v.form.title.$model">
                          </div>
                        </div>
                      </div>
                      <button class="d-none" type="submit"></button>
                    </div>
                  </div>
                </form>
              </div>
              <div :id="'permissions-' + employee.id" aria-labelledby="permissions-tab" class="tab-pane fade" role="tabpanel">
                <form>
                  <div class="row">
                    <div :key="permission.id" class="col-sm-6" v-for="permission in initialPermissions">
                      <div class="form-group">
                        <input :id="'checkbox-' + employee.id + '-' + permission.id" :value="permission.id" class="form-control d-inline-block mr-2" type="checkbox" v-model="current_permissions">
                        <label :for="'checkbox-' + employee.id + '-' + permission.id" :title="permission.description" data-placement="right" data-toggle="tooltip">{{ permission.identifier }}</label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer d-block">
            <div class="row">
              <div class="col-12 col-md-6">
                <button :disabled="disabled" @click="resetEmployee" class="btn btn-danger" type="button">Reset Password</button>
                <button :class="employee.active ? 'btn-danger' : 'btn-success'" :disabled="disabled" @click="activateEmployee" class="btn" type="button" v-if="employee.id !== this.$store.getters.user.id">{{ employee.active ? 'Deactivate' : 'Activate' }}</button>
              </div>
              <div class="col-12 col-md-6 text-right">
                <button :disabled="disabled" class="d-none d-md-inline-block btn btn-outline-primary" data-dismiss="modal" type="button">Close</button>
                <button :disabled="disabled || $v.$invalid" @click="putEmployee" class="btn btn-primary" type="button">Save Changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from '../api.js';
    import {email, maxLength, numeric, required} from 'vuelidate/lib/validators';

    export default {
        name: 'Employee',
        props: {
            initialEmployee: Object,
            initialPermissions: Array
        },
        data() {
            return {
                disabled: false,
                employee: null,
                form: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: '',
                    title: '',
                },
                current_permissions: [],
                available_permissions: []
            };
        },
        validations: {
            form: {
                first_name: {
                    maxLength: maxLength(255),
                    required
                },
                last_name: {
                    maxLength: maxLength(255),
                    required
                },
                email: {
                    maxLength: maxLength(255),
                    email,
                    required
                },
                phone_number: {
                    maxLength: maxLength(255),
                    numeric,
                    required
                },
                title: {
                    maxLength: maxLength(255),
                    required
                }
            }
        },
        computed: {
            phone() {
                let m = ('' + this.employee.phone_number).replace(/\D/g, '').match(/^(\d{3})(\d{3})(\d{4})$/);
                return (!m) ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];
            },
        },
        beforeMount() {
            const self = this;
            self.employee = self.initialEmployee;
            self.form = self.employee;

            $.each(self.initialPermissions, function (index, value) {
                self.available_permissions.push(value.id);
            });
            $.each(self.employee.permissions, function (index, value) {
                self.current_permissions.push(value.id);
            });

            $(function () {
                $('[data-toggle="tooltip"]').tooltip()
            });
        },
        methods: {
            putEmployee() {
                const self = this;
                if (self.disabled || self.$v.$invalid) return;
                self.disabled = true;

                api.putEmployee(self.form, self.current_permissions, self.available_permissions)
                    .then((response) => {
                        self.disabled = false;
                        $('#employee' + self.employee.id).modal('hide');
                        self.employee = response.homebase_admin;
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
            activateEmployee() {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                api.putActivateEmployee(self.employee)
                    .then((response) => {
                        self.disabled = false;
                        self.employee = response.homebase_admin;
                    })
                    .catch((error) => {
                        self.disabled = false;
                        self.handleError(error);
                    });
            },
            resetEmployee() {
                const self = this;
                if (self.disabled) return;
                self.disabled = true;

                api.putResetEmployee(self.employee)
                    .then((response) => {
                        self.disabled = false;
                        self.handleSuccess(self.employee.first_name + ' ' + self.employee.last_name + '\'s password has been reset!');
                    }).catch((error) => {
                    self.disabled = false;
                    self.handleError(error);
                });
            },
        },
    };
</script>
