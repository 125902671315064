<template>
  <div>
    <div
      v-if="status === UNAVAILABLE"
      class="btn-wrapper"
    >
      <button
        disabled="true"
        type="button"
        class="btn btn-toggle"
      >
        <div class="handle"/>
      </button>
      <span class="status status-disabled">Unavailable</span>
    </div>
    <div
      v-else-if="status === DISABLING"
      class="btn-wrapper active"
    >
      <div class="spinner-wrapper">
        <div
          class="spinner-border spinner-border-sm"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <span class="status status-disabled">Disabling</span>
    </div>
    <div
      v-else-if="status === ENABLING"
      class="btn-wrapper active"
    >
      <div class="spinner-wrapper">
        <div
          class="spinner-border spinner-border-sm"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <span class="status status-enabled">Enabling</span>
    </div>
    <div
      v-else-if="status === ENABLED"
      class="btn-wrapper active"
      @click="updateToggle(DISABLED)"
    >
      <button
        type="button"
        class="btn btn-toggle"
      >
        <div class="handle"/>
      </button>
      <span class="status status-enabled">Enabled</span>
    </div>
    <div
      v-else
      class="btn-wrapper"
      @click="updateToggle(ENABLED)"
    >
      <button
        type="button"
        class="btn btn-toggle"
      >
        <div class="handle"/>
      </button>
      <span class="status status-disabled">Disabled</span>
    </div>
  </div>
</template>


<script>
import {statuses} from '../../configs/stateKeys';

const {
    DISABLED,
    DISABLING,
    ENABLED,
    ENABLING,
    UNAVAILABLE,
} = statuses;

export default {
    name: 'Toggle',
    props: {
        'status': {
            type: String,
            default: DISABLED,
            validator: function(value) {
                return [
                    UNAVAILABLE,
                    ENABLED,
                    ENABLING,
                    DISABLED,
                    DISABLING,
                ].includes(value);
            },
        },
    },
    created() {
        // register statuses with component
        this.ENABLED = ENABLED;
        this.DISABLED = DISABLED;
        this.UNAVAILABLE = UNAVAILABLE;
        this.ENABLING = ENABLING;
        this.DISABLING = DISABLING;
    },
    methods: {
        updateToggle(statusToSet) {
            this.$emit('toggle', statusToSet);
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../sass/variables';

$toggle-default-size: 1.5rem;

@mixin toggle-mixin($size: $toggle-default-size){
  padding: 0;
  position: relative;
  border: none;
  height: $size;
  width: $size * 2;
  border-radius: $size;
  min-width: unset;

  &:focus,
  &.focus {
    &,
    .active & {
      outline: none;
    }
  }

  &:before,
  &:after {
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
  }

  > .handle {
    position: absolute;
    top: 0.12rem;
    left: calc(($size * 0.25) / 2);
    width: $size * 0.75;
    height: $size * 0.75;
    border-radius: $size * 0.75;
    background: $white;
    transition: left 0.25s;
  }

  .active & {
    transition: background-color 0.25s;
    > .handle {
      left: $size + calc(($size * 0.25) / 2);
      transition: left 0.25s;
    }

    &:before {
      opacity: 0.5;
    }

    &:after {
      opacity: 1;
    }
  }

  &.btn-toggle {
    &:before,
    &:after {
      display: none;
    }
  }
}

.btn-toggle {
  @include toggle-mixin;

  color: #6b7381;
  background: $gray-60;

  &:before,
  &:after {
    color: #6b7381;
  }

  .active & {
    background-color: map-get($theme-colors, 'primary-alt');
  }

  &.btn-toggle {
    @include toggle-mixin($size:1rem);
  }
}

.btn-wrapper {
  display: inline-flex;
  align-items: center;
  border-radius: 15px;
  background: $blue-05;
  padding: 6px 9px;
  font-weight: bold;
  color: map-get($theme-colors, 'primary-alt');
  cursor: pointer;

  &.active {
    color: $white,
  }
}

.status {
  padding-left: .5em;

  &.status-enabled {
    color: map-get($theme-colors, 'primary-alt');
  }

  &.status-disabled {
    color: $gray-60;
  }
}

.spinner-wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 2rem;
  color: #008EC0;
}
</style>
