<template>
  <div class="row mb-3">
    <div class="col-12 col-md">
      <div class="form-group mb-0">
        <label
          :for="`feature-${feature.identifier}`"
          class="mb-0"
        >
          <Toggle
            :status="feature.status"
            class="toggle"
            @toggle="saveFeature($event, feature.id)"
          />
          {{ feature.title }}
        </label>
        <label
          :for="`feature-${feature.identifier}}`"
          class="d-block font-weight-normal"
        >
          {{ feature.description }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Toggle from '../components/elements/Toggle';
export default {
    name: 'BuildingFeature',
    components: {
         Toggle,
    },
    props: {
        feature: Object,
    },
    methods: {
        saveFeature(statusToSet, featureId) {
            const self = this;
            self.$store.dispatch('plan/toggleFeature', {
                featureId,
                statusToSet,
            })
            .catch((error)=> {
                self.handleError(error);
            });
        },
    },
};
</script>

<style scoped>
.row {
    border-bottom: 1px solid #EEEEEE;
}
.toggle {
    position: absolute;
    right: 0%;
}
</style>
